import { cn } from "../../util/helpers";

type TextBodyProps = {
	type: "normal" | "small" | "mini" | "bold16" | "bold14";
	children: string | number | JSX.Element | null;
	className?: string;
};

const TextBody = ({ type, children, className }: TextBodyProps) => {
	return (
		<p
			className={cn(
				type.includes("bold") ? "font-bold" : "font-normal",
				type === "normal" &&
					"text-sm leading-5 md:text-base md:leading-6 print:md:text-sm",
				type === "small" && "text-sm leading-5 print:text-xs",
				type === "mini" && "text-xs leading-5",
				type === "bold16" &&
					"text-sm leading-5 md:text-base md:leading-6 print:md:text-sm",
				type === "bold14" && "text-sm leading-5",
				className,
			)}
		>
			{children}
		</p>
	);
};

export default TextBody;
