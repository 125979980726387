import { TextButton } from "../buttons";
import { useTranslation } from "react-i18next";
import { IconDelete, ArrowDown } from "../icons";
import { PlainCheckbox } from "../input";
import { BodyText } from "../typography";
import CheckboxState from "../../components/input/CheckboxState";
import { useEffect, useRef, useState } from "react";
import { Dropdown } from "../cards";
import OutsideClickHandler from "react-outside-click-handler";
import { usePopper } from "react-popper";
import { useBrandingStore } from "../../store";
import OSArrowDown from "../icons/optiswiss/OSArrowDown";
import { cn } from "../../util/helpers";

type CheckboxDropdownOptions = {
	id: number;
	label: string;
	count: number;
	onSet: () => void;
};

type TableHeaderProps = {
	variant: "lastModified" | "delete";
	checkbox?: CheckboxState;
	setCheckboxChecked?: () => void;
};

type TableHeaderDeleteProps = {
	variant: "delete";
	itemCountToDelete?: number;
	onDelete: () => void;
};

type TableHeaderLastModifiedProps = {
	variant: "lastModified";
	options: CheckboxDropdownOptions[];
};

type Props = TableHeaderProps &
	(TableHeaderDeleteProps | TableHeaderLastModifiedProps);

const TableHeader = (props: Props) => {
	const { t } = useTranslation();
	const {
		variant,
		checkbox = "unchecked",
		// eslint-disable-next-line
		setCheckboxChecked = () => {},
	} = props;
	const { branding } = useBrandingStore();
	const [stuck, setStuck] = useState<boolean>(false);
	const [monthDropdownVisible, setMonthDropdownVisible] =
		useState<boolean>(false);

	const [referenceElement, setReferenceElement] =
		useState<HTMLButtonElement | null>(null);
	const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
		null,
	);
	const { styles, attributes } = usePopper(referenceElement, popperElement, {
		placement: "bottom-start",
		modifiers: [
			{
				name: "offset",
				options: {
					offset: [0, 7.5],
				},
			},
		],
	});

	const headerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const cachedRef = headerRef.current;

		const observer = new IntersectionObserver(
			([e]) => setStuck(e.intersectionRatio < 1),
			{ threshold: [1] },
		);

		if (null !== cachedRef) {
			observer.observe(cachedRef);
		}
	}, []);

	return (
		<>
			<div
				ref={headerRef}
				className={cn(
					"sticky top-[calc(4.5rem+1px)] z-[5] flex w-full items-center justify-between border-b border-secondary-100 bg-pure-white py-3 backdrop-blur",
					stuck && "bg-quaternary-20",
					branding === "hoya" && "bg-opacity-[0.88]",
					branding !== "hoya" && "border-quaternary-80 px-4 pb-0",
				)}
			>
				<div className={cn("flex items-center gap-x-4")}>
					<PlainCheckbox
						checked={checkbox}
						setChecked={setCheckboxChecked}
					/>
					{variant === "lastModified" ? (
						<OutsideClickHandler
							onOutsideClick={() =>
								setMonthDropdownVisible(false)
							}
						>
							<TextButton
								color="secondary"
								icon={
									branding !== "hoya"
										? OSArrowDown
										: ArrowDown
								}
								forwardRef={setReferenceElement}
								className={cn(
									"[&>svg]:text-primary-100",
									monthDropdownVisible
										? "bg-quaternary-20"
										: undefined,
									branding !== "hoya"
										? "!mt-1.5 !size-7 !p-0"
										: "w-fit min-w-fit max-w-fit",
								)}
								onClick={() =>
									setMonthDropdownVisible(
										!monthDropdownVisible,
									)
								}
							/>
							{monthDropdownVisible && (
								<Dropdown
									forwardRef={setPopperElement}
									styles={styles.popper}
									attributes={{ ...attributes.popper }}
									className={cn(
										branding !== "hoya" &&
											"rounded-[10px] p-0 shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)]",
									)}
								>
									{props.options.map((opt) => (
										<button
											key={opt.id}
											className={cn(
												"inline-flex w-full items-center text-pure-black",
												"relative justify-between gap-x-3 overflow-hidden",
												"px-6 py-3 transition duration-200 ease-in-out",
												branding !== "hoya" &&
													"gap-9 border-b border-quaternary-100 p-4 font-bold last:border-none",
											)}
											onClick={() => {
												setMonthDropdownVisible(false);
												opt.onSet();
											}}
										>
											<span>{opt.label}</span>
											<span
												className={cn(
													"font-normal text-primary-dark-60",
												)}
											>
												{opt.count}
											</span>
										</button>
									))}
								</Dropdown>
							)}
						</OutsideClickHandler>
					) : (
						<span>
							{t("tableHeader.selectedItems", {
								count: props.itemCountToDelete,
							})}
						</span>
					)}
				</div>
				{variant === "lastModified" && (
					<BodyText
						type="normal"
						className={cn(
							"text-primary-dark-60",
							branding !== "hoya" && "text-quaternary-80",
						)}
					>
						{t(
							branding !== "hoya"
								? "tableHeader.recorded"
								: "tableHeader.lastModified",
						)}
					</BodyText>
				)}
				{variant === "delete" && (
					<TextButton
						color="primary"
						icon={IconDelete}
						onClick={props.onDelete}
					>
						{t("tableHeader.deleteSelected")}
					</TextButton>
				)}
			</div>
		</>
	);
};

export default TableHeader;
