import { cn } from "../../util/helpers";
import { BodyText, ButtonText } from "../typography";

type TableRowConsultationProps = {
	isSelected: string | undefined;
	toggleSelection: (b: string) => void;

	content: {
		id: string;
		name: string;
		date: Date;
		consultation: string;
	};
};

const TableRowConsultation = ({
	isSelected,
	toggleSelection,
	content,
}: TableRowConsultationProps) => {
	const { name, date, id, consultation } = content;
	return (
		<div
			className={cn(
				"flex h-[4.5rem] cursor-pointer flex-row items-center justify-between",
			)}
			onClick={() => toggleSelection(id)}
		>
			<div className={cn("flex flex-row items-center gap-3")}>
				<div>
					<input
						type={"radio"}
						name={"radio-group"}
						value={id}
						className="p-2.5"
						checked={isSelected === id}
						onChange={() => (isSelected = id)}
					/>
				</div>
				<div className={cn("flex flex-col items-start justify-center")}>
					<ButtonText type="bold">{name}</ButtonText>
					<BodyText
						type="small"
						className={cn("text-primary-dark-60")}
					>
						{date.toLocaleString()}
					</BodyText>
				</div>
			</div>
			<div>
				<ButtonText type="normal">{consultation}</ButtonText>
			</div>
		</div>
	);
};

export default TableRowConsultation;
