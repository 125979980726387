import { formatRelative } from "date-fns";
import { Link } from "react-router-dom";
import { BodyText, ButtonText } from "../typography";
import { useBrandingStore } from "../../store";
import { cn } from "../../util/helpers";

type DraftItemProps = {
	id: string;
	name: string;
	image: string;
	date: Date;
};

const DraftItem = ({ id, date, image, name }: DraftItemProps) => {
	const { branding } = useBrandingStore();

	return (
		<Link
			to={`/measurement/eyes/${id}/process`}
			state={{ previousPage: "/" }}
			className={cn(
				branding !== "hoya"
					? "items-center gap-2.5 overflow-hidden rounded-[10px] bg-quaternary-100 px-4 py-2.5"
					: "bg-quaternary-20",
				"flex h-24 w-full shrink-0 grow-0 flex-row",
			)}
		>
			<img
				className={cn(
					branding !== "hoya"
						? "size-[75px] rounded-full object-cover object-center"
						: "h-full w-16 object-cover",
				)}
				src={image}
				loading="lazy"
			/>
			<div
				className={cn(
					branding !== "hoya"
						? "gap-3"
						: "relative justify-between py-3 pl-3",
					"flex flex-col",
				)}
			>
				<ButtonText
					type="bold"
					className={cn(
						branding !== "hoya"
							? "text-pure-black"
							: "text-primary-dark-100",
					)}
				>
					{name}
				</ButtonText>
				<BodyText
					type={branding !== "hoya" ? "normal" : "mini"}
					className={cn(
						branding !== "hoya"
							? "text-primary-dark-100"
							: "text-primary-dark-60",
					)}
				>
					{formatRelative(date, new Date())}
				</BodyText>
			</div>
		</Link>
	);
};
export default DraftItem;
