import { SVGProps } from "react";

const SnackOk = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			width="42"
			height="42"
			viewBox="0 0 42 42"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M21.1841 36.3384C12.6184 36.3384 5.67188 29.463 5.67188 20.9778C5.67188 12.4927 12.6184 5.62054 21.1841 5.62054C29.7498 5.62054 36.6964 12.4959 36.6964 20.9811"
				fill="white"
			/>
			<path
				d="M36.6968 20.9811C36.6968 29.463 29.7535 36.3416 21.1846 36.3416L36.6968 20.9811Z"
				fill="white"
			/>
			<path
				d="M21.1841 36.3384C12.6184 36.3384 5.67188 29.463 5.67188 20.9778C5.67188 12.4927 12.6184 5.62054 21.1841 5.62054C29.7498 5.62054 36.6964 12.4959 36.6964 20.9811"
				fill="white"
			/>
			<path
				d="M36.6968 20.9811C36.6968 29.463 29.7535 36.3416 21.1846 36.3416L36.6968 20.9811Z"
				fill="white"
			/>
			<path
				d="M37.0233 20.9811C37.0233 29.6278 29.92 36.6648 21.1846 36.6648L37.0233 20.9811Z"
				fill="white"
			/>
			<path
				d="M21.1844 36.6616C12.4522 36.6616 5.3457 29.6278 5.3457 20.9778C5.3457 12.3278 12.4522 5.29729 21.1844 5.29729C29.9166 5.29729 37.0231 12.3311 37.0231 20.9811"
				fill="white"
			/>
			<path
				d="M36.3706 20.9811C36.3706 12.6899 29.5579 5.94378 21.1848 5.94378C12.8117 5.94378 5.99902 12.6899 5.99902 20.9811C5.99902 29.2723 12.8117 36.0183 21.1848 36.0183"
				fill="white"
			/>
			<path
				d="M21.1846 36.0151C29.5577 36.0151 36.3704 29.269 36.3704 20.9778L21.1846 36.0151Z"
				fill="white"
			/>
			<path
				d="M18.4347 28.1214L13.8452 23.579C13.2837 23.0232 13.2839 22.1159 13.8456 21.5604C14.399 21.0132 15.2899 21.0135 15.8429 21.5611L18.4347 24.1275L26.5056 16.1355C27.0586 15.588 27.9494 15.588 28.5024 16.1355C29.0632 16.6908 29.0632 17.5968 28.5024 18.1521L18.4347 28.1214Z"
				fill="currentColor"
			/>
		</svg>
	);
};

export default SnackOk;
