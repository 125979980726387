import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
	FloatingButton,
	SolidButton,
	TextButton,
} from "../../components/buttons";
import { Back, OSArrowLeft } from "../../components/icons";
import { FrameLayout } from "../../components/layouts";
import Footer from "../../components/panels/Footer";
import { HeadingText, BodyText } from "../../components/typography";
import languageIcons, { languageIcon } from "../../util/LanguageIcons";
import {
	getInternetConnectionState,
	getSettings,
} from "../../util/api/api-store";
import { useBrandingStore } from "../../store";
import Modal from "react-modal";
import BrandLogo from "../../components/logo/BrandLogo";
import { application, cn } from "../../util/helpers";

const Language = () => {
	const navigate = useNavigate();
	const { t, i18n } = useTranslation();
	const { branding } = useBrandingStore();
	const [selectedLanguageCode, setSelectedLanguageCode] =
		useState<string>("");
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [hasInternet, setHasInternet] = useState<boolean>(false);

	const getCurrentLng = () =>
		i18n.language || window.localStorage.i18nextLng || "";

	const changeLanguage = (lng: string) => {
		console.log(lng);
		i18n.changeLanguage(lng);
		setSelectedLanguageCode(lng);
	};

	useEffect(() => {
		getSettings.setRequest(null, false);
		getSettings.fetchData().then((resp) => {
			if (resp.onboard) {
				navigate("/");
				return;
			}
		});

		getInternetConnectionState.setRequest(null);
		getInternetConnectionState
			.fetchData()
			.then((resp) => {
				setHasInternet(resp.internet_connected);
			})
			.catch(() => {
				setHasInternet(false);
			})
			.finally(() => {
				setIsLoading(false);
			});

		if (getCurrentLng()) {
			if (getCurrentLng() === "en-US") {
				changeLanguage("en-GB");
			} else {
				changeLanguage(getCurrentLng());
			}
		}
	}, []);

	const generateRow = (language: languageIcon) => {
		const isSelected = language.code === selectedLanguageCode;
		return (
			<div
				key={language.code}
				className={cn(
					"flex h-12 cursor-pointer",
					isSelected ?? "bg-quaternary-100",
				)}
				onClick={() => changeLanguage(language.code)}
			>
				<img
					src={language.iconSrc}
					className={cn("m-[14px] h-5 w-5")}
				/>
				<BodyText
					type={
						isSelected
							? branding !== "hoya"
								? "normal"
								: "bold16"
							: "normal"
					}
					className="w-[100%] py-3"
				>
					{language.name}
				</BodyText>
				{isSelected && (
					<img
						src={
							branding !== "hoya"
								? "/icons/optiswiss/done_icon.svg"
								: "/icons/done_icon.svg"
						}
						className={cn(
							"m-3 size-6 justify-self-end",
							branding !== "hoya" &&
								"rounded-full bg-primary-100",
						)}
					/>
				)}
			</div>
		);
	};

	if (branding !== "hoya") {
		return (
			<main className="bg-[#b4b4b4]">
				<Modal
					closeTimeoutMS={0}
					isOpen={true}
					className={cn(
						"fixed inset-0 overflow-y-auto focus-visible:outline-0",
					)}
					overlayClassName={cn("fixed inset-0 z-30 bg-transparent")}
					bodyOpenClassName={cn("overflow-hidden")}
					style={{
						content: {
							transition: "none",
						},
						overlay: {
							transition: "none",
						},
					}}
				>
					<div
						className={cn(
							"flex min-h-full items-center justify-center p-4 sm:p-0",
						)}
					>
						<div
							className={cn(
								"relative flex min-h-[767px] w-full flex-col overflow-hidden rounded-[50px] bg-pure-white transition-all sm:my-8 sm:max-w-[620px]",
							)}
						>
							<div
								className={cn(
									"flex items-center justify-between border-b-[1px] border-secondary-40 px-12 py-8",
								)}
							>
								<BrandLogo />
								<div className={cn("flex flex-col items-end")}>
									<BodyText
										type={"normal"}
										className={cn(
											"!leading-[1em] text-pure-black",
										)}
									>
										<span
											dangerouslySetInnerHTML={{
												__html: application[branding]
													.title,
											}}
										></span>
									</BodyText>
									<p
										className="text-[8px] text-pure-black"
										dangerouslySetInnerHTML={{
											__html: application[branding]
												.title_visureal,
										}}
									></p>
								</div>
							</div>
							<div
								className={cn(
									"grid flex-1 grid-cols-1 grid-rows-[1fr_auto] place-items-center px-6 pb-12 sm:px-14",
								)}
							>
								<div
									className={cn(
										"w-full overflow-hidden pb-8",
									)}
								>
									<TextButton
										to="/onboarding/welcome"
										color="primary"
										icon={OSArrowLeft}
										iconClasses={cn(
											"size-[30px] !rounded-[10px] border border-quaternary-80 p-[5px]",
										)}
										className={cn("-m-3 my-6 h-fit")}
									>
										{t("language.previous")}
									</TextButton>
									<HeadingText
										h={4}
										className={cn(
											"mb-3 text-primary-dark-100",
										)}
									>{`1/${hasInternet ? "2" : "3"}. ${t(
										"language.pleaseSelectALanguage",
									)}`}</HeadingText>
									<BodyText
										type={"normal"}
										className={cn(
											"mb-5 text-primary-dark-80",
										)}
									>
										{t(
											"language.itWillBeTheLanguagOfTheDevice",
										)}
									</BodyText>
									<div
										className={cn(
											"grid max-h-[338px] overflow-y-auto",
										)}
									>
										<div className="divide-y">
											{languageIcons.map((language) =>
												generateRow(language),
											)}
										</div>
									</div>
								</div>
								<div
									className={cn(
										"flex w-full flex-col gap-5 bg-white",
									)}
								>
									<SolidButton
										color="primary"
										loading={isLoading}
										onClick={() => {
											if (isLoading) {
												return;
											}
											if (hasInternet) {
												navigate(
													"/onboarding/registration",
												);
											} else {
												navigate("/onboarding/wifi");
											}
										}}
										fullWidth
										className="capitalize"
									>
										{t("application.btnNextCaption")}
									</SolidButton>
								</div>
							</div>
						</div>
					</div>
				</Modal>
			</main>
		);
	}

	return (
		<>
			<FrameLayout headerType="onboarding" hiddenFooter={true}>
				<div className="container pb-12">
					<TextButton
						to="/onboarding/welcome"
						color="primary"
						icon={Back}
						className={cn("-m-3 mb-6 mt-3")}
					>
						{t("language.previous")}
					</TextButton>
					<HeadingText
						h={4}
						className={cn("mb-3 text-primary-dark-100")}
					>{`1/${hasInternet ? "2" : "3"}. ${t(
						"language.pleaseSelectALanguage",
					)}`}</HeadingText>
					<BodyText
						type={"normal"}
						className={cn("mb-12 text-primary-dark-80")}
					>
						{t("language.itWillBeTheLanguagOfTheDevice")}
					</BodyText>
					<div className="divide-y">
						{languageIcons.map((language) => generateRow(language))}
					</div>
				</div>
			</FrameLayout>
			<Footer stickyFooter>
				<div className="container">
					<FloatingButton
						mx={false}
						num={1}
						label={t("application.btnNextCaption")}
						loading={isLoading}
						onClick={() => {
							if (isLoading) {
								return;
							}
							if (hasInternet) {
								navigate("/onboarding/registration");
							} else {
								navigate("/onboarding/wifi");
							}
						}}
					/>
				</div>
			</Footer>
		</>
	);
};

export default Language;
