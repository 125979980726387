import { SVGProps } from "react";

type labelInput = {
	eye_to_lens_dist: number;
	lens_thickness: number;
};

const OSFrameSide = (props: SVGProps<SVGSVGElement> & { data: labelInput }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 385 376"
			width="385"
			height="376"
			fill="none"
			{...props}
		>
			<g clipPath="url(#a)">
				<path fill="#fff" d="M0 0h385v376H0z" />
				<path
					fill="#fff"
					d="M202.812 157.866c-9.316 53.808 24.927 118.136 36.313 120.378 11.385 2.329 88.582-26.731 93.585-108.65-18.027-15.522-53.822-30.009-72.626-28.198-18.889 1.811-57.186 16.47-57.186 16.47h-.086Z"
				/>
				<path
					fill="#BBBEC3"
					d="M146.314 100.005s77.198-37.08 119.548-48.462c42.351-11.382 107.559-7.157 107.559-7.157S301.917 20.5 236.881 28.088c-52.529 11.21-77.887 24.404-80.82 27.422-3.019 3.018-10.695 19.488-9.66 44.581l-.087-.086Z"
				/>
				<path
					fill="#BBBEC3"
					fillOpacity=".75"
					d="M194.788 168.732c-1.897 42.598 27.86 108.823 44.248 109.599 16.389.776 26.48-19.057 31.483-41.649 4.917-22.679 12.076-78.384-8.798-92.612-20.959-14.142-66.933 24.662-66.933 24.662Z"
				/>
				<path
					fill="#BBBEC3"
					d="M143.211 171.922s28.033 8.623 50.372 6.726c23.375-18.281 53.133-28.543 69.09-29.405 31.827-1.811 68.313 27.163 68.313 27.163-3.45 23.886-7.591 38.373-7.591 38.373s33.898-44.237 39.073-65.363c-7.504-.949-33.984-2.673-33.984-2.673-17.595-11.038-53.304-21.213-69.261-18.713-15.957 2.501-45.025 12.073-71.16 31.388 0 0-25.962 10.262-44.938 12.59l.086-.086Z"
				/>
				<path
					fill="#fff"
					d="M234.865 232.164c-9.506-25.088-31.581-43.318-35.059-41.409-3.436 1.983-.067 50.933 16.027 57.078 16.094 6.146 19.107-15.712 19.107-15.712l-.075.043Z"
				/>
				<path
					fill="#1D1D1D"
					d="m37.532 192.712 11.333 42.084c25.362 81.562 38.756 97.353 38.756 97.353 62.532 39.637 49.455-47.295 49.455-47.295s-7.767-41.452-18.229-95.853c-9.986-51.875 51.041-64.429 56.747-64.982 135.367-20.134 240.697-42.952 372.419-63.402 21.557-3.395 163.741-18.95 191.084-15.95 14.424 1.58 43.273 8.291 52.546 14.45 3.17 2.132 7.529 1.184 9.51-2.053 1.982-3.237 4.518-7.185 6.895-10.896 4.201-6.554 1.348-15.318-5.944-18.318l-.475-.158c-18.387-7.501-38.201-10.738-58.015-9.396-55.399 3.79-158.827 15.712-170.556 16.976L112.111 87.62c-13.95-2.132-31.306 5.211-31.306 5.211-66.251-5.21-50.656 65.52-50.656 65.52m25.612-39.069c12.046-3.237 33.92 38.925 48.9 94.195 14.979 55.27 17.436 102.723 5.389 105.96-12.047 3.237-33.92-38.925-48.9-94.195-14.98-55.27-17.436-102.723-5.39-105.96Z"
				/>
				<path
					fill="#F8F8F8"
					d="M57.37 208.276c-13.245-58.279-8.278-86.093 0-87.749 8.278-1.655 23.73 26.49 29.8 39.735 3.312 7.225 26.491 72.848 29.802 107.617 3.311 34.768 0 52.98-8.278 52.98S73.926 281.124 57.37 208.276Z"
				/>
				<path
					stroke="var(--primary--100)"
					strokeDasharray="10 10"
					d="m165.436 65.567 72.095 277.591M83.006 65.567l72.096 277.591M127 57v148M304 182.105 29 226.473"
				/>
				<path
					fill="var(--primary--100)"
					d="M123.636 66.636a.9.9 0 0 0 0-1.272l-5.727-5.728a.9.9 0 0 0-1.273 1.273L121.727 66l-5.091 5.091a.9.9 0 0 0 1.273 1.273l5.727-5.728Zm-37.272-1.272a.9.9 0 0 0 0 1.272l5.727 5.728a.9.9 0 0 0 1.273-1.273L88.273 66l5.091-5.091a.9.9 0 0 0-1.273-1.273l-5.727 5.728ZM123 65.1H87v1.8h36v-1.8ZM229.716 325.545a.9.9 0 0 0-.171-1.261l-6.443-4.909a.9.9 0 1 0-1.09 1.432l5.727 4.363-4.364 5.728a.9.9 0 1 0 1.432 1.09l4.909-6.443Zm-75.432 8.91a.9.9 0 0 0 .171 1.261l6.443 4.909a.9.9 0 1 0 1.09-1.432l-5.727-4.363 4.364-5.728a.9.9 0 1 0-1.432-1.09l-4.909 6.443Zm74.595-10.347-74 10 .242 1.784 74-10-.242-1.784Z"
				/>
				<text
					xmlSpace="preserve"
					fill="#1D1D1D"
					fontFamily="Arial"
					fontSize="22"
					fontWeight="bold"
					letterSpacing="0em"
					style={{ whiteSpace: "pre" }}
				>
					<tspan x="89.367" y="54.547">
						{props.data.lens_thickness.toFixed(1)}
					</tspan>
				</text>
				<text
					xmlSpace="preserve"
					fill="#1D1D1D"
					fontFamily="Arial"
					fontSize="22"
					fontWeight="bold"
					letterSpacing="0em"
					style={{ whiteSpace: "pre" }}
				>
					<tspan x="182.414" y="353.547">
						{props.data.eye_to_lens_dist.toFixed(1)}
					</tspan>
				</text>
			</g>
			<defs>
				<clipPath id="a">
					<path fill="#fff" d="M0 0h385v376H0z" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default OSFrameSide;
