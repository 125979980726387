import { Suspense, useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Snackbar } from "../components/panels";
import { useBrandingStore, useSnackStore } from "../store";
import { getSettings } from "../util/api/api-store";
import { application } from "../util/helpers";

function Root() {
	const { text, icon } = useSnackStore();
	const location = useLocation();
	const isCurrentPageOnboarding = location.pathname.startsWith("/onboarding");
	const { setBranding } = useBrandingStore();

	const settingsResult = getSettings.useGetResult();

	useEffect(() => {
		getSettings.fetchData().then((resp) => {
			setBranding(resp.branding);
		});
	}, []);

	return (
		<Suspense>
			{settingsResult.kind === "ok" && settingsResult.data.branding && (
				<Helmet
					title={
						application[settingsResult.data.branding].title_visureal
							? `${application[settingsResult.data.branding].title} - ${application[settingsResult.data.branding].title_visureal}`
							: `${application[settingsResult.data.branding].title}`
					}
					link={[
						{
							rel: "apple-touch-icon",
							sizes: "152x152",
							href: `${window.location.origin}/${settingsResult.data.branding}_fav152.png`,
						},
						{
							rel: "icon",
							type: "image/png",
							sizes: "152x152",
							href: `${window.location.origin}/${settingsResult.data.branding}_fav152.png`,
						},
						{
							rel: "icon",
							type: "image/png",
							sizes: "32x32",
							href: `${window.location.origin}/${settingsResult.data.branding}_fav32.png`,
						},
						{
							rel: "icon",
							type: "image/png",
							sizes: "96x96",
							href: `${window.location.origin}/${settingsResult.data.branding}_fav96.png`,
						},
					]}
				/>
			)}
			{text && <Snackbar text={text} icon={icon} />}
			{settingsResult.kind === "ok" &&
			!settingsResult.data.onboard &&
			!isCurrentPageOnboarding ? (
				<Navigate to={"/onboarding"} />
			) : (
				<Outlet />
			)}
		</Suspense>
	);
}

export default Root;
