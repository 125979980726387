import Modal from "react-modal";
import { Close } from "../icons";
import { HeadingText } from "../typography";
import { cn } from "../../util/helpers";
import { useBrandingStore } from "../../store";
import TextButton from "../buttons/TextButton";

type ModalBaseProps = {
	title?: string;
	disableHeader?: boolean;
	disableClose?: boolean;
	children: React.ReactNode;
	open: boolean;
	small?: boolean;
	close: () => void;
};

Modal.setAppElement("#root");

const ModalBase = ({
	title,
	disableHeader,
	disableClose,
	children,
	open,
	small,
	close,
}: ModalBaseProps) => {
	const { branding } = useBrandingStore();

	return (
		<Modal
			closeTimeoutMS={300}
			isOpen={open}
			onRequestClose={close}
			className={cn(
				"fixed inset-0 overflow-y-auto focus-visible:outline-0",
			)}
			overlayClassName={cn(
				"fixed inset-0 z-30 bg-black/50 transition-opacity",
			)}
			bodyOpenClassName={cn("overflow-hidden")}
		>
			<div
				className={cn(
					"flex min-h-full items-end justify-center p-4 sm:items-center sm:p-0",
				)}
			>
				<div
					className={cn(
						"relative w-full overflow-hidden bg-pure-white transition-all sm:my-8",
						branding !== "hoya"
							? "rounded-[50px]"
							: "sm:max-w-[498px]",
						branding !== "hoya" &&
							(small ? "sm:max-w-[490px]" : "sm:max-w-[620px]"),
					)}
				>
					<div
						className={cn(
							branding !== "hoya" ? "px-0" : "sm:px-6 sm:pb-6",
						)}
					>
						{!disableHeader && (
							<div
								className={cn(
									"border-b-[1px] border-secondary-40 px-6",
									branding !== "hoya"
										? "grid place-items-center px-12 py-8"
										: "flex flex-row items-center justify-between py-5",
								)}
							>
								{title && (
									<HeadingText
										h={4}
										className={cn(
											branding !== "hoya" &&
												"pr-8 [grid-area:1/1]",
										)}
									>
										{title}
									</HeadingText>
								)}
								{!disableClose && (
									<TextButton
										color="secondary"
										disabled={false}
										fullWidth={false}
										icon={Close}
										iconClasses={
											branding !== "hoya"
												? "size-[17px]"
												: undefined
										}
										onClick={() => {
											close();
										}}
										className={cn(
											"justify-self-end",
											branding !== "hoya" &&
												"h-fit !rounded-[10px] border border-quaternary-80 p-[5px] [grid-area:1/1]",
										)}
									/>
								)}
							</div>
						)}
						{children}
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default ModalBase;
