import { t } from "i18next";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	FloatingButton,
	SolidButton,
	TextButton,
} from "../../components/buttons";
import { Back, Done, OSArrowLeft } from "../../components/icons";
import { TextField } from "../../components/input";
import { FrameLayout } from "../../components/layouts";
import { ModalBase } from "../../components/modals";
import Footer from "../../components/panels/Footer";
import { BodyText, HeadingText } from "../../components/typography";
import { useBrandingStore, useSnackStore } from "../../store";
import {
	getCommSettings,
	getInternetConnectionState,
	getSSIDs,
	getSettings,
	wifiConnect,
} from "../../util/api/api-store";
import { InfoPanel } from "../../components/panels";
import Modal from "react-modal";
import { application, cn } from "../../util/helpers";
import BrandLogo from "../../components/logo/BrandLogo";

const Wifi = () => {
	const navigate = useNavigate();
	const [wifiNetworks, setWifiNetworks] = useState<string[]>([]);
	const openSnack = useSnackStore((state) => state.open);
	const [ssidKey, setSsidKey] = useState<string>("");
	const [isSubmited, setIsSubmited] = useState<boolean>(false);
	const [isConnectingFailed, setConnectingFailed] = useState<boolean>(false);
	const [isInternetAvailable, setIsInternetAvaliable] =
		useState<boolean>(false);
	const [modalPasswordOpen, setModalPasswordOpen] = useState<boolean>(false);
	const [modalPasswordCorrect, setModalPasswordCorrect] =
		useState<boolean>(false);
	const [isConnectedSsid, setIsConnectedSsid] = useState<string>("");
	const [isSelectedSsid, setIsSelectedSsid] = useState<string>("");
	const [isInetConnected, setIsInetConnected] = useState<boolean>(false);
	const { branding } = useBrandingStore();

	useEffect(() => {
		getSettings.setRequest(null, false);
		getSettings.fetchData().then((resp) => {
			if (resp.onboard) {
				navigate("/");
				return;
			}
		});

		getInternetConnectionState.setRequest(null);
		getInternetConnectionState
			.fetchData()
			.then((resp) => {
				setIsInternetAvaliable(resp.internet_connected);
			})
			.catch((err) => {
				console.error(err);
				setIsInternetAvaliable(false);
			});

		getSSIDs.setRequest(null, true);
		getSSIDs.fetchData().then((resp) => {
			setWifiNetworks(resp.SSIDs);
			if (resp.last_connected) {
				setIsConnectedSsid(resp.last_connected);
				setIsInetConnected(true);
			} else {
				openSnack(
					t("onboardingWifi.needInternetConnection"),
					"warning",
				);
			}
		});
	}, []);

	const getSSIDsResponse = getSSIDs.useGetResult();
	const { SSIDs, last_connected } = getSSIDsResponse.unwrapOrDefault({
		SSIDs: [],
		last_connected: "",
	});

	const getCommSettingsResponse = getCommSettings.useGetResult();
	const commSettings = getCommSettingsResponse.unwrapOrDefault({
		SSIDs: [],
		admin_mode: false,
		ap_channel: 0,
		autoconfig_ap_channel: false,
		autoconfig_ap_channel_at_start: false,
		inet_config: {
			SSID: "",
			address: "",
			encryption: "",
		},
		inet_connected: false,
		update_enabled: false,
		vpn_on: false,
	});

	const gotoNextPage = () => {
		if (!isInetConnected) {
			openSnack(t("onboardingWifi.needInternetConnection"), "warning");
			return;
		} else if (!isInternetAvailable) {
			openSnack(t("onboardingWifi.connectedButNoInternet"), "warning");
			return;
		} else {
			navigate("/onboarding/registration");
		}
	};

	const connectWifi = () => {
		setIsSubmited(true);
		setConnectingFailed(false);
		wifiConnect.setRequest(
			{
				// inet_connected: formData.inet_connected,
				// update_enabled: formData.update_enabled,
				// unattended_update: false,//@todo ????
				// autoconfig_ap_channel: formData.autoconfig_ap_channel,
				// autoconfig_ap_channel_at_start: formData.autoconfig_ap_channel_at_start,
				// ap_channel: formData.ap_channel,
				inet_config: {
					SSID: isSelectedSsid,
					key: ssidKey,
					encryption: "psk2",
				},
			},
			true,
		);
		wifiConnect.invalidate();
		wifiConnect
			.fetchData()
			.then(async (resp) => {
				if (resp.success) {
					getSSIDs.invalidate();
					getSSIDs.fetchData();
					getCommSettings.invalidate();
					getCommSettings.fetchData().then((resp) => {
						setIsConnectedSsid(resp.inet_config.SSID);
						setIsInetConnected(resp.inet_connected);
						setModalPasswordOpen(false);
						setModalPasswordCorrect(true);
					});
					await checkInternetConnection();
				} else {
					setConnectingFailed(true);
					setModalPasswordCorrect(false);
				}
			})
			.catch((error) => {
				console.error(error);
				setConnectingFailed(true);
				setModalPasswordCorrect(false);
			})
			.finally(() => setIsSubmited(false));
	};

	const checkInternetConnection = () => {
		return new Promise<void>((resolve, reject) => {
			getInternetConnectionState.setRequest(null);
			getInternetConnectionState.invalidate();
			getInternetConnectionState
				.fetchData()
				.then((resp) => {
					setIsInternetAvaliable(resp.internet_connected);
				})
				.catch((err) => {
					console.error(err);
					setIsInternetAvaliable(false);
				})
				.finally(() => {
					resolve();
				});
		});
	};

	const closePasswordModal = () => {
		setModalPasswordOpen(false);
		setIsSubmited(false);
		setConnectingFailed(false);
	};

	const selectNetwork = (ssid: string) => {
		setSsidKey("");
		setModalPasswordOpen(true);
		setIsSelectedSsid(ssid);
		setConnectingFailed(false);
	};

	const skip = () => {
		navigate("/onboarding/registration");
	};

	useEffect(() => {
		getCommSettings.setRequest(null);
		getCommSettings.fetchData();

		const refresh = setInterval(() => {
			getCommSettings.invalidate();
			getCommSettings.fetchData();
		}, 5000);

		return () => {
			clearInterval(refresh);
		};
	}, []);

	if (branding !== "hoya") {
		return (
			<main className="bg-[#b4b4b4]">
				<Modal
					closeTimeoutMS={0}
					isOpen={true}
					className="fixed inset-0 overflow-y-auto focus-visible:outline-0"
					overlayClassName="fixed inset-0 z-30 bg-transparent"
					bodyOpenClassName="overflow-hidden"
					style={{
						content: {
							transition: "none",
						},
						overlay: {
							transition: "none",
						},
					}}
				>
					<div
						className={cn(
							"flex min-h-full items-center justify-center p-4 sm:p-0",
						)}
					>
						<div
							className={cn(
								"relative flex min-h-[767px] w-full flex-col overflow-hidden rounded-[50px] bg-pure-white transition-all sm:my-8 sm:max-w-[620px]",
							)}
						>
							<div
								className={cn(
									"flex items-center justify-between border-b-[1px] border-secondary-40 px-12 py-8",
								)}
							>
								<BrandLogo />
								<div className={cn("flex flex-col items-end")}>
									<BodyText
										type={"normal"}
										className={cn(
											"!leading-[1em] text-pure-black",
										)}
									>
										<span
											dangerouslySetInnerHTML={{
												__html: application[branding]
													.title,
											}}
										></span>
									</BodyText>
									<p
										className="text-[8px] text-pure-black"
										dangerouslySetInnerHTML={{
											__html: application[branding]
												.title_visureal,
										}}
									></p>
								</div>
							</div>
							<div
								className={cn(
									"grid flex-1 grid-cols-1 grid-rows-[1fr_auto] place-items-center px-6 pb-12 sm:px-14",
								)}
							>
								<div className={cn("w-full place-self-start")}>
									<TextButton
										to="/onboarding/language"
										color="primary"
										icon={OSArrowLeft}
										iconClasses={cn(
											"size-[30px] !rounded-[10px] border border-quaternary-80 p-[5px]",
										)}
										className={cn("-m-3 my-6 h-fit")}
									>
										{t("shopName.previous")}
									</TextButton>
									<div
										className={cn(
											"flex flex-col gap-5 pb-12",
										)}
									>
										<HeadingText
											h={4}
											className="text-pure-black"
										>{`3/4. ${t(
											"onboardingWifi.pageTitle",
										)}`}</HeadingText>
										<BodyText
											type={"normal"}
											className={cn(
												"text-primary-dark-80",
											)}
										>
											{t(
												"onboardingWifi.requiredToSendLensSupplier",
											)}
										</BodyText>
										{isInetConnected && (
											<>
												<div>
													<BodyText
														type={"bold16"}
														className="mb-3"
													>
														{t(
															"onboardingWifi.connectedTo",
														)}
													</BodyText>
													<div
														className={cn(
															"flex items-center bg-quaternary-100 px-1 pr-4",
														)}
													>
														<div
															className={`${
																isInternetAvailable
																	? "m-3"
																	: "ml-3"
															} w-[100%]`}
														>
															<BodyText
																type={"bold16"}
															>
																{
																	isConnectedSsid
																}
															</BodyText>
															{isInternetAvailable || (
																<BodyText
																	type={
																		"mini"
																	}
																>
																	{t(
																		"onboardingWifi.noInternet",
																	)}
																</BodyText>
															)}
														</div>
														<img
															src={
																"/icons/optiswiss/done_icon.svg"
															}
															className={cn(
																"size-6 justify-self-end rounded-full bg-primary-100",
															)}
														/>
													</div>
												</div>
												<div>
													<BodyText
														type={"bold16"}
														className="mb-3"
													>
														{t(
															"onboardingWifi.anotherNetworks",
														)}
													</BodyText>
												</div>
											</>
										)}
										<div
											className={cn(
												"grid overflow-y-auto",
												isInetConnected
													? "max-h-[148px]"
													: "max-h-[338px]",
											)}
										>
											<div className="divide-y">
												{wifiNetworks.map(
													(network, index) =>
														(network !==
															isConnectedSsid ||
															!isInetConnected) && (
															<div
																key={index}
																className={`flex h-12`}
																style={{
																	cursor: "pointer",
																}}
																onClick={() =>
																	selectNetwork(
																		network,
																	)
																}
															>
																<BodyText
																	type={
																		"normal"
																	}
																	className={cn(
																		"w-[100%] px-4 py-3",
																	)}
																>
																	{network}
																</BodyText>
															</div>
														),
												)}
											</div>
										</div>
									</div>
								</div>
								<div
									className={cn(
										"flex w-full flex-col gap-5 bg-white",
									)}
								>
									<SolidButton
										color="primary"
										onClick={() => gotoNextPage()}
										fullWidth
										className="capitalize"
									>
										{t("application.btnNextCaption")}
									</SolidButton>

									<TextButton
										color="primary"
										onClick={() => skip()}
										fullWidth
										className={cn(
											"!h-10 rounded-full border border-primary-100",
										)}
									>
										{t("onboardingWifi.skipStep")}
									</TextButton>
								</div>
							</div>
						</div>
					</div>
				</Modal>
				<ModalBase
					title={
						isConnectingFailed
							? t("onboardingWifi.failedConnectingTo", {
									ssid: isSelectedSsid,
								}) || undefined
							: t("onboardingWifi.connectingTo", {
									ssid: isSelectedSsid,
								}) || undefined
					}
					open={modalPasswordOpen}
					close={closePasswordModal}
				>
					<form
						onSubmit={(e) => {
							e.preventDefault();
							connectWifi();
						}}
						className="px-12 py-8"
					>
						<TextField
							type="default"
							htmlFor="inputmodal"
							label={t("lensSupplierSettings.customerPassword")}
							showPasswordButton={true}
							fieldOptions={{ type: "password", autoFocus: true }}
							fullWidth
							value={ssidKey}
							setValue={setSsidKey}
						/>
						<p className={cn("mt-8 text-right")}>
							<SolidButton
								color="primary"
								submit={true}
								loading={isSubmited}
							>
								{isSubmited
									? t("onboardingWifi.connecting")
									: t("settings.connect")}
							</SolidButton>
						</p>
						{isConnectingFailed && (
							<InfoPanel
								type="info"
								title={t("onboardingWifi.pleaseCheck")}
								message={
									t(
										"onboardingWifi.pleaseCheckDescription",
									) || undefined
								}
								className="mt-6"
							/>
						)}
					</form>
				</ModalBase>
			</main>
		);
	}

	return (
		<>
			<FrameLayout headerType="onboarding" hiddenFooter={true}>
				<div className={cn("container pb-12")}>
					<TextButton
						to="/onboarding/language"
						color="primary"
						icon={Back}
						className={cn("-m-3 mb-6 mt-3")}
					>
						{t("onboardingWifi.previous")}
					</TextButton>
					<HeadingText
						h={4}
						className={cn("mb-3 text-primary-dark-100")}
					>
						{t("onboardingWifi.pageTitle")}
					</HeadingText>
					<BodyText
						type={"normal"}
						className={cn(
							isConnectedSsid ? "mb-8" : "mb-12",
							"text-primary-dark-80",
						)}
					>
						{t("onboardingWifi.requiredToSendLensSupplier")}
					</BodyText>

					{isInetConnected && (
						<>
							<div className="mb-8">
								<BodyText type={"bold16"} className="mb-3">
									{t("onboardingWifi.connectedTo")}
								</BodyText>
								<div
									className={cn(
										"flex items-center bg-quaternary-100 px-1 pr-4",
									)}
								>
									<div
										className={cn(
											"w-[100%]",
											isInternetAvailable
												? "m-3"
												: "ml-3",
										)}
									>
										<BodyText type={"bold16"}>
											{isConnectedSsid}
										</BodyText>
										{isInternetAvailable || (
											<BodyText type={"mini"}>
												{t("onboardingWifi.noInternet")}
											</BodyText>
										)}
									</div>
									<Done className="justify-self-end" />
								</div>
							</div>
							<div>
								<BodyText type={"bold16"} className="mb-3">
									{t("onboardingWifi.anotherNetworks")}
								</BodyText>
							</div>
						</>
					)}

					<div className="divide-y">
						{wifiNetworks.map(
							(network, index) =>
								(network !== isConnectedSsid ||
									!isInetConnected) && (
									<div
										key={index}
										className={`flex h-12`}
										style={{ cursor: "pointer" }}
										onClick={() => selectNetwork(network)}
									>
										<BodyText
											type={"normal"}
											className="w-[100%] px-4 py-3"
										>
											{network}
										</BodyText>
									</div>
								),
						)}
					</div>
				</div>
			</FrameLayout>
			<Footer stickyFooter>
				<div className="container">
					<FloatingButton
						mx={false}
						num={2}
						buttons={{
							left: {
								label: t("onboardingWifi.skipStep"),
								onClick: () => skip(),
							},
							right: {
								label: t("application.btnNextCaption"),
								onClick: () => gotoNextPage(),
							},
						}}
					/>
				</div>
			</Footer>
			<ModalBase
				title={
					isConnectingFailed
						? t("onboardingWifi.failedConnectingTo", {
								ssid: isSelectedSsid,
							}) || undefined
						: t("onboardingWifi.connectingTo", {
								ssid: isSelectedSsid,
							}) || undefined
				}
				open={modalPasswordOpen}
				close={closePasswordModal}
			>
				<form
					onSubmit={(e) => {
						e.preventDefault();
						connectWifi();
					}}
					className="p-6"
				>
					<TextField
						type="default"
						htmlFor="inputmodal"
						label={t("lensSupplierSettings.customerPassword")}
						showPasswordButton={true}
						fieldOptions={{ type: "password", autoFocus: true }}
						fullWidth
						value={ssidKey}
						setValue={setSsidKey}
					/>
					<p className={cn("mt-8 text-right")}>
						<SolidButton
							color="primary"
							submit={true}
							loading={isSubmited}
						>
							{isSubmited
								? t("onboardingWifi.connecting")
								: t("settings.connect")}
						</SolidButton>
					</p>
					{isConnectingFailed && (
						<InfoPanel
							type="info"
							title={t("onboardingWifi.pleaseCheck")}
							message={
								t("onboardingWifi.pleaseCheckDescription") ||
								undefined
							}
							className="mt-6"
						/>
					)}
				</form>
			</ModalBase>
		</>
	);
};

export default Wifi;
