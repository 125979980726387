import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { setDefaultOptions, Locale } from "date-fns";
import * as dateFnsLocales from "date-fns/locale";
import { getSettings } from "./api/api-store";

interface Locales {
	[key: string]: Locale;
}

export const locales: Locales = {
	fr: dateFnsLocales.fr,
	ja: dateFnsLocales.ja,
	cs: dateFnsLocales.cs,
	da: dateFnsLocales.da,
	fi: dateFnsLocales.fi,
	it: dateFnsLocales.it,
	lt: dateFnsLocales.lt,
	nl: dateFnsLocales.nlBE,
	pl: dateFnsLocales.pl,
	es: dateFnsLocales.es,
	pt: dateFnsLocales.pt,
	sv: dateFnsLocales.sv,
	zh: dateFnsLocales.zhCN,
	en: dateFnsLocales.enGB,
	hu: dateFnsLocales.hu,
	de: dateFnsLocales.de,
	ro: dateFnsLocales.ro,
	th: dateFnsLocales.th,
	tr: dateFnsLocales.tr,
};

i18n.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		fallbackLng: {
			"en-US": ["en-GB"],
			default: ["en-GB"],
		},
		debug: true,
		backend: {
			appendNamespaceToMissingKey: true,
			loadPath: async () => {
				getSettings.setRequest(null);
				return getSettings.fetchData().then((resp) => {
					console.log(
						"Branding is being retrieved from the server",
						resp.branding,
					);
					return `/i18n/${resp.branding}/${resp.branding}_{{lng}}.json`;
				});
			},
		},
		interpolation: {
			escapeValue: false,
		},
		returnNull: false,
	});

i18n.on("languageChanged", (lng) => {
	setDefaultOptions({
		locale: locales[lng.split("-")[0]],
	});
});

export default i18n;
