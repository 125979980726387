import { Loading, OSLoading } from "../icons";
import { useTranslation } from "react-i18next";
import { ButtonText, HeadingText } from "../typography";
import { useBrandingStore } from "../../store";
import { cn } from "../../util/helpers";

// TODO primary gray
const ModalUpdate = () => {
	const { t } = useTranslation();
	const { branding } = useBrandingStore();

	return (
		<div className={cn("p-6 md:py-20")}>
			<div
				className={cn(
					"flex flex-col items-center gap-3",
					branding !== "hoya" && "gap-4",
				)}
			>
				{branding !== "hoya" ? (
					<OSLoading className={cn("mb-4 animate-spin-slow")} />
				) : (
					<Loading
						height={48}
						width={48}
						// eslint-disable-next-line tailwindcss/no-custom-classname
						className={cn(
							"animate-spin-slow fill-primary-dark-100",
						)}
					/>
				)}
				<HeadingText h={4}>
					{t("modalUpdate.workingOnUpdate")}
				</HeadingText>
				<ButtonText
					type="normal"
					className={cn("text-center text-primary-dark-60")}
				>
					asd
				</ButtonText>
			</div>
		</div>
	);
};

export default ModalUpdate;
