import { useTranslation } from "react-i18next";
import { BodyText } from "../typography";
import { cn } from "../../util/helpers";

const DraftTag = ({ className }: { className?: string }) => {
	const { t } = useTranslation();

	return (
		<div>
			<BodyText
				type="normal"
				className={cn(
					"h-fit w-fit bg-quaternary-20 p-2 text-primary-dark-80",
					className,
				)}
			>
				{t("draftTag.draft")}
			</BodyText>
		</div>
	);
};

export default DraftTag;
