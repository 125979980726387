import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
	FloatingButton,
	OutlineButton,
	SolidButton,
	TextButton,
} from "../../components/buttons";
import {
	ArrowLeft,
	ArrowRight,
	Back,
	Close,
	MeasurementLoading,
	MeasurementReady,
	NoPhoto,
	OSArrowLeft,
	OSNoPhoto,
	OSWarning,
	Photo,
	Warning,
} from "../../components/icons";
import { BodyText, ButtonText, HeadingText } from "../../components/typography";
import {
	getAccessIdAsPromise,
	useBrandingStore,
	useSnackStore,
} from "../../store";
import { start } from "../../util/api/api-store";
import { ModalBase, ModalFullScreen } from "../../components/modals";
import { cn } from "../../util/helpers";
import Footer from "../../components/panels/Footer";

type Image = {
	src: string;
	alt: string;
	fallback: any;
	className?: string;
};

const Loading = () => {
	const { t } = useTranslation();
	const { branding } = useBrandingStore();
	const navigate = useNavigate();
	const { state } = useLocation();
	const [status, setStatus] = useState<string>("");
	const [measurement, setMeasurement] = useState<string | undefined>("");
	const openSnack = useSnackStore((state) => state.open);
	const [modalLeaveOpen, setModalLeaveOpen] = useState<boolean>(false);
	const [modalPreviewOpen, setModalPreviewOpen] = useState<boolean>(false);
	const [currentlyViewedImageIndex, setCurrentlyViewedImageIndex] =
		useState<number>(-1);

	const loadingSentences = [
		t("loading.loadingMessage1"),
		t("loading.loadingMessage2"),
		t("loading.loadingMessage3"),
		t("loading.loadingMessage4"),
		t("loading.loadingMessage5"),
		t("loading.loadingMessage6"),
	];

	const photos = ["cam0", "cam1", "cam2", "cam3", "cam4", "cam5"];

	const decreaseCurrentlyViewedImageIndex = () => {
		setCurrentlyViewedImageIndex((state) => {
			if (state > 0) {
				return state - 1;
			}
			return state;
		});
	};

	const increaseCurrentlyViewedImageIndex = () => {
		setCurrentlyViewedImageIndex((state) => {
			if (state + 1 < photos.length) {
				return state + 1;
			}
			return state;
		});
	};

	const displayImage = (index: number) => {
		setModalPreviewOpen(true);
		setCurrentlyViewedImageIndex(index);
		// forecUpdate();
	};

	const Image = ({ src, alt, fallback, className }: Image) => {
		const [error, setError] = useState(false);

		const onError = () => {
			setError(true);
		};

		return error ? (
			fallback
		) : (
			<img
				src={`${src}?id=${(Math.random() + 1).toString(36).substring(7)}`}
				alt={alt}
				className={className}
				loading="lazy"
				onError={onError}
			/>
		);
	};

	useEffect(() => {
		getAccessIdAsPromise().then((access_id) => {
			const newDate = new Date();

			const data = {
				access_id: access_id,
				order_id: state.order_id,
				eye_catcher: state.eye_catcher,
				client_time_zone: 2, //@todo ???
				timestamp: format(newDate, "yyyy-MM-dd HH:mm:ss"),
				client_time: parseInt(format(newDate, "t")),
				frame_type: state.frame_type,
			};

			start.setRequest(data);
			start
				.fetchData()
				.then((resp) => {
					if (resp.camera_pair < 0) {
						setStatus("error");
					} else {
						setStatus("loaded");
						setMeasurement(resp.measurement);
					}
				})
				.catch((err) => {
					if (err.kind === "api-response-error") {
						useSnackStore
							.getState()
							.open(t("loading.camerasAreInUse"), "warning");
						navigate("/measurement/start", {
							state: {
								...state,
								pms: false,
							},
						});
					} else {
						navigate("/");
						openSnack(err?.errorData?.error, "warning");
					}
				});
		});
	}, []);

	return (
		<>
			<div
				className={cn(
					"fixed top-0 z-10 w-full shadow-[0px_1px_0px_#e6e8e9] backdrop-blur before:absolute before:inset-0 before:bg-pure-white before:opacity-[0.88]",
				)}
			>
				<div
					className={cn(
						"relative grid grid-cols-[1fr_auto_1fr] items-center px-6",
						branding !== "hoya" ? "container h-[85px]" : "h-[72px]",
					)}
				>
					{branding !== "hoya" && (
						<TextButton
							color="primary"
							icon={OSArrowLeft}
							iconClasses={cn(
								"size-[30px] !rounded-[10px] border border-quaternary-80 p-[5px] h-fit",
							)}
							className={cn(
								"[&>span]:hidden [&>span]:md:inline-block",
							)}
							onClick={() => {
								navigate("/measurement/start", {
									state: {
										...state,
										previousPage: state?.previousPage,
									},
								});
							}}
						></TextButton>
					)}
					{branding === "hoya" && !status && (
						<TextButton
							color="primary"
							icon={Back}
							className={cn(
								"[&>span]:hidden [&>span]:md:inline-block",
							)}
							onClick={() => {
								navigate("/measurement/start", {
									state: {
										...state,
										previousPage: state?.previousPage,
									},
								});
							}}
						>
							{t("application.btnBackCaption")}
						</TextButton>
					)}
					{state?.order_id && (
						<BodyText
							type="bold16"
							className={cn(
								"col-start-2 -ml-3 line-clamp-2 [&>span]:hidden [&>span]:md:inline-block",
							)}
						>
							{state.order_id}
						</BodyText>
					)}
					<TextButton
						color="secondary"
						disabled={false}
						fullWidth={false}
						icon={Close}
						iconClasses={
							branding !== "hoya" ? "size-[17px]" : undefined
						}
						onClick={() => {
							setModalLeaveOpen(true);
						}}
						className={cn(
							"col-start-3 justify-self-end",
							branding !== "hoya" &&
								"h-fit !rounded-[10px] border border-quaternary-80 p-[5px]",
						)}
					/>
				</div>
			</div>
			<div className={cn("flex min-h-dvh md:items-center")}>
				<div
					className={cn(
						"container flex flex-col gap-3 py-[72px] text-center",
						branding !== "hoya" && "w-full items-center gap-5",
						branding !== "hoya" &&
							status !== "error" &&
							"max-w-[545px]",
					)}
				>
					<div
						className={cn(
							"mb-7 mt-12 grid place-items-center text-quaternary-100 md:mt-0",
							branding !== "hoya" && "mb-4",
						)}
					>
						{branding === "hoya" && (
							<p className={cn("col-start-1 row-start-1")}>
								{status ? (
									<svg
										fill="none"
										width={128}
										height={80}
										className="mx-auto"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 128 80"
									>
										<path
											d="M126.85 37.71C126.27 36 107.25 0 64 0S1.73 36 1.15 37.71L0 40l1.15 2.29C1.73 44 20.75 80 64 80s62.27-36 62.85-37.71L128 40l-1.15-2.29Z"
											fill="currentColor"
										/>
										<path
											d="M64 64c13.255 0 24-10.745 24-24S77.255 16 64 16 40 26.745 40 40s10.745 24 24 24Z"
											fill="#fff"
										/>
									</svg>
								) : (
									<svg
										fill="none"
										width={128}
										height={80}
										className="mx-auto"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 128 80"
									>
										<path
											d="M126.85 37.71C126.27 36 107.25 0 64 0S1.73 36 1.15 37.71L0 40l1.15 2.29C1.73 44 20.75 80 64 80s62.27-36 62.85-37.71L128 40l-1.15-2.29Z"
											fill="currentColor"
										/>
										<path
											d="M80.97 23.03A23.927 23.927 0 0 0 64 16c-6.63 0-12.63 2.69-16.97 7.03A23.927 23.927 0 0 0 40 40c0 6.63 2.69 12.63 7.03 16.97A23.927 23.927 0 0 0 64 64c6.63 0 12.63-2.69 16.97-7.03L64 40l16.97-16.97Z"
											fill="currentColor"
										/>
									</svg>
								)}
							</p>
						)}
						{status ? (
							<span className={cn("col-start-1 row-start-1")}>
								{status === "loaded" &&
									(branding !== "hoya" ? (
										<MeasurementReady className="size-[140px] text-primary-100" />
									) : (
										<Photo />
									))}
								{status === "error" &&
									(branding !== "hoya" ? (
										<OSWarning className="size-[140px]" />
									) : (
										<Warning />
									))}
							</span>
						) : (
							<>
								{branding === "hoya" ? (
									<span
										className={cn(
											"col-start-1 row-start-1",
										)}
									>
										<div className="loader"></div>
									</span>
								) : (
									<MeasurementLoading
										className={cn(
											"size-[140px] animate-loading text-primary-100",
										)}
									/>
								)}
							</>
						)}
					</div>
					<HeadingText
						h={2}
						className={cn(
							branding !== "hoya" &&
								"!text-[26px] !leading-[30px]",
						)}
					>
						<>
							{!status && t("loading.measurement")}
							{status === "loaded" && t("loading.loaded")}
							{status === "error" && t("loading.failed")}
						</>
					</HeadingText>
					{!status && (
						<p className="text-primary-dark-80">
							{
								loadingSentences[
									Math.floor(
										Math.random() * loadingSentences.length,
									)
								]
							}
						</p>
					)}
					{status === "error" && (
						<>
							<hr hidden={branding !== "hoya"} className="my-6" />
							<div
								className={cn(
									"prose mb-6 text-left text-primary-dark-80 prose-li:marker:text-primary-dark-80",
									branding !== "hoya" &&
										"max-w-[545px] px-2.5 prose-li:my-0",
								)}
							>
								<ul>
									<li>{t("loading.failedMessage1")}</li>
									<li>{t("loading.failedMessage2")}</li>
									<li>{t("loading.failedMessage3")}</li>
									<li>{t("loading.failedMessage4")}</li>
								</ul>
							</div>
							<div
								className={cn(
									"mb-12 flex w-full flex-col gap-8 md:mb-0",
								)}
							>
								<HeadingText
									h={4}
									className={cn(
										branding !== "hoya" && "text-left",
									)}
								>
									{t("loading.photos")}
								</HeadingText>
								<div
									className={cn(
										"grid grid-cols-3 gap-4 md:grid-cols-6",
									)}
								>
									{photos.map((item, index) => (
										<button
											type="button"
											key={item}
											onClick={() => displayImage(index)}
											className={cn("grid")}
										>
											<Image
												src={`${import.meta.env.VITE_MASTER_SERVER_HOST}img/${item}.jpg`}
												alt={item}
												fallback={
													<span
														className={cn(
															"grid aspect-[154/205] place-content-center bg-quaternary-40",
														)}
													>
														{branding !== "hoya" ? (
															<OSNoPhoto
																width={40}
																height={40}
															/>
														) : (
															<NoPhoto />
														)}
													</span>
												}
											/>
										</button>
									))}
								</div>
							</div>
							<SolidButton
								color="primary"
								onClick={() => {
									navigate("/measurement/start", {
										state: {
											...state,
											previousPage: state?.previousPage,
										},
									});
								}}
								fullWidth
								className={cn(
									branding !== "hoya"
										? "mt-4 max-w-[545px]"
										: "hidden",
								)}
							>
								{t("loading.restartMeasurement")}
							</SolidButton>
						</>
					)}
					{branding !== "hoya" && status === "loaded" && (
						<div
							className={cn(
								"mt-4 flex flex-col items-center gap-5",
							)}
						>
							<SolidButton
								color="primary"
								onClick={() => {
									navigate(
										`/measurement/eyes/${measurement}/shot`,
										{
											state: {
												previousPage:
													state?.previousPage,
											},
										},
									);
								}}
							>
								{t("start.shot")}
							</SolidButton>
							<OutlineButton
								color="primary"
								onClick={() =>
									navigate("/measurement/start", {
										state: {
											...state,
											previousPage: state?.previousPage,
										},
									})
								}
							>
								{t("loading.restartMeasurement")}
							</OutlineButton>
						</div>
					)}
				</div>
			</div>
			{status && (
				<div
					hidden={branding !== "hoya"}
					className={cn(
						"fixed bottom-0 z-10 w-full shadow-[0px_-1px_0px_#e6e8e9] backdrop-blur before:absolute before:inset-0 before:bg-pure-white before:opacity-[0.88]",
					)}
				>
					<div className="container">
						{status === "loaded" && (
							<FloatingButton
								mx={false}
								num={2}
								buttons={{
									left: {
										label: t("loading.restartMeasurement"),
										onClick: () => {
											navigate("/measurement/start", {
												state: {
													...state,
													previousPage:
														state?.previousPage,
												},
											});
										},
									},
									right: {
										label: t("start.shot"),
										onClick: () =>
											navigate(
												`/measurement/eyes/${measurement}/shot`,
												{
													state: {
														previousPage:
															state?.previousPage,
													},
												},
											),
									},
								}}
							/>
						)}
						{status === "error" && (
							<FloatingButton
								mx={false}
								num={1}
								label={t("loading.restartMeasurement")}
								onClick={() =>
									navigate("/measurement/start", {
										state: {
											...state,
											previousPage: state?.previousPage,
										},
									})
								}
							/>
						)}
					</div>
				</div>
			)}
			<ModalBase
				title={t("loading.leaveMesaurement") || undefined}
				open={modalLeaveOpen}
				close={() => {
					setModalLeaveOpen(false);
				}}
			>
				<div className={cn("p-6", branding !== "hoya" && "pt-8")}>
					<p
						className={cn(
							branding !== "hoya" ? "mb-8 text-center" : "mb-6",
						)}
					>
						{t("loading.leaveMeasurementConfirm")}
					</p>
					<p className={cn(branding === "hoya" && "text-right")}>
						<SolidButton
							color="primary"
							onClick={() => {
								navigate(
									state?.previousPage
										? `${state.previousPage}`
										: "/",
								);
							}}
							className={cn(branding !== "hoya" && "w-full")}
						>
							{t("loading.quit")}
						</SolidButton>
					</p>
				</div>
			</ModalBase>
			<ModalFullScreen
				open={modalPreviewOpen}
				close={() => setModalPreviewOpen(false)}
			>
				<div className={cn("grid grid-rows-[auto_1fr]")}>
					<div
						className={cn(
							"grid h-[72px] grid-cols-[auto_auto] items-center bg-pure-white px-6 shadow-[0px_1px_0px_#e6e8e9]",
						)}
					>
						<ButtonText
							type="bold"
							className="translate-x-1/2 text-center"
						>
							{`${currentlyViewedImageIndex + 1}/${
								photos.length
							}`}
						</ButtonText>
						<TextButton
							color="secondary"
							disabled={false}
							fullWidth={false}
							icon={Close}
							iconClasses={
								branding !== "hoya" ? "size-[17px]" : undefined
							}
							onClick={() => setModalPreviewOpen(false)}
							className={cn(
								"justify-self-end",
								branding !== "hoya" &&
									"h-fit !rounded-[10px] border border-quaternary-80 p-[5px]",
							)}
						/>
					</div>
					<div className="relative">
						<div
							className={cn(
								"absolute left-12 top-1/2 cursor-pointer",
								branding !== "hoya"
									? "grid size-16 -translate-y-1/2 place-items-center overflow-hidden rounded-[10px] border border-quaternary-80 before:absolute before:inset-0 before:bg-[--primary--100] before:opacity-10"
									: "rounded-full bg-black p-3",
								currentlyViewedImageIndex <= 0 && "hidden",
							)}
							onClick={decreaseCurrentlyViewedImageIndex}
						>
							{branding !== "hoya" ? (
								<OSArrowLeft width={28} height={28} />
							) : (
								<ArrowLeft
									width={48}
									height={48}
									className="fill-pure-white"
								/>
							)}
						</div>
						<div className="flex justify-center">
							<Image
								src={`${import.meta.env.VITE_MASTER_SERVER_HOST}img/${photos[currentlyViewedImageIndex]}.jpg`}
								alt={photos[currentlyViewedImageIndex]}
								className={cn(
									branding !== "hoya"
										? "h-[calc(100vh-72px-72px)]"
										: "h-[calc(100vh-72px)]",
									"w-full object-contain object-center",
								)}
								fallback={
									<span
										className={cn(
											branding !== "hoya"
												? "h-[calc(100vh-72px-72px)]"
												: "h-[calc(100vh-72px)]",
											"grid w-full place-content-center bg-quaternary-40 md:aspect-[154/205] md:w-auto",
										)}
									>
										{branding !== "hoya" ? (
											<OSNoPhoto
												className={cn(
													"scale-50 md:scale-100",
												)}
											/>
										) : (
											<NoPhoto
												width={236}
												height={236}
												className={cn(
													"scale-50 md:scale-100",
												)}
											/>
										)}
									</span>
								}
							/>
						</div>
						<div
							className={cn(
								"absolute right-12 top-1/2 cursor-pointer",
								branding !== "hoya"
									? "grid size-16 -translate-y-1/2 place-items-center overflow-hidden rounded-[10px] border border-quaternary-80 before:absolute before:inset-0 before:bg-[--primary--100] before:opacity-10"
									: "rounded-full bg-black p-3",
								currentlyViewedImageIndex + 1 >=
									photos.length && "hidden",
							)}
							onClick={increaseCurrentlyViewedImageIndex}
						>
							{branding !== "hoya" ? (
								<OSArrowLeft
									width={28}
									height={28}
									className="-scale-x-100"
								/>
							) : (
								<ArrowRight
									width={48}
									height={48}
									className="fill-pure-white"
								/>
							)}
						</div>
					</div>
				</div>
				{branding !== "hoya" && <Footer />}
			</ModalFullScreen>
		</>
	);
};

export default Loading;
