import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
	FloatingButton,
	SolidButton,
	TextButton,
} from "../../components/buttons";
import { FrameLayout } from "../../components/layouts";
import Footer from "../../components/panels/Footer";
import { BodyText, HeadingText } from "../../components/typography";
import TextBody from "../../components/typography/TextBody";
import { getSettings, onboardingDone } from "../../util/api/api-store";
import { useBrandingStore } from "../../store";
import { application, cn } from "../../util/helpers";
import Modal from "react-modal";
import BrandLogo from "../../components/logo/BrandLogo";

const Welcome = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { branding } = useBrandingStore();

	const start = () => {
		onboardingDone.setRequest(null);
		onboardingDone.fetchData().then((resp) => {
			console.log(resp);
			getSettings.invalidate();
			getSettings.fetchData();
			navigate("/");
		});
	};

	useEffect(() => {
		getSettings.setRequest(null, false);
		getSettings.fetchData().then((resp) => {
			if (resp.onboard) {
				navigate("/");
				return;
			}
		});
	}, []);

	const style1 = { height: "calc(100vh - 144px)" };
	const style2 = { height: "calc(100dvh - 144px)" };

	if (branding !== "hoya") {
		return (
			<main className="bg-[#b4b4b4]">
				<Modal
					closeTimeoutMS={0}
					isOpen={true}
					className={cn(
						"fixed inset-0 overflow-y-auto focus-visible:outline-0",
					)}
					overlayClassName={cn("fixed inset-0 z-30 bg-transparent")}
					bodyOpenClassName={cn("overflow-hidden")}
				>
					<div
						className={cn(
							"flex min-h-full items-center justify-center p-4 sm:p-0",
						)}
					>
						<div
							className={cn(
								"relative flex min-h-[767px] w-full flex-col overflow-hidden rounded-[50px] bg-pure-white transition-all sm:my-8 sm:max-w-[620px]",
							)}
						>
							<div
								className={cn(
									"flex items-center justify-between border-b-[1px] border-secondary-40 px-12 py-8",
								)}
							>
								<BrandLogo />
								<div className={cn("flex flex-col items-end")}>
									<BodyText
										type={"normal"}
										className={cn(
											"!leading-[1em] text-pure-black",
										)}
									>
										<span
											dangerouslySetInnerHTML={{
												__html: application[branding]
													.title,
											}}
										></span>
									</BodyText>
									<p
										className="text-[8px] text-pure-black"
										dangerouslySetInnerHTML={{
											__html: application[branding]
												.title_visureal,
										}}
									></p>
								</div>
							</div>
							<div
								className={cn(
									"grid flex-1 grid-cols-1 grid-rows-[1fr_auto] place-items-center px-14 pb-12",
								)}
							>
								<div
									className={cn(
										"flex flex-col items-center gap-8",
									)}
								>
									<HeadingText
										h={2}
										className={cn(
											"text-center !text-[26px] font-bold text-pure-black",
										)}
									>
										{t("welcome.welcome")}
									</HeadingText>
									<TextBody
										type={"normal"}
										className={cn(
											"text-center text-pure-black",
										)}
									>
										{t("welcome.pageDescription")}
									</TextBody>
									<img
										src={`/images/${branding}-welcome.png`}
										alt="os_welcome"
									/>
								</div>
								<div
									className={cn("flex w-full flex-col gap-5")}
								>
									<SolidButton
										color="primary"
										onClick={() =>
											navigate("/onboarding/language")
										}
										fullWidth
									>
										{t("welcome.startSetup")}
									</SolidButton>
								</div>
							</div>
						</div>
					</div>
				</Modal>
			</main>
		);
	}

	return (
		<>
			<FrameLayout headerType="onboarding" hiddenFooter={true}>
				<div
					className="container grid content-center"
					style={{ ...style1, ...style2 }}
				>
					<HeadingText
						h={1}
						className={cn("mb-3 text-center text-primary-dark-100")}
					>
						{t("welcome.welcome")}
					</HeadingText>
					<TextBody
						type={"normal"}
						className={cn("text-center text-primary-dark-80")}
					>
						{t("welcome.pageDescription")}
					</TextBody>
				</div>
			</FrameLayout>
			<Footer stickyFooter>
				<div className="container">
					<FloatingButton
						mx={false}
						num={1}
						label={t("welcome.startSetup")}
						onClick={() => navigate("/onboarding/language")}
					/>
				</div>
			</Footer>
		</>
	);
};

export default Welcome;
