import J from "joi";

export type BrandingType =
	| "hoya"
	| "vs"
	| "ollendorf"
	| "optiswiss"
	| "visiono";

export type IRequestData = null;

export interface IResponseData {
	admin_mode: boolean;
	auto_exposure: number;
	branding: BrandingType;
	brightness: number;
	contrast: number;
	contrast_max: number;
	contrast_min: number;
	contrast_step: number;
	debug_capture_timing: boolean;
	direct_loupe: boolean;
	expose_face_form_angle: boolean;
	exposure_time: number;
	exposure_time_max: number;
	exposure_time_min: number;
	exposure_time_step: number;
	eye_catcher: boolean;
	gain_max: number;
	gain_min: number;
	hide_shutdown: boolean;
	hide_wifi_settings: boolean;
	history_show_preview: boolean;
	inactive_time: number;
	isp_digital_gain_max: number;
	measurement_retention_days: number;
	measurements_permanent: boolean;
	modules: {
		ai: boolean;
		b2b_optic: boolean;
		b2b_optic_download: boolean;
	};
	onboard: boolean;
	order_id_fixed: boolean;
	order_id_required: boolean;
	show_aux_points: boolean;
	show_coordinates: boolean;
	show_editor_changes: boolean;
	show_frame_edge_editor: boolean;
	show_menu_item_debug: boolean;
	show_reevaluate_button: boolean;
	starlight_available: boolean;
	starlight_enabled: boolean;
	update_enabled: boolean;
	shop_name?: string; //@todo optional?
	// user_instructions_url: string // The response does not contain this key.
}

export type IResponseError = null;

const responseDataShape = J.object({
	admin_mode: J.boolean(),
	auto_exposure: J.number(),
	branding: J.string().valid(
		"hoya",
		"vs",
		"ollendorf",
		"optiswiss",
		"visiono",
	),
	brightness: J.number(),
	contrast: J.number(),
	contrast_max: J.number(),
	contrast_min: J.number(),
	contrast_step: J.number(),
	debug_capture_timing: J.boolean(),
	direct_loupe: J.boolean(),
	expose_face_form_angle: J.boolean(),
	exposure_time: J.number(),
	exposure_time_max: J.number(),
	exposure_time_min: J.number(),
	exposure_time_step: J.number(),
	eye_catcher: J.boolean(),
	gain_max: J.number(),
	gain_min: J.number(),
	hide_shutdown: J.boolean(),
	hide_wifi_settings: J.boolean(),
	history_show_preview: J.boolean(),
	inactive_time: J.number(),
	isp_digital_gain_max: J.number(),
	measurement_retention_days: J.number(),
	measurements_permanent: J.boolean(),
	modules: J.object({
		ai: J.boolean(),
		b2b_optic: J.boolean(),
		b2b_optic_download: J.boolean(),
	}).unknown(),
	onboard: J.boolean(),
	order_id_fixed: J.boolean(),
	order_id_required: J.boolean(),
	shop_name: J.string().allow("").optional(), //@todo optional?
	show_aux_points: J.boolean(),
	show_coordinates: J.boolean(),
	show_editor_changes: J.boolean(),
	show_frame_edge_editor: J.boolean(),
	show_menu_item_debug: J.boolean(),
	show_reevaluate_button: J.boolean(),
	starlight_available: J.boolean(),
	starlight_enabled: J.boolean(),
	update_enabled: J.boolean(),
	// user_instructions_url: J.string() // The response does not contain this key.
})
	.required()
	.unknown();

const uri = import.meta.env.VITE_MASTER_SERVER_HOST + "get_settings";

// eslint-disable-next-line
export function isValidResponse(resp: any): resp is IResponseData {
	const validationError = responseDataShape.validate(resp).error;
	return validationError === undefined;
}

export function isValidResponseError(respErr: any): respErr is IResponseError {
	return respErr === null;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function request(_: IRequestData): Promise<Response> {
	return fetch(uri, {
		method: "GET",
	});
}
