import Modal from "react-modal";
import { Loading } from "../icons";
import { cn } from "../../util/helpers";

type ModalFullScreenLoadingProps = {
	children: React.ReactNode;
	open: boolean;
	className?: string;
	loadingClass?: string;
};

Modal.setAppElement("#root");

const ModalFullScreenLoading = ({
	children,
	open,
	className,
	loadingClass,
}: ModalFullScreenLoadingProps) => {
	return (
		<Modal
			closeTimeoutMS={300}
			isOpen={open}
			className={cn(
				className,
				"fixed inset-0 overflow-y-auto focus-visible:outline-0",
			)}
			overlayClassName={cn(
				"fixed inset-0 z-30 bg-black/50 transition-opacity",
			)}
			bodyOpenClassName={cn("overflow-hidden")}
		>
			<div
				className={cn(
					"flex min-h-full flex-col items-center justify-center",
				)}
			>
				<Loading className={cn(loadingClass, "animate-spin")} />
				{children}
			</div>
		</Modal>
	);
};

export default ModalFullScreenLoading;
