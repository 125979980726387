import J from "joi";

export type IRequestData = null;

export interface IResponseData {
	IP4_LAN: string;
	IP4_WLAN: string;
	IP6_LAN: string;
	IP6_WLAN: string;
	vpn_accessibility: string;
}

export type IResponseError = null;

const responseDataShape = J.object({
	IP4_LAN: J.string().allow(""),
	IP4_WLAN: J.string().allow(""),
	IP6_LAN: J.string().allow(""),
	IP6_WLAN: J.string().allow(""),
	vpn_accessibility: J.string().allow(""),
})
	.required()
	.unknown();

const uri = import.meta.env.VITE_MASTER_SERVER_HOST + "get_network_info";

// eslint-disable-next-line
export function isValidResponse(resp: any): resp is IResponseData {
	const validationError = responseDataShape.validate(resp).error;
	return validationError === undefined;
}

export function isValidResponseError(respErr: any): respErr is IResponseError {
	return respErr === null;
}

// eslint-disable-next-line
export function request(_: IRequestData): Promise<Response> {
	return fetch(uri, {
		method: "GET",
	});
}
