import { useTranslation } from "react-i18next";
import { useState } from "react";
import { OutlineButton, TextButton } from "../buttons";
import {
	Warning,
	Info as InfoIcon,
	Done,
	OSWarning,
	GreenTick,
	OSArrowLeft,
} from "../icons";
import { BodyText, ButtonText } from "../typography";
import { useBrandingStore } from "../../store";
import { cn } from "../../util/helpers";

type InfoButton = {
	text: string;
	onClick: () => void;
};

type InfoProps = {
	type: "default" | "info" | "alert" | "done";
	title: string;
	message?: string;
	button?: InfoButton;
	isCloseable?: boolean;
	customControl?: React.ReactNode;
	className?: string;
};

const Info = ({
	title = "Test title",
	type,
	button,
	isCloseable,
	message,
	customControl,
	className,
}: InfoProps) => {
	const { t } = useTranslation();
	const { branding } = useBrandingStore();
	const [isOpen, setIsOpen] = useState<boolean>(true);

	return (
		<div
			className={cn(
				type === "alert" ? "bg-system-danger-20" : "bg-quaternary-20",
				"w-full p-3",
				"flex items-start justify-between",
				branding !== "hoya" && "rounded-[10px] px-5 py-4",
				branding !== "hoya" &&
					type === "alert" &&
					"relative overflow-hidden before:absolute before:inset-0 before:bg-[--primary--100] before:opacity-10",
				className,
			)}
		>
			<div
				className={cn(
					"flex w-full flex-col py-3 pl-3",
					(message || isCloseable) && "gap-2",
					branding !== "hoya" && "p-0",
				)}
			>
				<div
					className={cn(
						"grid gap-x-5",
						isCloseable
							? "grid-cols-[auto_1fr_auto]"
							: "grid-cols-[auto_1fr]",
					)}
				>
					{(type === "default" || type === "alert") && (
						<>
							{branding !== "hoya" ? (
								<OSWarning className="size-10 translate-y-0.5" />
							) : (
								<Warning
									className={cn(
										type === "default"
											? "fill-primary-dark-100"
											: "fill-system-danger-100",
									)}
								/>
							)}
						</>
					)}
					{type === "info" && (
						<>
							{branding !== "hoya" ? (
								<OSWarning className="size-10 translate-y-0.5" />
							) : (
								<InfoIcon className="fill-primary-dark-100" />
							)}
						</>
					)}
					{type === "done" && (
						<>
							{branding !== "hoya" ? (
								<GreenTick className="translate-y-0.5" />
							) : (
								<Done className="fill-primary-dark-100" />
							)}
						</>
					)}
					<div className={cn("my-auto flex flex-col gap-2.5")}>
						<ButtonText type="bold">{title}</ButtonText>
						{isOpen && (message || button || customControl) && (
							<div
								className={cn(
									"flex flex-col gap-6",
									branding !== "hoya" && "",
								)}
							>
								{message && (
									<BodyText
										type={
											branding !== "hoya"
												? "normal"
												: "small"
										}
										className={cn(
											branding !== "hoya" &&
												"text-primary-dark-60",
										)}
									>
										{message}
									</BodyText>
								)}
								{button && (
									<div
										className={cn(
											"grid gap-6 md:grid-cols-2",
										)}
									>
										{branding !== "hoya" ? (
											<TextButton
												color="primary"
												onClick={button.onClick}
												icon={OSArrowLeft}
												iconPosition="suffix"
												iconClasses="-scale-x-100"
												className="-ml-3"
											>
												{button.text}
											</TextButton>
										) : (
											<OutlineButton
												color="primary"
												onClick={button.onClick}
											>
												{button.text}
											</OutlineButton>
										)}
									</div>
								)}
								{!button && customControl && (
									<>{customControl}</>
								)}
							</div>
						)}
					</div>
				</div>
			</div>
			{isCloseable && (
				<div className="">
					<TextButton
						onClick={() => {
							isOpen ? setIsOpen(false) : setIsOpen(true);
						}}
						color="primary"
						className={cn(
							"shrink-0",
							branding !== "hoya" &&
								"h-fit !p-0 text-primary-80 underline underline-offset-2 [&>span]:font-normal",
						)}
					>
						{isOpen ? t("info.hide") : t("info.show")}
					</TextButton>
				</div>
			)}
		</div>
	);
};

export default Info;
