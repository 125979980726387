import { SVGProps } from "react";
const OSWarning = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			width="120"
			height="121"
			viewBox="0 0 120 121"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g filter="url(#filter0_d_1_3619)">
				<circle
					cx="60"
					cy="56.5"
					r="56"
					fill="var(--system-highlight--100)"
				/>
				<g clipPath="url(#clip0_1_3619)">
					<path
						d="M51.6748 28.8036C51.6748 23.9974 55.5073 20.1011 60.2348 20.1011C64.9623 20.1011 68.7949 23.9974 68.7949 28.8036V29.413C68.7949 29.5966 68.7848 29.7794 68.7638 29.9611L64.8647 63.9784C64.591 66.3676 62.6004 68.1688 60.2348 68.1688C57.8693 68.1688 55.8786 66.3676 55.605 63.9784L51.7058 29.9611C51.6848 29.7794 51.6748 29.5957 51.6748 29.413V28.8036Z"
						fill="white"
					/>
					<path
						d="M66.8971 90.37C65.0953 92.0571 62.8976 92.9011 60.3058 92.9011C57.714 92.9011 55.6266 92.0654 53.8559 90.3941C52.0851 88.7237 51.2002 86.6331 51.2002 84.1261C51.2002 81.6192 52.0851 79.5462 53.8559 77.81C55.6257 76.0747 57.776 75.2065 60.3058 75.2065C62.8356 75.2065 65.0953 76.0747 66.8971 77.81C68.6989 79.5453 69.6002 81.6516 69.6002 84.1261C69.6002 86.6007 68.6989 88.6819 66.8971 90.37Z"
						fill="white"
					/>
				</g>
			</g>
			<defs>
				<filter
					id="filter0_d_1_3619"
					x="0"
					y="0.5"
					width="120"
					height="120"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="4" />
					<feGaussianBlur stdDeviation="2" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
					/>
					<feBlend
						mode="normal"
						in2="BackgroundImageFix"
						result="effect1_dropShadow_1_3619"
					/>
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="effect1_dropShadow_1_3619"
						result="shape"
					/>
				</filter>
				<clipPath id="clip0_1_3619">
					<rect
						width="18.4"
						height="72.8"
						fill="white"
						transform="translate(51.2002 20.1011)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default OSWarning;
