import { useEffect, useRef, useState } from "react";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperCore } from "swiper/types";
import { SolidButton, TextButton } from "../../components/buttons";
import { DraftItem, DraftItemShowAll, MainCard } from "../../components/cards";
import { History, Next, OSNext, Photo } from "../../components/icons";
import { FrameLayout } from "../../components/layouts";
import { ButtonText, HeadingText } from "../../components/typography";
import { useTranslation } from "react-i18next";
import {
	getOpticianId,
	getServerStatus,
	getSettings,
	home,
	listMeasurements,
} from "../../util/api/api-store";
import { getAccessIdAsPromise, useBrandingStore } from "../../store";
import Loading from "../../components/icons/Loading";

import "swiper/css";
import { ModalBase } from "../../components/modals";
import { useNavigate } from "react-router-dom";
import { add, isAfter } from "date-fns";
import { cn } from "../../util/helpers";
import OSMeasurement from "../../components/icons/optiswiss/Measurement";
import OSHistory from "../../components/icons/optiswiss/History";

function Home() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const swiperRef = useRef<SwiperCore>();
	const [isBeginning, setIsBeginning] = useState(false);
	const [isEnd, setIsEnd] = useState(false);
	const [modalUpdateOpen, setModalUpdateOpen] = useState<boolean>(false);
	const [modalMemoryWarning, setModalMemoryWarning] =
		useState<boolean>(false);
	const { branding } = useBrandingStore();

	const draftInfo = listMeasurements
		.useGetResult()
		.map(({ measurements, measurement_count, measurement_count_max }) => ({
			drafts: measurements.filter((e) => !e.finished).slice(0, 20),
			draftCount: measurements.reduce(
				(c, e) => (e.finished ? c : c + 1),
				0,
			),
			measurementCount: measurement_count,
			measurementCountMax: measurement_count_max,
		}));

	const uri = import.meta.env.VITE_MASTER_SERVER_HOST;

	const { shopName } = getOpticianId
		.useGetResult()
		.map((resp) => ({
			shopName: resp.shop_name,
		}))
		.unwrapOrDefault({
			shopName: "",
		});

	const closeUpdateModal = () => {
		setModalUpdateOpen(false);
	};

	const remindUpdateModal = () => {
		closeUpdateModal();
		window.localStorage.setItem(
			"updateReminder",
			add(new Date(), { days: 1 }).toDateString(),
		);
	};

	const dontRemindUpdateModal = () => {
		closeUpdateModal();
		window.localStorage.setItem(
			"updateReminder",
			add(new Date(), { years: 444 }).toDateString(),
		);
	};

	const closeModalMemoryWarning = () => {
		setModalMemoryWarning(false);
	};

	useEffect(() => {
		getOpticianId.setRequest(null, true);
		getOpticianId.fetchData();
	}, []);

	useEffect(() => {
		getAccessIdAsPromise().then((access_id) => {
			home.setRequest({ access_id });
			home.invalidate();
			home.fetchData().then(() => {
				listMeasurements.setRequest(
					{
						client_time: Math.floor(Date.now() / 1000),
						version: 2,
					},
					true,
				);
				listMeasurements.invalidate();
				listMeasurements.fetchData().then((resp) => {
					if (
						25 >=
						resp.measurement_count_max - resp.measurement_count
					) {
						setModalMemoryWarning(true);
					}
				});
			});
		});

		getServerStatus.setRequest(null);
		getServerStatus.fetchData().then(({ update_ready }) => {
			const updateReminder =
				window.localStorage.getItem("updateReminder");
			getSettings.setRequest(null, true);
			getSettings.fetchData().then(({ update_enabled }) => {
				if (!updateReminder && update_ready && update_enabled) {
					setModalUpdateOpen(true);
				}
				if (
					updateReminder &&
					update_enabled &&
					isAfter(new Date(), Date.parse(updateReminder)) &&
					update_ready
				) {
					setModalUpdateOpen(true);
				}
			});
		});
	}, []);

	return (
		<FrameLayout stickyFooter>
			<div
				className={cn(
					branding !== "hoya"
						? "container flex flex-col gap-4 pt-12 lg:pt-4"
						: "container pt-12 md:pt-16",
				)}
			>
				<div
					className={cn(
						branding !== "hoya" &&
							"grid grid-cols-2 grid-rows-[auto_auto_auto] gap-5 md:grid-rows-[auto_auto]",
					)}
				>
					<div
						style={{
							backgroundImage: `url(/images/${branding}-banner.png)`,
						}}
						className={cn(
							branding !== "hoya" &&
								"w-full overflow-hidden rounded-[20px] bg-cover bg-center bg-no-repeat [grid-area:1/1/1/3]",
						)}
					>
						<HeadingText
							h={2}
							className={cn(
								branding !== "hoya"
									? "px-14 py-[70px]"
									: "mb-6 md:mb-8",
							)}
						>
							{shopName
								? t("home.welcomeName", { name: shopName })
								: t("home.welcome")}
						</HeadingText>
					</div>
					<MainCard
						title={t("home.measurement")}
						to="/measurement"
						icon={branding !== "hoya" ? OSMeasurement : Photo}
						message={t("home.measurementSubtext")}
						className={cn(
							branding !== "hoya"
								? "text-primary-100 [grid-area:2/1/2/3] md:[grid-area:2/1/2/1]"
								: "mb-3",
						)}
					/>
					<MainCard
						title={t("home.history")}
						to="/history"
						icon={branding !== "hoya" ? OSHistory : History}
						message={t("home.historySubtext")}
						className={cn(
							branding !== "hoya"
								? "text-primary-100 [grid-area:3/1/3/3] md:[grid-area:2/2/2/2]"
								: "mb-12 md:mb-16",
						)}
					/>
				</div>

				<div className="mb-6 flex items-center justify-between">
					<HeadingText
						h={4}
						className={cn(branding !== "hoya" && "!text-base")}
					>
						{draftInfo.kind === "ok"
							? t("home.incompletes", {
									count: draftInfo.data.draftCount,
								})
							: t("home.loadingIncompletes")}
					</HeadingText>
					<TextButton
						color="primary"
						to="/history"
						state={{ showDrafts: true }}
						className={cn(
							branding !== "hoya" &&
								"text-base text-primary-80 underline underline-offset-2 [&>span]:font-normal",
						)}
					>
						{t("home.showAllIncompletes")}
					</TextButton>
				</div>
			</div>
			<div className={cn("container relative pb-16")}>
				<div
					className={cn(
						"relative before:pointer-events-none after:pointer-events-none",
						branding !== "hoya"
							? "before:absolute before:left-0 before:top-0 before:z-10 before:h-full before:w-32 before:bg-gradient-to-r before:from-white before:opacity-100 before:transition-opacity before:ease-in-out after:absolute after:right-0 after:top-0 after:z-10 after:h-full after:w-32 after:bg-gradient-to-l after:from-white after:opacity-100 after:transition-opacity after:ease-in-out"
							: "before:absolute before:inset-y-0 before:right-0 before:z-10 before:w-6 before:translate-x-full before:bg-gradient-to-l before:from-white after:absolute after:inset-y-0 after:right-0 after:z-10 after:w-screen after:translate-x-[calc(100vw+64px)] after:bg-white md:before:w-12 xl:before:w-16",
						branding !== "hoya" &&
							isBeginning &&
							"before:opacity-0",
						branding !== "hoya" && isEnd && "after:opacity-0",
					)}
				>
					{draftInfo.kind === "ok" ? (
						<>
							<Swiper
								modules={[Navigation]}
								spaceBetween={branding !== "hoya" ? 15 : 12}
								slidesPerView={1.5}
								onSlideChange={(swiper) => {
									swiperRef.current = swiper;
									setIsBeginning(swiper.isBeginning);
									setIsEnd(swiper.isEnd);
								}}
								onSwiper={(swiper) => console.log(swiper)}
								className="overflow-visible xl:overflow-hidden"
								breakpoints={{
									768: {
										slidesPerView:
											branding !== "hoya" ? 2.75 : 3.5,
									},
									1280: {
										slidesPerView:
											branding !== "hoya" ? 3.5 : 3,
									},
								}}
								onBeforeInit={(swiper) => {
									swiperRef.current = swiper;
									setIsBeginning(swiper.isBeginning);
									setIsEnd(swiper.isEnd);
								}}
							>
								{draftInfo.data.drafts.map((draft) => (
									<SwiperSlide key={draft.id}>
										<DraftItem
											id={draft.id}
											name={draft.order_id}
											date={new Date(draft.last_modified)}
											image={uri + draft.preview_2}
										/>
									</SwiperSlide>
								))}
								<SwiperSlide>
									<DraftItemShowAll
										draftCount={draftInfo.data.draftCount}
									/>
								</SwiperSlide>
							</Swiper>
							<button
								onClick={() => swiperRef.current?.slidePrev()}
								disabled={swiperRef.current?.isBeginning}
								className={cn(
									branding !== "hoya"
										? "absolute -bottom-10 left-0 z-10 hidden text-primary-100 disabled:hidden xl:block"
										: "absolute left-0 top-1/2 z-10 hidden -translate-x-12 -translate-y-1/2 disabled:hidden xl:block",
								)}
							>
								{branding !== "hoya" ? (
									<OSNext className="rotate-180" />
								) : (
									<Next className="rotate-180" />
								)}
							</button>
							<button
								onClick={() => swiperRef.current?.slideNext()}
								disabled={swiperRef.current?.isEnd}
								className={cn(
									branding !== "hoya"
										? "absolute -bottom-10 right-0 z-10 hidden text-primary-100 disabled:hidden xl:block"
										: "absolute right-0 top-1/2 z-10 hidden -translate-y-1/2 translate-x-12 disabled:hidden xl:block",
								)}
							>
								{branding !== "hoya" ? <OSNext /> : <Next />}
							</button>
						</>
					) : (
						<div className="flex h-full w-full animate-spin items-center justify-center">
							<Loading width={48} height={48} />
						</div>
					)}
				</div>
			</div>
			<ModalBase
				title={t("home.updateAvailable") || undefined}
				open={modalUpdateOpen}
				close={remindUpdateModal}
			>
				<div className="p-6">
					<p className="mb-3">{t("home.updateText")}</p>
					<p className="mb-10">
						<ButtonText
							type="bold"
							className="cursor-pointer text-primary-100"
							onClick={() => navigate("/settings/update#details")}
						>
							{t("home.detailsOfUpdate")}
						</ButtonText>
					</p>
					<SolidButton
						color="primary"
						onClick={() => navigate("/settings/update")}
						fullWidth
					>
						{t("home.updateNow")}
					</SolidButton>
					<TextButton
						color="primary"
						className={cn("mt-4 text-primary-100")}
						onClick={dontRemindUpdateModal}
						fullWidth
					>
						{t("home.dontRemind")}
					</TextButton>
				</div>
			</ModalBase>
			<ModalBase
				title={
					draftInfo.kind === "ok" &&
					draftInfo.data.measurementCountMax !==
						draftInfo.data.measurementCount
						? t("home.memoryFullSoon") || undefined
						: t("home.memoryFull") || undefined
				}
				open={modalMemoryWarning}
				close={closeModalMemoryWarning}
				disableClose={
					draftInfo.kind === "ok" &&
					draftInfo.data.measurementCountMax !==
						draftInfo.data.measurementCount
						? false
						: true
				}
			>
				<div className="p-6">
					<p className="mb-6">
						{draftInfo.kind === "ok" &&
						draftInfo.data.measurementCountMax !==
							draftInfo.data.measurementCount
							? t("home.pleaseDeleteCount", {
									count:
										draftInfo.data.measurementCountMax -
										draftInfo.data.measurementCount,
								})
							: t("home.pleaseDelete")}
					</p>
					<p className="text-right">
						<SolidButton
							color="primary"
							onClick={() => {
								closeModalMemoryWarning();
								navigate("/history");
							}}
						>
							{t("home.goToHistory")}
						</SolidButton>
					</p>
				</div>
			</ModalBase>
		</FrameLayout>
	);
}

export default Home;
