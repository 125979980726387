import { SVGProps } from "react";

const Trash = (props: SVGProps<SVGSVGElement> & { title?: string }) => {
	return (
		<svg
			width="14"
			height="18"
			viewBox="0 0 14 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g clipPath="url(#clip0_1_3944)">
				<path
					d="M12.8631 5.17969L12.1334 16.436L12.1322 16.4504C12.0581 17.0493 11.5395 17.5006 10.9269 17.5006H2.85774C2.24518 17.5006 1.72659 17.0493 1.65243 16.4504L1.6496 16.4209L0.92041 5.17969C1.00873 5.2003 1.09931 5.21089 1.19386 5.21089H1.9655L2.68733 16.3362C2.70205 16.417 2.77395 16.4761 2.85774 16.4761H10.9269C11.0107 16.4761 11.0826 16.4165 11.0973 16.3362L11.818 5.21089H12.5902C12.6836 5.21089 12.7754 5.19974 12.8631 5.17969Z"
					fill="currentColor"
				/>
				<path
					d="M4.81032 14.7692C4.52272 14.7692 4.29004 14.5397 4.29004 14.2572V7.42995C4.29004 7.14694 4.52329 6.91797 4.81032 6.91797C5.09735 6.91797 5.3306 7.1475 5.3306 7.42995V14.2572C5.3306 14.5403 5.09735 14.7692 4.81032 14.7692Z"
					fill="currentColor"
				/>
				<path
					d="M6.89235 14.7692C6.60475 14.7692 6.37207 14.5397 6.37207 14.2572V7.42995C6.37207 7.14694 6.60532 6.91797 6.89235 6.91797C7.17938 6.91797 7.41263 7.1475 7.41263 7.42995V14.2572C7.41263 14.5403 7.17938 14.7692 6.89235 14.7692Z"
					fill="currentColor"
				/>
				<path
					d="M8.97341 14.7692C8.68581 14.7692 8.45312 14.5397 8.45312 14.2572V7.42995C8.45312 7.14694 8.68637 6.91797 8.97341 6.91797C9.26044 6.91797 9.49369 7.1475 9.49369 7.42995V14.2572C9.49369 14.5403 9.26044 14.7692 8.97341 14.7692Z"
					fill="currentColor"
				/>
				<path
					d="M12.5904 2.07031H1.19399C0.535567 2.07031 0 2.59733 0 3.24524V4.03577C0 4.59176 0.393467 5.05806 0.920542 5.1795C1.00886 5.20012 1.09944 5.2107 1.19399 5.2107H12.5904C12.6838 5.2107 12.7755 5.19956 12.8632 5.1795C13.3903 5.05806 13.7838 4.59176 13.7838 4.03577V3.24524C13.7838 2.59733 13.2482 2.07031 12.5904 2.07031ZM12.7432 4.03577C12.7432 4.11934 12.6747 4.18675 12.5904 4.18675H1.19399C1.10963 4.18675 1.04056 4.11934 1.04056 4.03577V3.24524C1.04056 3.16223 1.10907 3.09427 1.19399 3.09427H12.5904C12.6742 3.09427 12.7432 3.16168 12.7432 3.24524V4.03577Z"
					fill="currentColor"
				/>
				<path
					d="M8.69236 1.69499V2.07047H7.6518V1.69499C7.6518 1.60084 7.57367 1.52451 7.47856 1.52451H6.30609C6.21041 1.52451 6.13285 1.60084 6.13285 1.69499V2.07047H5.09229V1.69499C5.09229 1.03593 5.63691 0.5 6.30665 0.5H7.47913C8.14887 0.5 8.69349 1.03593 8.69349 1.69499H8.69236Z"
					fill="currentColor"
				/>
			</g>
			<defs>
				<clipPath id="clip0_1_3944">
					<rect
						width="13.7838"
						height="17"
						fill="white"
						transform="translate(0 0.5)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default Trash;
