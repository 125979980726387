import J from "joi";

export type IRequestData = null;

export interface IResponseData {
	internet_connected: boolean;
}

export type IResponseError = null;

const responseDataShape = J.object({
	internet_connected: J.boolean(),
})
	.required()
	.unknown();

const uri =
	import.meta.env.VITE_MASTER_SERVER_HOST + "get_internet_connection_state";

// eslint-disable-next-line
export function isValidResponse(resp: any): resp is IResponseData {
	const validationError = responseDataShape.validate(resp).error;
	return validationError === undefined;
}

export function isValidResponseError(respErr: any): respErr is IResponseError {
	return respErr === null;
}

// eslint-disable-next-line
export function request(_: IRequestData): Promise<Response> {
	return fetch(uri, {
		method: "GET",
	});
}
