import { SVGProps } from "react";

const OSInfo = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			width="148"
			height="149"
			viewBox="0 0 148 149"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g filter="url(#filter0_d_1_4654)">
				<circle cx="74" cy="70.5" r="70" fill="currentColor" />
				<g clipPath="url(#clip0_1_4654)">
					<path
						d="M77.3849 41.1019C75.9778 39.6996 74.2296 39 72.1433 39C70.057 39 68.3057 39.6996 66.9017 41.1019C65.4915 42.5073 64.791 44.2829 64.791 46.4349C64.791 48.587 65.5159 50.2685 66.9687 51.7649C68.4215 53.2613 70.1453 54.0079 72.1433 54.0079C74.1413 54.0079 75.9809 53.2832 77.388 51.8339C78.792 50.3877 79.4986 48.5838 79.4986 46.4349C79.4986 44.286 78.792 42.5073 77.388 41.1019H77.3849Z"
						fill="white"
					/>
					<path
						d="M86.9878 99.5489V102.513C86.9878 102.783 86.7746 103 86.5157 103H60.4751C60.2132 103 60 102.783 60 102.513V98.7709C60 98.5575 60.1371 98.3693 60.3381 98.3128C60.731 98.1999 61.2213 98.2438 61.6294 98.2125C62.0924 98.1779 62.5523 98.1403 63.0122 98.0995C64.0173 98.0085 64.9157 97.8611 65.7107 97.6509C66.5086 97.4407 67.1726 97.1584 67.7178 96.8008C68.2629 96.44 68.534 95.9945 68.534 95.4549V65.1286C68.3756 63.5193 67.666 63.2871 67.666 63.2871L61.4102 60.9468C61.0538 60.5547 61.2183 60.2127 61.2396 60.1688C61.2426 60.1688 61.2426 60.1688 61.2426 60.1688C61.4954 59.5508 63.8985 59.2998 64.2853 59.1869C68.9239 58.1078 73.5624 57.0286 78.201 55.9463H78.9533V95.9977C79.1208 96.5968 79.4772 97.0266 80.0223 97.2964C80.9635 97.7607 82.069 97.9458 83.0954 98.0932C84.2345 98.2595 85.4041 98.2407 86.5371 98.2689C86.6497 98.2689 86.7929 98.294 86.8294 98.4007C86.8112 98.3756 86.7868 98.3505 86.7655 98.3285C86.7898 98.3568 86.8112 98.385 86.8355 98.4132C86.8538 98.4352 86.869 98.4571 86.8812 98.4822C87.0518 98.7928 86.9878 99.2038 86.9878 99.552V99.5489Z"
						fill="white"
					/>
					<path
						d="M86.8357 98.4109C86.8113 98.3826 86.79 98.3544 86.7656 98.3262C86.7869 98.3481 86.8113 98.3732 86.8296 98.3983C86.8296 98.4046 86.8357 98.4077 86.8357 98.4109Z"
						fill="white"
					/>
				</g>
			</g>
			<defs>
				<filter
					id="filter0_d_1_4654"
					x="0"
					y="0.5"
					width="148"
					height="148"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="4" />
					<feGaussianBlur stdDeviation="2" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
					/>
					<feBlend
						mode="normal"
						in2="BackgroundImageFix"
						result="effect1_dropShadow_1_4654"
					/>
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="effect1_dropShadow_1_4654"
						result="shape"
					/>
				</filter>
				<clipPath id="clip0_1_4654">
					<rect
						width="27"
						height="64"
						fill="white"
						transform="translate(60 39)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default OSInfo;
