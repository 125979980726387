import { NavLink } from "react-router-dom";
import { ButtonText } from "../typography";
import BaseButtonProps from "./baseProps";
import createRipple from "./rippleEffect";
import { useBrandingStore } from "../../store";
import { cn } from "../../util/helpers";
import CaretRight from "../icons/optiswiss/CaretRight";

const MenuItem = ({
	children,
	className,
	fullWidth,
	active,
	badge,
	icon,
	onClick,
	to,
}: BaseButtonProps) => {
	const { branding } = useBrandingStore();

	return (
		<>
			{to ? (
				<NavLink
					to={to}
					className={({ isActive }) =>
						cn(
							fullWidth ? "w-full" : "w-fit",
							isActive
								? "active bg-quaternary-100 text-primary-dark-100"
								: "border border-primary-100 text-primary-100 md:border-0",
							className,
							"flex items-center gap-x-3",
							"h-12 p-3",
							"group relative overflow-hidden outline-none transition duration-200 ease-in-out",
							branding !== "hoya" &&
								"justify-between rounded-[10px] bg-quaternary-100 px-4 py-3",
							branding !== "hoya" && isActive && "bg-primary-100",
						)
					}
					onClick={(e) => createRipple(e)}
				>
					{({ isActive }) => (
						<>
							<div className="flex items-center gap-1">
								{branding !== "hoya" &&
									icon &&
									icon.call(null, {
										width: 16,
										height: 16,
										color: isActive
											? "#fff"
											: "--primary--100",
									})}
								<ButtonText
									type={
										branding !== "hoya" ? "normal" : "bold"
									}
									className={cn(
										branding !== "hoya" &&
											"text-pure-black",
										branding !== "hoya" &&
											isActive &&
											"text-white",
									)}
								>
									{children}
								</ButtonText>
							</div>
							{branding === "hoya" && badge && (
								<span
									className={cn(
										"grid h-6 min-w-[1.5rem] place-items-center rounded-full px-1 text-sm font-bold",
										isActive
											? "bg-primary-dark-100 text-quaternary-100"
											: "bg-quaternary-100 text-primary-dark-100",
									)}
								>
									{badge}
								</span>
							)}
							{branding !== "hoya" && (
								<CaretRight
									className={cn(isActive && "text-white")}
								/>
							)}
						</>
					)}
				</NavLink>
			) : (
				<button
					className={cn(
						fullWidth ? "w-full" : "w-fit",
						active
							? "bg-quaternary-100 text-primary-dark-100"
							: "border border-primary-100 text-primary-100 md:border-0",
						className,
						"flex items-center gap-x-3",
						"h-12 p-3",
						"relative overflow-hidden outline-none transition duration-200 ease-in-out",
					)}
					onClick={(e) => {
						createRipple(e);
						onClick && onClick();
					}}
					type="button"
				>
					<ButtonText type="bold">{children}</ButtonText>
					{badge && (
						<span
							className={cn(
								"grid h-6 min-w-[1.5rem] place-items-center rounded-full px-1 text-sm font-bold",
								active
									? "bg-primary-dark-100 text-quaternary-100"
									: "bg-quaternary-100 text-primary-dark-100",
							)}
						>
							{badge}
						</span>
					)}
				</button>
			)}
		</>
	);
};

export default MenuItem;
