import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import HeadingText from "../../components/typography/TextHeading";
import TextField from "../../components/input/TextField";
import SolidButton from "../../components/buttons/SolidButton";
import { useTranslation } from "react-i18next";
import { Close } from "../../components/icons";
import { useBrandingStore } from "../../store";
import { cn } from "../../util/helpers";

const Login = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { branding } = useBrandingStore();
	const [isInputError, setInputError] = useState<boolean>(false);
	const [formData, setFormData] = useState({
		access_id: "",
		password: "",
	});
	const onSubmit = () => {
		const pw = "StereoAdjustment";
		if (formData.password === pw) {
			setInputError(false);
			navigate("/maintainance/calibration", {
				state: { loggedIn: true },
			});
		} else {
			setInputError(true);
		}
	};

	return (
		<div
			style={{
				backgroundImage: `url(/images/${branding}-login.jpg)`,
			}}
			className={cn(
				"flex h-dvh w-screen flex-row items-center justify-center bg-cover bg-center",
			)}
		>
			<div
				className={cn(
					"mx-4 w-full max-w-lg bg-pure-white px-6 pb-6 md:mx-0",
					branding !== "hoya" && "rounded-[50px]",
				)}
			>
				<div
					className={cn(
						"flex items-center justify-between gap-4 px-6",
						branding !== "hoya" ? "py-8" : "py-5",
					)}
				>
					<HeadingText h={4}>
						{t("maintainance.loginTitle")}
					</HeadingText>
					<Link
						to="/"
						className={cn(
							branding !== "hoya" &&
								"h-fit !rounded-[10px] border border-quaternary-80 p-[5px]",
						)}
					>
						<Close
							className={cn(branding !== "hoya" && "size-[17px]")}
						/>
					</Link>
				</div>
				<form
					onSubmit={(e) => {
						e.preventDefault();
						onSubmit();
					}}
					className={cn(
						"flex flex-col border-t border-secondary-40 px-6",
						branding !== "hoya" ? "gap-16 py-8" : "gap-6 py-6",
					)}
				>
					<TextField
						label={t("maintainance.password")}
						htmlFor="admin-password"
						type={isInputError ? "alert" : "default"}
						showPasswordButton={true}
						fieldOptions={{ type: "password", autoFocus: true }}
						value={formData.password}
						setValue={(v) =>
							setFormData({
								...formData,
								password: v,
							})
						}
						fullWidth
					/>
					<div className={cn("flex flex-row justify-end")}>
						<SolidButton
							color="primary"
							submit={true}
							fullWidth={branding !== "hoya"}
						>
							{t("maintainance.login")}
						</SolidButton>
					</div>
				</form>
			</div>
		</div>
	);
};

export default Login;
