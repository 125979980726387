import J from "joi";

export interface IRequestData {
	access_id: string;
	head_rotation_compensation: boolean;
	measurement: string;
	sessionId: string;
}

export interface IResponseData {
	xmlData: string;
}

export type IResponseError = null;

const responseDataShape = J.object().required();

const uri = import.meta.env.VITE_MASTER_SERVER_HOST + "set_session";

// eslint-disable-next-line
export function isValidResponse(resp: any): resp is IResponseData {
	const validationError = responseDataShape.validate(resp).error;
	return validationError === undefined;
}

// eslint-disable-next-line
export function isValidResponseError(respErr: any): respErr is IResponseError {
	return respErr === null;
}

export function request(req: IRequestData): Promise<Response> {
	return fetch(uri, {
		method: "POST",
		body: JSON.stringify(req),
	});
}
