import { SVGProps } from "react";
const SvgNoPhoto = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		height={props.height || 35}
		width={props.width || 35}
		fill="none"
		viewBox="0 0 35 35"
		{...props}
	>
		<path
			fill="#D0CCBF"
			d="m27.26 5.866-6.374 6.375a6.028 6.028 0 0 1 1.436 2.321c.217.626.327 1.283.325 1.945a6.11 6.11 0 0 1-6.11 6.11 5.866 5.866 0 0 1-1.944-.326 6.03 6.03 0 0 1-2.322-1.436L5.948 27.18h24.296V5.866H27.26Zm-16.577 12.22a5.896 5.896 0 0 1-.204-1.538 6.11 6.11 0 0 1 6.11-6.11c.519 0 1.036.069 1.537.204a5.2 5.2 0 0 1 1.09.407l5.162-5.162h-.804l-.346-.347-2.689-2.698h-7.932L9.552 5.897h-6.67v21.282h.194l8.014-8.014a5.206 5.206 0 0 1-.407-1.12v.04Z"
		/>
		<path
			fill="#958B87"
			fillRule="evenodd"
			d="M34.5 2.384 22.322 14.562 2.384 34.5.5 32.626l26.76-26.76L32.626.5 34.5 2.384Z"
			clipRule="evenodd"
		/>
	</svg>
);
export default SvgNoPhoto;
