import { Navigate, Outlet } from "react-router-dom";

type RequireAuthPropsChildren = {
	children: JSX.Element;
	outlet: false;
	to: string;
};

type RequireAuthPropsOutlet = {
	outlet: true;
	to: string;
};

const RequireAuth = (
	props: RequireAuthPropsChildren | RequireAuthPropsOutlet,
) => {
	const user = null;

	if (!user) {
		return <Navigate to={props.to} />;
	} else {
		if (props.outlet) {
			return <Outlet />;
		} else {
			return props.children;
		}
	}
};

export default RequireAuth;
