import { Option } from "../util/result";

export type Position = {
	x: number;
	y: number;
};

export type YPosition = {
	y: number;
};

export type Box = {
	x: number;
	y: number;
	width: number;
	height: number;
};

export type ImageObjects = {
	topImage: HTMLImageElement;
	bottomImage: HTMLImageElement;
};

export type IrisPosition = {
	x: number;
	y1: number;
	y2: number;
};

export type IrisPositions = {
	left: IrisPosition;
	right: IrisPosition;
};

export type IrisRadus = number;
export type IrisRadii = {
	left: IrisRadus;
	right: IrisRadus;
};

export type FramePosition = {
	x: number;
	y1: number;
	y2: number;
};

export type FramePositionSingle = Position;

export type FramesPositionsSide = {
	bottomNasal: FramePosition;
	bottom: FramePosition;
	bottomTemporal: FramePosition;
	nasal: FramePosition;
	temporal: FramePosition;
	topNasal: FramePosition;
	top: FramePosition;
	topTemporal: FramePosition;
};

export type FramesPositionsSideSingle = {
	bottomNasal: FramePositionSingle;
	bottom: FramePositionSingle;
	bottomTemporal: FramePositionSingle;
	nasal: FramePositionSingle;
	temporal: FramePositionSingle;
	topNasal: FramePositionSingle;
	top: FramePositionSingle;
	topTemporal: FramePositionSingle;
};

export type FramesPositions = {
	left: FramesPositionsSide;
	right: FramesPositionsSide;
};

export type FramesPositionsSingle = {
	left: FramesPositionsSideSingle;
	right: FramesPositionsSideSingle;
};

export type HandleObjects = {
	handle1: HTMLImageElement;
	handle2Left: HTMLImageElement;
	handle2Right: HTMLImageElement;
};

export type ImageObjectOptions = {
	topImage: Option<HTMLImageElement>;
	bottomImage: Option<HTMLImageElement>;
};

export type HandleObjectOptions = {
	handle1: Option<HTMLImageElement>;
	handle2Right: Option<HTMLImageElement>;
	handle2Left: Option<HTMLImageElement>;
};

export type EyesHandle = "l" | "r";

export type CornerHandle =
	| "left-bottom-nasal"
	| "left-bottom-temporal"
	| "left-top-nasal"
	| "left-top-temporal"
	| "right-bottom-nasal"
	| "right-bottom-temporal"
	| "right-top-nasal"
	| "right-top-temporal";

export type SideHandle =
	| "left-bottom"
	| "left-nasal"
	| "left-temporal"
	| "left-top"
	| "right-bottom"
	| "right-temporal"
	| "right-nasal"
	| "right-top";

export type FramesHandle = CornerHandle | SideHandle;

export type EyesSelectedHandle = {
	handle: EyesHandle;
	previousPosition: Position;
	doubleTap: boolean;
};

export type FramesSelectedSideHandle = {
	handle: SideHandle;
	previousPosition: Position;
	doubleTap: boolean;
};

export type FramesSelectedCornerHandle = {
	isTapped: boolean;
	handle: CornerHandle;
	previousPosition: Position;
	doubleTap: boolean;
};

export type LineProp = "hline" | "vline" | "nothing";

export function posPlus(a: Position, b: Position) {
	return {
		x: a.x + b.x,
		y: a.y + b.y,
	};
}

export function newBox(p: Position, width: number, height: number) {
	return {
		x: width > 0 ? p.x : p.x + width,
		y: height > 0 ? p.y : p.y + height,
		width: width > 0 ? width : -width,
		height: height > 0 ? height : -height,
	};
}

export function getVectorAngle({ x, y }: Position) {
	const l = Math.sqrt(x * x + y * y);
	if (l === 0) {
		return 0;
	}
	const nx = x / l;
	const ny = y / l;
	return Math.sign(ny) * Math.acos(nx);
}

export function getAngleVector(angle: number) {
	return {
		x: Math.cos(angle),
		y: Math.sin(angle),
	};
}

export function isInsideBox(p: Position, b: Box) {
	return (
		p.x >= b.x &&
		p.x <= b.x + b.width &&
		p.y >= b.y &&
		p.y <= b.y + b.height
	);
}

export function drawPlus(
	ctx: CanvasRenderingContext2D,
	x: number,
	y: number,
	handleColor: string,
	lineWidth: number,
) {
	ctx.beginPath();
	ctx.moveTo(x, y + 7);
	ctx.lineTo(x, y - 7);
	ctx.moveTo(x + 7, y);
	ctx.lineTo(x - 7, y);
	ctx.lineWidth = lineWidth;
	ctx.strokeStyle = handleColor;
	ctx.stroke();
}

export function drawCircle(
	ctx: CanvasRenderingContext2D,
	x: number,
	y: number,
	r: number,
	handleColor: string,
	lineWidth: number,
) {
	ctx.beginPath();
	ctx.arc(x, y, r, 0, 2 * Math.PI);
	ctx.lineWidth = lineWidth;
	ctx.strokeStyle = handleColor;
	ctx.stroke();
}

export function drawEyeGoal(
	ctx: CanvasRenderingContext2D,
	x: number,
	y: number,
	r: number,
	handleColor: string,
	lineWidth: number,
) {
	drawPlus(ctx, x, y, handleColor, lineWidth);
	drawCircle(ctx, x, y, r, handleColor, lineWidth);
	drawCircle(ctx, x, y, r / 2, handleColor, lineWidth);
}

export function drawEyeGoalWithHandle(
	ctx: CanvasRenderingContext2D,
	handle: HTMLImageElement,
	x: number,
	y: number,
	r: number,
	handleColor: string,
	lineWidth: number,
	rightHanded: boolean,
) {
	drawEyeGoal(ctx, x, y, r, handleColor, lineWidth);
	const angle = rightHanded ? Math.PI / 4 : Math.PI * (3 / 4);
	ctx.beginPath();
	ctx.moveTo(x + r * Math.cos(angle), y + r * Math.sin(angle));
	const handlePosX = x + (r + Math.SQRT2 * 24) * Math.cos(angle);
	const handlePosY = y + (r + Math.SQRT2 * 24) * Math.sin(angle);
	ctx.lineTo(
		x + (r + Math.SQRT2 * 24 + handle.height) * Math.cos(angle),
		y + (r + Math.SQRT2 * 24 + handle.height) * Math.sin(angle),
	);
	ctx.lineWidth = lineWidth;
	ctx.strokeStyle = handleColor;
	ctx.stroke();
	ctx.drawImage(
		handle,
		handlePosX - (rightHanded ? 0 : 2 * r),
		handlePosY,
		2 * r,
		handle.height * ((2 * r) / handle.width),
	);
}

export function drawHandle(
	ctx: CanvasRenderingContext2D,
	handle: HTMLImageElement,
	x: number,
	y: number,
	r: number,
	handleColor: string,
	lineWidth: number,
	rightHanded: boolean,
) {
	const angle = rightHanded ? Math.PI / 4 : Math.PI * (3 / 4);
	ctx.beginPath();
	ctx.moveTo(x, y);
	const handlePosX = x + Math.SQRT2 * 24 * Math.cos(angle);
	const handlePosY = y + Math.SQRT2 * 24 * Math.sin(angle);
	ctx.lineTo(
		x + (Math.SQRT2 * 24 + handle.height) * Math.cos(angle),
		y + (Math.SQRT2 * 24 + handle.height) * Math.sin(angle),
	);
	ctx.lineWidth = lineWidth;
	ctx.strokeStyle = handleColor;
	ctx.stroke();
	ctx.drawImage(
		handle,
		handlePosX - (rightHanded ? 0 : 2 * r),
		handlePosY,
		2 * r,
		handle.height * ((2 * r) / handle.width),
	);
}

export function drawLoupe(
	ctx: CanvasRenderingContext2D,
	image: HTMLImageElement,
	lx: number,
	ly: number,
	lr: number,
	ix: number,
	iy: number,
	ir: number,
	irr: number,
	boxAngle: number,
	handleColor: string,
	lineWidth: number,
	insideGraphic: "circle" | "hline" | "vline" | "nothing",
) {
	const v = { x: Math.cos(boxAngle), y: Math.sin(boxAngle) };
	const n = { x: -v.y, y: v.x };
	ctx.save();
	ctx.beginPath();
	ctx.arc(lx, ly, lr, 0, Math.PI * 2);
	ctx.clip();
	ctx.drawImage(
		image,
		ix - ir,
		iy - ir,
		ir * 2,
		ir * 2,
		lx - lr,
		ly - lr,
		2 * lr,
		2 * lr,
	);
	ctx.restore();
	ctx.beginPath();
	ctx.strokeStyle = "black";
	ctx.arc(lx, ly, lr, 0, Math.PI * 2);
	ctx.stroke();
	const smallRadius = 4 * (lr / ir);
	switch (insideGraphic) {
		case "circle":
			drawEyeGoal(ctx, lx, ly, irr * (lr / ir), handleColor, lineWidth);
			break;
		case "hline":
			ctx.beginPath();
			ctx.strokeStyle = handleColor;
			ctx.lineWidth = lineWidth;
			ctx.moveTo(
				lx - v.x * (lr - lineWidth),
				ly - v.y * (lr - lineWidth),
			);
			ctx.lineTo(lx - v.x * smallRadius, ly - v.y * smallRadius);
			ctx.stroke();
			ctx.moveTo(lx + v.x * smallRadius, ly + v.y * smallRadius);
			ctx.lineTo(
				lx + v.x * (lr - lineWidth),
				ly + v.y * (lr - lineWidth),
			);
			ctx.stroke();
			ctx.beginPath();
			ctx.arc(lx, ly, smallRadius, 0, Math.PI * 2);
			ctx.stroke();
			break;
		case "vline":
			ctx.beginPath();
			ctx.strokeStyle = handleColor;
			ctx.lineWidth = lineWidth;
			ctx.moveTo(
				lx - n.x * (lr - lineWidth),
				ly - n.y * (lr - lineWidth),
			);
			ctx.lineTo(lx - n.x * smallRadius, ly - n.y * smallRadius);
			ctx.moveTo(lx + n.x * smallRadius, ly + n.y * smallRadius);
			ctx.lineTo(
				lx + n.x * (lr - lineWidth),
				ly + n.y * (lr - lineWidth),
			);
			ctx.stroke();
			ctx.beginPath();
			ctx.arc(lx, ly, smallRadius, Math.PI * 0.5, Math.PI * 2.5);
			ctx.stroke();
			break;
		case "nothing":
			ctx.beginPath();
			ctx.strokeStyle = handleColor;
			ctx.lineWidth = lineWidth;
			ctx.arc(lx, ly, smallRadius, Math.PI * 0.5, Math.PI * 2.5);
			ctx.stroke();
			break;
	}
}

export function drawHandleWithLoupe(
	ctx: CanvasRenderingContext2D,
	handle: HTMLImageElement,
	image: HTMLImageElement,
	x: number,
	y: number,
	r: number,
	ix: number,
	iy: number,
	ir: number,
	boxAngle: number,
	handleColor: string,
	lineWidth: number,
	rightHanded: boolean,
) {
	const angle = rightHanded ? Math.PI / 4 : Math.PI * (3 / 4);
	const handlePosX = x + (r + Math.SQRT2 * 24) * Math.cos(angle);
	const handlePosY = y + (r + Math.SQRT2 * 24) * Math.sin(angle);

	drawLoupe(
		ctx,
		image,
		x,
		y,
		r * (Math.SQRT2 + 0.8) + Math.SQRT2 * 24,
		ix,
		iy,
		ir * 1.5,
		ir,
		boxAngle,
		handleColor,
		lineWidth,
		"circle",
	);

	ctx.drawImage(
		handle,
		handlePosX - (rightHanded ? 0 : 2 * r),
		handlePosY,
		2 * r,
		handle.height * ((2 * r) / handle.width),
	);
}

export function drawHandleWithLoupeForFramePosition(
	ctx: CanvasRenderingContext2D,
	handle: HTMLImageElement,
	image: HTMLImageElement,
	lx: number,
	ly: number,
	hr: number,
	ix: number,
	iy: number,
	ir: number,
	boxAngle: number,
	handleColor: string,
	lineWidth: number,
	rightHanded: boolean,
	insideLine: LineProp,
) {
	const angle = rightHanded ? Math.PI / 4 : Math.PI * (3 / 4);
	const handlePosX = lx + Math.SQRT2 * 24 * Math.cos(angle);
	const handlePosY = ly + Math.SQRT2 * 24 * Math.sin(angle);

	drawLoupe(
		ctx,
		image,
		lx,
		ly,
		hr * (Math.SQRT2 + 0.8) + Math.SQRT2 * 24,
		ix,
		iy,
		ir * 1.5,
		ir,
		boxAngle,
		handleColor,
		lineWidth,
		insideLine,
	);

	ctx.drawImage(
		handle,
		handlePosX - (rightHanded ? 0 : 2 * hr),
		handlePosY,
		2 * hr,
		handle.height * ((2 * hr) / handle.width),
	);
}

export function drawHandleWithoutEyeGoalWithOrWithoutLoupe(
	ctx: CanvasRenderingContext2D,
	handleLeft: HTMLImageElement,
	handleRight: HTMLImageElement,
	image: HTMLImageElement,
	position: Position,
	imagePosition: Position,
	handleRadius: number,
	boxAngle: number,
	handleColor: string,
	lineWidth: number,
	leftHand: boolean,
	drawLoupe: boolean,
	insideLine: LineProp,
) {
	const handle = leftHand ? handleLeft : handleRight;
	if (drawLoupe) {
		drawHandleWithLoupeForFramePosition(
			ctx,
			handle,
			image,
			position.x,
			position.y,
			handleRadius,
			imagePosition.x,
			imagePosition.y,
			Math.SQRT2 * 24,
			boxAngle,
			handleColor,
			lineWidth,
			!leftHand,
			insideLine,
		);
	} else {
		drawHandle(
			ctx,
			handle,
			position.x,
			position.y,
			handleRadius,
			handleColor,
			lineWidth,
			!leftHand,
		);
	}
}

export function drawHandleWithOrWithoutLoupe(
	ctx: CanvasRenderingContext2D,
	handleLeft: HTMLImageElement,
	handleRight: HTMLImageElement,
	image: HTMLImageElement,
	position: Position,
	radius: number,
	irisPosition: Position,
	irisRadius: number,
	boxAngle: number,
	handleColor: string,
	lineWidth: number,
	leftHand: boolean,
	drawLoupe: boolean,
) {
	const handle = leftHand ? handleLeft : handleRight;
	if (drawLoupe) {
		drawHandleWithLoupe(
			ctx,
			handle,
			image,
			position.x,
			position.y,
			radius,
			irisPosition.x,
			irisPosition.y,
			irisRadius,
			boxAngle,
			handleColor,
			lineWidth,
			!leftHand,
		);
	} else {
		drawEyeGoalWithHandle(
			ctx,
			handle,
			position.x,
			position.y,
			radius,
			handleColor,
			1,
			!leftHand,
		);
	}
}

export function dot(a: Position, b: Position) {
	return a.x * b.x + a.y * b.y;
}

export function ort(v: Position) {
	return { x: -v.y, y: v.x };
}

export function calculateIntersectionPoint(
	a: Position,
	b: Position,
	v: Position,
) {
	const n = { x: -v.y, y: v.x };
	const c1 = dot(a, n);
	const c2 = dot(b, v);
	const x = (c2 - (c1 * v.y) / n.y) / (v.x - (n.x * v.y) / n.y);
	const y = (c1 - n.x * x) / n.y;
	return { x, y };
}

export function drawRectangleFromSidePositions(
	ctx: CanvasRenderingContext2D,
	bottom: Position,
	left: Position,
	right: Position,
	top: Position,
	boxAngle: number,
	lineWidth: number,
	handleColor: string,
) {
	const v = { x: Math.cos(boxAngle), y: Math.sin(boxAngle) };
	const tl = calculateIntersectionPoint(top, left, v);
	const tr = calculateIntersectionPoint(top, right, v);
	const bl = calculateIntersectionPoint(bottom, left, v);
	const br = calculateIntersectionPoint(bottom, right, v);
	ctx.beginPath();
	ctx.moveTo(tl.x, tl.y);
	ctx.lineTo(tr.x, tr.y);
	ctx.lineTo(br.x, br.y);
	ctx.lineTo(bl.x, bl.y);
	ctx.closePath();
	ctx.lineWidth = lineWidth;
	ctx.strokeStyle = handleColor;
	ctx.stroke();
}

export function drawSmallCircle(
	ctx: CanvasRenderingContext2D,
	x: number,
	y: number,
	lineWidth: number,
	handleColor: string,
) {
	drawCircle(ctx, x, y, 4, handleColor, lineWidth);
}

export function calculateHandleBox(
	x: number,
	y: number,
	r: number,
	handleWidth: number,
	handleHeight: number,
	rightHanded: boolean,
) {
	const angle = rightHanded ? Math.PI / 4 : Math.PI * (3 / 4);
	const handlePosX = x + (r + Math.SQRT2 * 24) * Math.cos(angle);
	const handlePosY = y + (r + Math.SQRT2 * 24) * Math.sin(angle);
	return {
		x: handlePosX - (rightHanded ? 0 : handleWidth),
		y: handlePosY,
		width: handleWidth,
		height: handleHeight,
	};
}

export function calculatePositionFromHandle(
	x: number,
	y: number,
	r: number,
	rightHanded: boolean,
	addedDistance: number,
) {
	const angle = Math.PI + (rightHanded ? Math.PI / 4 : Math.PI * (3 / 4));
	const irisPosX =
		x + (r - addedDistance + Math.SQRT2 * 24) * Math.cos(angle);
	const irisPosY =
		y + (r - addedDistance + Math.SQRT2 * 24) * Math.sin(angle);
	return {
		x: irisPosX,
		y: irisPosY,
	};
}

export type ImageUtils = {
	startingEyeY: number;
	startingEyeYTop: number;
	imageAspect: number;
	scaleFromImageToCanvas: (l: number) => number;
	fromImageToCanvas: (x: number, y: number) => Position;
	fromImageToCanvasPos: (p: Position) => Position;
	fromCanvasToImage: (p: Position) => Position;
	boxFromImageToCanvas: (b: Box) => Box;
	scaleFromCanvasToImage: (l: number) => number;
	viewBoxImage: Box;
};
export function createImageUtils(
	width: number,
	height: number,
	startingLeftEyePos: Position,
	startingRightEyePos: Position,
	leftIrisRadius: number,
	rightIrisRadius: number,
	canvasImageTop: number,
): ImageUtils {
	const imageAspect = height / width;
	const startingEyeY = (startingLeftEyePos.y + startingRightEyePos.y) / 2;
	const startingEyeX = (startingLeftEyePos.x + startingRightEyePos.x) / 2;
	const startingEyeWidth =
		(Math.abs(startingLeftEyePos.x - startingRightEyePos.x) +
			leftIrisRadius +
			rightIrisRadius) *
		2.3;
	const startingEyeYTop = startingEyeY - (startingEyeWidth * imageAspect) / 2;
	const startingEyeXLeft = startingEyeX - startingEyeWidth / 2;
	const scaleFromImageToCanvas = (l: number) =>
		l * (width / startingEyeWidth);
	const fromImageToCanvas = (x: number, y: number) => {
		const scale = width / startingEyeWidth;
		return {
			x: (x - startingEyeXLeft) * scale,
			y: (y - startingEyeYTop) * scale + canvasImageTop,
		};
	};
	const fromImageToCanvasPos = (p: Position) => fromImageToCanvas(p.x, p.y);

	const scaleFromCanvasToImage = (l: number) =>
		l / (width / startingEyeWidth);
	const fromCanvasToImage = ({ x, y }: Position) => {
		const scale = width / startingEyeWidth;
		const translateY = startingEyeYTop;
		return {
			x: x / scale + startingEyeXLeft,
			y: (y - canvasImageTop) / scale + translateY,
		};
	};

	const boxFromImageToCanvas = ({ x, y, width, height }: Box) => {
		const newXY = fromImageToCanvas(x, y);
		const newW = scaleFromImageToCanvas(width);
		const newH = scaleFromImageToCanvas(height);

		return {
			x: newXY.x,
			y: newXY.y,
			width: newW,
			height: newH,
		};
	};

	const viewBoxImage = {
		x: startingEyeXLeft,
		y: startingEyeYTop,
		width: startingEyeWidth,
		height: height * (startingEyeWidth / width),
	};

	return {
		startingEyeY,
		startingEyeYTop,
		imageAspect,
		scaleFromImageToCanvas,
		fromImageToCanvas,
		fromImageToCanvasPos,
		boxFromImageToCanvas,
		fromCanvasToImage,
		scaleFromCanvasToImage,
		viewBoxImage,
	};
}
