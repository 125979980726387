import { SVGProps } from "react";
const SvgOSNoPhoto = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		height={props.height || 148}
		width={props.width || 148}
		fill="none"
		viewBox="0 0 148 148"
		{...props}
	>
		<g filter="url(#a)">
			<circle
				cx="70"
				cy="70"
				r="70"
				fill="var(--system-highlight--100)"
				transform="matrix(-1 0 0 1 144 0)"
			/>
			<g clipPath="url(#b)">
				<path
					fill="#303030"
					d="M81.57 37.188H61.213a5.682 5.682 0 0 0-4.033 1.706 5.823 5.823 0 0 0-1.662 4.091v7.264a5.823 5.823 0 0 0 1.662 4.091 5.682 5.682 0 0 0 4.032 1.707h20.36a5.655 5.655 0 0 0 2.186-.445 5.712 5.712 0 0 0 1.853-1.258c.53-.538.95-1.177 1.235-1.88a5.85 5.85 0 0 0 .431-2.215v-7.264a5.85 5.85 0 0 0-.43-2.215 5.793 5.793 0 0 0-1.236-1.88 5.712 5.712 0 0 0-1.853-1.258 5.655 5.655 0 0 0-2.186-.444Z"
				/>
				<path
					fill="#fff"
					d="M109.624 52.01a.962.962 0 0 0 0-.186 4.005 4.005 0 0 0-.141-.798c0-.11-.054-.23-.086-.34a5.783 5.783 0 0 0-.27-.71 2.341 2.341 0 0 0-.163-.329 5.75 5.75 0 0 0-.27-.448 3.853 3.853 0 0 0-.237-.34l-.238-.284a3.452 3.452 0 0 0-.335-.35l-.303-.273a.84.84 0 0 0-.162-.132s-.108-.076-.162-.12a3.66 3.66 0 0 0-.616-.394 3.195 3.195 0 0 0-.389-.197l-.14-.076-.498-.175a2.113 2.113 0 0 0-.378-.099 3.746 3.746 0 0 0-.713-.131h-.097a3.816 3.816 0 0 0-.497 0H38.853a5.658 5.658 0 0 0-2.187.445 5.713 5.713 0 0 0-1.852 1.258 5.79 5.79 0 0 0-1.235 1.88 5.848 5.848 0 0 0-.431 2.215v45.409a2.204 2.204 0 0 0 0 .382.275.275 0 0 0 0 .132c.03.37.095.736.194 1.093.087.346.214.68.378.996.24.47.542.904.897 1.291a5.36 5.36 0 0 0 1.502 1.192l.368.186c.212.102.433.186.659.252h.173c.304.086.615.145.93.175H103.928a5.677 5.677 0 0 0 4.039-1.703 5.837 5.837 0 0 0 1.666-4.095V52.415c0-.142 0-.274-.01-.405Zm-5.177 13.707h-12.21a22.118 22.118 0 0 0-2.227-3.993l7.565-5.47 4.765-3.434 2.161-1.543-.054 14.44Zm-18.76 0a17.417 17.417 0 0 1 2.735 9.408c.004 1.545-.2 3.083-.605 4.572a17.244 17.244 0 0 1-4.928 8.188 16.92 16.92 0 0 1-8.49 4.248c-3.17.573-6.437.223-9.418-1.01a17.034 17.034 0 0 1-7.422-5.956l7.564-5.47 2.075-1.498 6.365-4.595 10.904-7.887.82-.59.4.59Z"
				/>
				<path
					fill="#fff"
					d="M104.447 51.288v14.429h-12.21a22.118 22.118 0 0 0-2.227-3.993l7.565-5.47 4.765-3.434 2.107-1.532Z"
				/>
				<path
					fill="#fff"
					d="M109.634 52.415v45.42a5.839 5.839 0 0 1-1.666 4.095 5.733 5.733 0 0 1-1.852 1.258 5.658 5.658 0 0 1-2.187.444H38.27a5.155 5.155 0 0 1-.93-.175h-.173a4.219 4.219 0 0 1-.659-.251l-.367-.186a5.357 5.357 0 0 1-1.524-1.291 5.832 5.832 0 0 1-.875-1.302 6.51 6.51 0 0 1-.378-.995 5.966 5.966 0 0 1-.195-1.094h65.573a5.656 5.656 0 0 0 2.186-.445 5.706 5.706 0 0 0 1.853-1.258 5.838 5.838 0 0 0 1.666-4.095V46.639h.098c.241.02.48.064.713.131.129.021.255.054.378.099l.497.175.119.054c.134.057.264.123.389.197.218.11.424.243.616.394 0 0 .119.077.162.12a.843.843 0 0 1 .162.132l.303.273c.12.109.231.226.335.35l.238.285c.085.108.164.221.237.339.098.142.184.295.27.448.063.105.117.215.163.328.105.231.196.469.27.712 0 .109.065.23.086.339.074.26.121.528.141.798a.963.963 0 0 1 0 .186c.01.142.01.274.01.416Z"
				/>
				<path
					fill="#303030"
					d="m84.477 65.717-10.903 7.887-6.365 4.595-2.075 1.498H33.148v-13.98h51.33ZM109.634 65.717v13.98H87.817c.405-1.49.609-3.027.605-4.572a17.416 17.416 0 0 0-2.734-9.408h23.946Z"
				/>
				<path
					fill="#303030"
					d="M109.624 65.717h-5.079v13.98h5.079v-13.98ZM92.236 65.717a22.118 22.118 0 0 0-2.226-3.993 21.511 21.511 0 0 0-2.366-2.877 23.124 23.124 0 0 0-3.901-3.227 22.494 22.494 0 0 0-12.346-3.697c-4.382 0-8.67 1.284-12.347 3.697A23.235 23.235 0 0 0 50.398 66.1a23.566 23.566 0 0 0-1.365 13.587 22.572 22.572 0 0 0 1.913 5.689 21.944 21.944 0 0 0 1.87 3.227 22.823 22.823 0 0 0 10.317 8.159 22.52 22.52 0 0 0 13.051 1.06 22.704 22.704 0 0 0 11.473-6.387 23.153 23.153 0 0 0 6.103-11.726c.925-4.708.393-9.59-1.524-13.98v-.011Zm-34.71 19.472 7.565-5.47 2.075-1.498 6.365-4.595 10.903-7.887.822-.59.389.59a17.417 17.417 0 0 1 2.734 9.408c.004 1.544-.2 3.083-.606 4.572a17.244 17.244 0 0 1-4.927 8.188 16.92 16.92 0 0 1-8.49 4.248c-3.17.573-6.437.222-9.418-1.01a17.035 17.035 0 0 1-7.422-5.956h.01Z"
				/>
				<path
					fill="#fff"
					d="M90.81 73.495a19.804 19.804 0 0 0-3.512-9.78 17.664 17.664 0 0 0-2.41-2.833 19.288 19.288 0 0 0-6.941-4.386 19.105 19.105 0 0 0-8.112-1.084c-1.162.096-2.312.3-3.436.613a19.531 19.531 0 0 0-10.897 7.729 19.95 19.95 0 0 0-3.476 13c.082.992.237 1.975.465 2.943.288 1.262.705 2.49 1.242 3.665a18.662 18.662 0 0 0 1.848 3.282 19.465 19.465 0 0 0 10.11 7.403 19.228 19.228 0 0 0 12.473-.338 19.5 19.5 0 0 0 9.704-7.94 19.881 19.881 0 0 0 2.942-12.274ZM71.359 92.452a16.822 16.822 0 0 1-7.788-1.931 17.053 17.053 0 0 1-6.044-5.332l7.564-5.47 2.075-1.498 6.365-4.595 10.903-7.887.822-.59.388.59a17.417 17.417 0 0 1 2.734 9.408c.005 1.544-.199 3.083-.605 4.572a17.252 17.252 0 0 1-6.08 9.178 16.897 16.897 0 0 1-10.334 3.555Z"
				/>
				<path
					fill="#fff"
					d="M88.422 75.125c.004 1.545-.2 3.083-.605 4.572a17.245 17.245 0 0 1-4.928 8.188 16.919 16.919 0 0 1-8.49 4.248c-3.17.573-6.437.223-9.418-1.01a17.034 17.034 0 0 1-7.422-5.956l7.564-5.47 2.075-1.498 6.365-4.595 10.904-7.887.82-.59.39.59a17.416 17.416 0 0 1 2.745 9.408Z"
				/>
				<path
					fill="#303030"
					d="m85.299 65.126-.822.591-10.903 7.887-6.365 4.595-2.075 1.498-7.564 5.47a17.447 17.447 0 0 1-1.805-3.282c-.307-.709-.56-1.44-.756-2.188a17.218 17.218 0 0 1-.605-4.572 17.372 17.372 0 0 1 2.742-9.403 17.067 17.067 0 0 1 7.367-6.364 16.82 16.82 0 0 1 9.605-1.261 16.925 16.925 0 0 1 8.728 4.25c.916.832 1.739 1.764 2.453 2.78Z"
				/>
				<path
					fill="#fff"
					d="M74.482 69.743a8.562 8.562 0 0 1-.908 3.861L67.209 78.2a8.167 8.167 0 0 1-5.025-.93 8.485 8.485 0 0 1-3.247-3.143 8.623 8.623 0 0 1-1.194-4.383 8.556 8.556 0 0 1 1.766-5.228 8.378 8.378 0 0 1 4.57-3.02 8.29 8.29 0 0 1 5.438.466 8.426 8.426 0 0 1 4.003 3.756c.447.847.75 1.764.897 2.713.055.435.076.874.065 1.313Z"
				/>
				<path
					fill="#303030"
					d="M102.243 93.273c0 .649-.19 1.283-.546 1.823-.357.54-.863.96-1.455 1.209a3.206 3.206 0 0 1-1.873.186 3.23 3.23 0 0 1-1.66-.898 3.295 3.295 0 0 1-.888-1.68 3.321 3.321 0 0 1 .185-1.896c.245-.6.66-1.112 1.194-1.473a3.214 3.214 0 0 1 4.093.408c.608.616.95 1.45.95 2.32ZM102.319 52.83l-4.766 3.436H89.99a2.685 2.685 0 0 1-1.91-.801 2.752 2.752 0 0 1-.792-1.934c0-.726.285-1.421.791-1.934a2.685 2.685 0 0 1 1.91-.801h9.802a2.659 2.659 0 0 1 1.601.588c.456.367.782.875.928 1.447Z"
				/>
				<path
					fill="#303030"
					d="m117.75 41.465-8.407 6.158-3.642 2.67-2.075 1.52-4.636 3.38-7.337 5.372-2.69 1.969-1.892 1.389-.8.58-10.6 7.766-6.193 4.53-2.02 1.476-7.392 5.404-1.891 1.39-2.69 1.957-13.152 9.638-4.528 3.304v.01l-9.855 7.21-2.075-2.998 10.504-7.68 17.268-12.634 2.723-1.99 1.935-1.423 2.993-2.188 3.263-2.396 11.898-8.696v-.011l3.653-2.67 4.57-3.347 1.935-1.41 2.734-2.014 3.48-2.538 7.305-5.349 5.543-4.047.087-.077 9.909-7.242 2.075 2.987Z"
				/>
			</g>
		</g>
		<defs>
			<clipPath id="b">
				<path fill="#fff" d="M25.875 37.188h91.875v70H25.875z" />
			</clipPath>
			<filter
				id="a"
				width="148"
				height="148"
				x="0"
				y="0"
				colorInterpolationFilters="sRGB"
				filterUnits="userSpaceOnUse"
			>
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					result="hardAlpha"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
				/>
				<feOffset dy="4" />
				<feGaussianBlur stdDeviation="2" />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
				<feBlend
					in2="BackgroundImageFix"
					result="effect1_dropShadow_3674_11455"
				/>
				<feBlend
					in="SourceGraphic"
					in2="effect1_dropShadow_3674_11455"
					result="shape"
				/>
			</filter>
		</defs>
	</svg>
);
export default SvgOSNoPhoto;
