import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import {
	createHashRouter,
	RouterProvider,
	RouteObject,
	Navigate,
} from "react-router-dom";
import RequireAuth from "./components/auth/RequireAuth";
import { BrandThemeContext, brandVar } from "./util/BrandThemeContext";
import {
	AdminFactoryReset,
	AdminLogin,
	AdminSettings,
	AdminSetup,
	OnboardingWelcome,
	OnboardingLanguage,
	OnboardingWifi,
	OnboardingRegistration,
	Settings,
	SettingsGeneral,
	SettingsProfile,
	SettingsUpdate,
	SettingsWifi,
	SettingsExportSettings,
	Measurement,
	Help,
	History,
	Details,
	MeasurementStart,
	MeasurementLoading,
	MeasurementEyes,
	MeasurementFrames,
	MeasurementResult,
	Home,
	Root,
	CalibrationLogin,
	Calibration,
} from "./pages";
import NotFound from "./NotFound";
import "./util/i18n";
import NavigateWithParams from "./util/NavigateWithParams";

const routes: RouteObject[] = [
	{
		path: "/",
		errorElement: <NotFound />,
		element: <Root />,
		children: [
			{
				index: true,
				element: <Home />,
			},
			{
				path: "onboarding",
				children: [
					{
						index: true,
						element: <Navigate to="/onboarding/welcome" replace />,
					},
					{ path: "welcome", element: <OnboardingWelcome /> },
					{ path: "language", element: <OnboardingLanguage /> },
					// { path: "shop-name", element: <OnboardingShopName /> },
					{ path: "wifi", element: <OnboardingWifi /> },
					{
						path: "registration",
						element: <OnboardingRegistration />,
					},
				],
			},
			{
				path: "history",
				children: [
					{ index: true, element: <History /> },
					{ path: ":measurementId", element: <Details /> },
				],
			},
			{
				path: "settings",
				element: <Settings />,
				children: [
					{
						index: true,
						element: <Navigate to="/settings/profile" replace />,
					},
					{ path: "profile", element: <SettingsProfile /> },
					{
						path: "export-settings",
						element: <SettingsExportSettings />,
					},
					{ path: "general", element: <SettingsGeneral /> },
					{ path: "wifi", element: <SettingsWifi /> },
					{ path: "update", element: <SettingsUpdate /> },
				],
			},
			{ path: "help", element: <Help /> },
			{
				path: "start",
				element: <NavigateWithParams to="/measurement/start" />,
			},
			{
				path: "measurement",
				element: <Measurement />,
				children: [
					{
						index: true,
						element: <Navigate to="/measurement/start" replace />,
					},
					{ path: "start", element: <MeasurementStart /> },
					{ path: "loading", element: <MeasurementLoading /> },
					{
						path: "eyes/:measurement/:method/:reevaluate?",
						element: <MeasurementEyes />,
					},
					{
						path: "frames/:measurement",
						element: <MeasurementFrames />,
					},
					{
						path: "result/:measurement",
						element: <MeasurementResult />,
					},
				],
			},
			{
				path: "admin",
				children: [
					{
						index: true,
						element: (
							<RequireAuth outlet={false} to="/admin/login">
								<></>
							</RequireAuth>
						),
					},
					{ path: "setup", element: <AdminSetup /> },
					{ path: "login", element: <AdminLogin /> },
					{ path: "reset", element: <AdminFactoryReset /> },
					{ path: "settings", element: <AdminSettings /> },
				],
			},
			{
				path: "maintainance",
				children: [
					{
						index: true,
						element: (
							<RequireAuth
								outlet={false}
								to="/maintainance/login"
							>
								<></>
							</RequireAuth>
						),
					},
					{ path: "login", element: <CalibrationLogin /> },
					{ path: "calibration", element: <Calibration /> },
				],
			},
		],
	},
];

const router = createHashRouter(routes);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
	<React.StrictMode>
		<BrandThemeContext.Provider value={brandVar}>
			<RouterProvider router={router} />
		</BrandThemeContext.Provider>
	</React.StrictMode>,
);
