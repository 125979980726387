import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import routes from "../../routes";
import { useBrandingStore, useMenuStore } from "../../store";
import { Close, OSInfo, OSLoading, RouteLogout, TurnOff } from "../icons";
import BrandLogo from "../logo/BrandLogo";
import { HeadingText } from "../typography";
import { TextButton } from "../buttons";
import { getSettings, shutdown } from "../../util/api/api-store";
import LoadingPanel from "../modals/LoadigPanel";
import { ModalBase, ModalFullScreen } from "../modals";
import TextHeading from "../typography/TextHeading";
import TextBody from "../typography/TextBody";
import EyesWithCheck from "../icons/EyesWithCheck";
import { cn } from "../../util/helpers";
import Footer from "./Footer";

type ShutdownProcess = {
	started: boolean;
	errorOccured: boolean;
	finished: boolean;
};

// TODO change names according to language
const SidebarMenu = () => {
	const location = useLocation();
	const { close, opened } = useMenuStore();
	const { t } = useTranslation();
	const [shutdownProcess, setShutdownProcess] = useState<ShutdownProcess>({
		started: false,
		errorOccured: false,
		finished: false,
	});

	const submitShutdown = () => {
		setShutdownProcess({
			started: true,
			errorOccured: false,
			finished: false,
		});
		shutdown.setRequest(null, true);
		shutdown.invalidate();
		shutdown
			.fetchData()
			.then((resp) => {
				setShutdownProcess({ ...shutdownProcess, finished: true });
			})
			.catch((err) => {
				setShutdownProcess({ ...shutdownProcess, errorOccured: true });
			});
	};

	const { branding } = useBrandingStore();

	useEffect(() => {
		getSettings.setRequest(null);
		getSettings.fetchData();
	});

	useEffect(() => {
		close();
		document.body.classList.remove("overflow-hidden");
	}, [location]);

	return (
		<>
			<div
				className={cn(
					opened ? "opacity-100" : "pointer-events-none opacity-0",
					"fixed z-30 transition-opacity duration-300 ease-in-out",
					branding !== "hoya"
						? "left-0 top-[85px] h-full w-full bg-black/30 backdrop-blur-sm"
						: "inset-0 bg-black/50",
				)}
				onClick={() => {
					close();
					document.body.classList.remove("overflow-hidden");
				}}
			></div>
			<div
				className={cn(
					branding === "hoya" &&
						(opened ? "translate-x-0" : "-translate-x-full"),
					opened
						? "pointer-events-auto opacity-100"
						: "pointer-events-none opacity-0",
					"fixed left-0 z-30 grid overflow-y-auto duration-300 ease-in-out",
					branding !== "hoya"
						? "top-[85px] h-[calc(100%-85px)] w-full grid-rows-1 transition-opacity"
						: "top-0 h-dvh w-[360px] grid-rows-[auto_1fr] bg-quaternary-20 transition-transform",
				)}
			>
				<nav
					className={cn(
						"flex flex-col gap-7",
						branding !== "hoya" && "container py-12",
					)}
				>
					<div
						className={cn(
							"ml-3 mt-3",
							branding !== "hoya" && "hidden",
						)}
					>
						<TextButton
							color="secondary"
							disabled={false}
							fullWidth={false}
							icon={Close}
							onClick={() => {
								close();
								document.body.classList.remove(
									"overflow-hidden",
								);
							}}
						/>
					</div>
					<ul
						className={cn(
							"flex flex-col px-6",
							branding !== "hoya" ? "gap-4 p-0" : "gap-8",
						)}
					>
						{routes.map((route) => (
							<li
								key={route.path}
								className={cn(
									branding !== "hoya" &&
										"rounded-[10px] bg-white",
								)}
							>
								<Link
									to={route.path}
									className={cn(
										branding !== "hoya" &&
											"grid grid-cols-[20px_1fr] items-center gap-3 px-5 py-4",
									)}
								>
									{branding !== "hoya" &&
										route.icon({
											className:
												"size-5 text-primary-100",
										})}
									<HeadingText
										h={4}
										className={cn(
											branding !== "hoya" &&
												"text-lg font-normal",
										)}
									>
										{t(`menu.${route.name}`)}
									</HeadingText>
								</Link>
							</li>
						))}
						<li>
							<button
								type="button"
								onClick={submitShutdown}
								className={cn(
									branding !== "hoya"
										? "grid w-full grid-cols-[20px_1fr] items-center gap-3 rounded-[10px] bg-white px-5 py-4 text-left"
										: "flex flex-row items-center gap-2",
								)}
							>
								{branding !== "hoya" ? (
									<RouteLogout className="size-5 text-primary-100" />
								) : (
									<TurnOff className="fill-primary-dark-100" />
								)}
								<HeadingText
									h={4}
									className={cn(
										branding !== "hoya" &&
											"text-lg font-normal",
									)}
								>
									{t("menu.shutdown")}
								</HeadingText>
							</button>
						</li>
					</ul>
				</nav>
				<div
					className={cn(
						"m-6 self-end",
						branding !== "hoya" && "hidden",
					)}
				>
					<BrandLogo />
				</div>
				{branding !== "hoya" && <Footer stickyFooter />}
			</div>
			<LoadingPanel
				open={shutdownProcess.started}
				loadingClass={cn("min-w-[48px] min-h-[48px] fill-white")}
				className={cn(branding !== "hoya" && "bg-pure-white")}
			>
				{branding !== "hoya" ? (
					<OSLoading className="animate-spin-slow" />
				) : (
					<TextHeading
						h={4}
						className={cn("text-2xl font-bold text-white")}
					>
						{t("sidebarMenu.shuttingDown")}
					</TextHeading>
				)}
			</LoadingPanel>
			<ModalBase
				title={t("sidebarMenu.error") || undefined}
				open={shutdownProcess.errorOccured}
				close={() =>
					setShutdownProcess({
						...shutdownProcess,
						started: false,
						errorOccured: false,
					})
				}
			>
				<TextBody className={cn("px-6 py-5")} type={"normal"}>
					{t("sidebarMenu.pleasUnplug")}
				</TextBody>
			</ModalBase>
			{branding !== "hoya" ? (
				<ModalBase
					open={shutdownProcess.finished}
					disableHeader
					close={() => undefined}
				>
					<div
						className={cn(
							"flex flex-col items-center gap-9 px-16 py-16",
						)}
					>
						<OSInfo />
						<div className={cn("flex flex-col items-center gap-4")}>
							<TextHeading className={cn("!text-[26px]")} h={1}>
								{t("shutdown.title")}
							</TextHeading>
							<TextBody type={"normal"}>
								{t("shutdown.text")}
							</TextBody>
						</div>
					</div>
				</ModalBase>
			) : (
				<ModalFullScreen
					open={shutdownProcess.finished}
					close={() => undefined}
				>
					<div
						className={cn(
							"flex min-h-full flex-col items-center justify-center",
						)}
					>
						<EyesWithCheck
							className={cn("min-h-[80px] min-w-[128px]")}
						/>
						<TextHeading className="mb-3 mt-10" h={2}>
							{t("shutdown.title")}
						</TextHeading>
						<TextBody type={"normal"}>
							{t("shutdown.text")}
						</TextBody>
					</div>
				</ModalFullScreen>
			)}
		</>
	);
};

export default SidebarMenu;
