import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { SolidButton } from "../../components/buttons";
import { SettingsFragment } from "../../components/fragments";
import { Close } from "../../components/icons";
import { Checkbox, CustomSelect, TextField } from "../../components/input";
import {
	ModalBase,
	ModalFullScreen,
	ModalPrivacyPolicy,
} from "../../components/modals";
import { InfoPanel } from "../../components/panels";
import { ButtonText, HeadingText } from "../../components/typography";
import { useBrandingStore, useSnackStore } from "../../store";
import {
	about,
	getInternetConnectionState,
	getOpticianId,
	getSettings,
	loadGDPR,
	setGDPR,
	setOpticianId,
	setRetentionPeriod,
} from "../../util/api/api-store";
import { Controller, get, SubmitHandler, useForm } from "react-hook-form";
import { cn } from "../../util/helpers";
import languageIcons from "../../util/LanguageIcons";
import { countries } from "countries-list";

type IFormValues = {
	branding: string;
	measurement_retention_days: number;
	system_nr: number;
	privacyPolicy: boolean;
	company_name: string;
	country_code: string;
	customer_number: string;
	my_name: string;
	shop_name: string;
};

const Profile = () => {
	const { t, i18n } = useTranslation();
	const { branding } = useBrandingStore();
	const [isSubmited, setIsSubmited] = useState<boolean>(false);
	const [modalOpen, setModalOpen] = useState<boolean>(false);
	const [stuck, setStuck] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [hasInternet, setHasInternet] = useState<boolean>(false);
	const buttonRef = useRef<HTMLDivElement>(null);
	const openSnack = useSnackStore((state) => state.open);
	const [selectedLanguageCode, setSelectedLanguageCode] =
		useState<string>("");

	const {
		control,
		handleSubmit,
		setValue,
		getValues,
		formState: { errors },
	} = useForm<IFormValues>({
		// mode: "onChange",
		defaultValues: {
			branding: "hoya",
			measurement_retention_days: 3,
			system_nr: 0,
			privacyPolicy: false,
			company_name: "",
			country_code: "",
			customer_number: "",
			my_name: "",
			shop_name: "",
		},
	});

	const languageOptions = languageIcons.map((lang) => ({
		value: lang.code,
		label: lang.name,
	}));

	const getCurrentLng = () =>
		i18n.language || window.localStorage.i18nextLng || "";

	const changeLanguage = (lng: string) => {
		i18n.changeLanguage(lng);
		setSelectedLanguageCode(lng);
	};

	const countriesNativeOptions = Object.entries(countries).map((item) => ({
		value: item[0],
		label: item[1].native,
	}));

	useEffect(() => {
		const cachedRef = buttonRef.current;

		const observer = new IntersectionObserver(
			([e]) => setStuck(e.intersectionRatio < 1),
			{ threshold: [1] },
		);

		if (null !== cachedRef) {
			observer.observe(cachedRef);
		}
	}, []);

	useEffect(() => {
		setSelectedLanguageCode(getCurrentLng());

		getInternetConnectionState.setRequest(null);
		getInternetConnectionState
			.fetchData()
			.then((resp) => {
				if (!resp.internet_connected) {
					openSnack(t("profile.connectToInternet"), "warning");
				}
				setHasInternet(resp.internet_connected);
			})
			.catch(() => {
				setHasInternet(false);
				openSnack(t("profile.connectToInternet"), "warning");
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, []);

	const onSubmit: SubmitHandler<IFormValues> = async (formData) => {
		setIsSubmited(true);

		if (hasInternet) {
			const data = {
				companyName: formData.company_name,
				name: formData.my_name,
				customerNumber: formData.customer_number,
				serialNumber: formData.system_nr,
				version: "1",
				branding: formData.branding,
				html: templateContent,
			};
			console.log(data);
			setGDPR.setRequest(data);
			setGDPR.setUrlparams({
				code: "Y8Hqd8dfIuHMqiBkmZTD5DiL8DfHp2qFOYCa9lYJVPtBcjK2MVN7mw==",
			});

			setRetentionPeriod.setRequest({ measurement_retention_days: 183 });
		}

		const dataOpticianId = {
			company_name: formData.company_name,
			country_code: formData.country_code,
			customer_number: formData.customer_number,
			my_name: formData.my_name,
			shop_name: formData.shop_name,
		};
		setOpticianId.setRequest(dataOpticianId);

		try {
			const promises = hasInternet
				? [
						setGDPR.fetchData(),
						setRetentionPeriod.fetchData(),
						setOpticianId.fetchData(),
					]
				: [setOpticianId.fetchData()];

			const responses = await Promise.all(promises);
			console.log(responses);
			getOpticianId.setRequest(null, true);
			getOpticianId.invalidate();
			getOpticianId.fetchData().then((resp) => {
				setValue("company_name", resp.company_name);
				setValue("country_code", resp.country_code);
				setValue("customer_number", resp.customer_number);
				setValue("my_name", resp.my_name);
				setValue("shop_name", resp.shop_name);
			});

			if (hasInternet) {
				setValue("measurement_retention_days", 183);
				setValue("privacyPolicy", true);
			}

			getSettings.invalidate();
			getSettings.fetchData();

			openSnack(t("profile.saveSuccessfully"), "ok");
		} catch (error) {
			console.error(error);
		} finally {
			setIsSubmited(false);
		}
	};

	const loadGDPRResponse = loadGDPR.useGetResult();
	const { templateContent } = loadGDPRResponse.unwrapOrDefault({
		templateContent: "",
	});

	useEffect(() => {
		about.setRequest(null);
		about.fetchData().then((resp) => {
			setValue("system_nr", resp.system_nr);
		});

		getOpticianId.setRequest(null);
		getOpticianId.fetchData().then((resp) => {
			setValue("company_name", resp.company_name);
			setValue("country_code", resp.country_code);
			setValue("customer_number", resp.customer_number);
			setValue("my_name", resp.my_name);
			setValue("shop_name", resp.shop_name);
		});

		getSettings.setRequest(null);
		getSettings.fetchData().then((resp) => {
			setValue(
				"privacyPolicy",
				resp.measurement_retention_days > 3 ? true : false,
			);
			setValue("branding", resp.branding);
			setValue(
				"measurement_retention_days",
				resp.measurement_retention_days,
			);
			loadGDPR.setRequest(null);
			loadGDPR.setUrlparams({
				branding: resp.branding,
				language: getCurrentLng().split("-")[0],
				code: "aMHEfEz2MzChHOcahdv8LhbY1XGRwTWNM4gw5LYkQ6ivtXPDN3FFOQ==",
			});
			loadGDPR.fetchData();
			// if (resp.measurement_retention_days > 3) {
			// 	setIsSubmited(true);
			// }
		});
	}, []);

	return (
		<>
			<div>
				<HeadingText
					h={4}
					className={cn(branding !== "hoya" && "mt-2 !text-[26px]")}
				>
					{t("profile.pageTitle")}
				</HeadingText>
				<SettingsFragment className={cn(branding !== "hoya" && "py-8")}>
					<form
						onSubmit={handleSubmit(onSubmit)}
						id="form-privacy_policy"
						className={cn("flex flex-col gap-8")}
					>
						<Controller
							name="company_name"
							control={control}
							rules={{
								required: {
									value: hasInternet,
									message: t(
										"privacyPolicy.enterCompanyName",
									),
								},
								minLength: {
									value: 4,
									message: t("privacyPolicy.minLength4"),
								},
							}}
							render={({ field: { name, value, onChange } }) => (
								<TextField
									type={
										isLoading || !hasInternet
											? "disabled"
											: "default"
									}
									htmlFor={name}
									label={t("gdpa.companyName")}
									info={
										t("privacyPolicy.min4Char") || undefined
									}
									value={value}
									setValue={(v) => onChange(v)}
									errors={errors}
								/>
							)}
						/>
						<Controller
							name="my_name"
							control={control}
							rules={{
								required: {
									value: hasInternet,
									message: t("privacyPolicy.enterName"),
								},
							}}
							render={({ field: { name, value, onChange } }) => (
								<TextField
									type={
										isLoading || !hasInternet
											? "disabled"
											: "default"
									}
									htmlFor={name}
									label={t("gdpa.name")}
									value={value}
									setValue={(v) => onChange(v)}
									errors={errors}
								/>
							)}
						/>
						<Controller
							name="customer_number"
							control={control}
							rules={{
								required: {
									value: hasInternet,
									message: t(
										"privacyPolicy.enterCustomerNumber",
									),
								},
							}}
							render={({ field: { name, value, onChange } }) => (
								<TextField
									type={
										isLoading || !hasInternet
											? "disabled"
											: "default"
									}
									htmlFor={name}
									label={t("gdpa.customerNumber")}
									info={
										t(
											"privacyPolicy.pleaseAskSalesPartner",
										) || undefined
									}
									value={value}
									setValue={(v) => onChange(v)}
									errors={errors}
								/>
							)}
						/>
						<Controller
							name="shop_name"
							control={control}
							rules={{
								required:
									t("profile.shopNameRequired") || undefined,
								maxLength: {
									value: 20,
									message: t("profile.maxLength20"),
								},
							}}
							render={({ field: { name, value, onChange } }) => (
								<TextField
									type={isLoading ? "disabled" : "default"}
									htmlFor={name}
									label={t("profile.shopName")}
									info={
										t("profile.shopNameDescription", {
											length: value.length,
										}) || undefined
									}
									value={value}
									setValue={(v) => onChange(v.slice(0, 20))}
									errors={errors}
								/>
							)}
						/>
						<CustomSelect
							label={t("profile.language")}
							value={selectedLanguageCode}
							setValue={(v) => {
								changeLanguage(v);
							}}
							options={languageOptions}
							fullWidth
						/>
						<Controller
							name="country_code"
							control={control}
							rules={{
								required:
									t("profile.countryRequired") || undefined,
							}}
							render={({ field: { name, value, onChange } }) => (
								<CustomSelect
									label={t("profile.country")}
									value={value}
									setValue={(v) => onChange(v)}
									options={countriesNativeOptions}
									isSearchable={true}
									alert={
										errors &&
										get(errors, "country_code")?.message
									}
								/>
							)}
						/>
						<TextField
							value={getValues("system_nr").toString()}
							type="disabled"
							htmlFor="serial-number"
							label={t("gdpa.serialNumber")}
						/>
						{!hasInternet && (
							<InfoPanel
								type="alert"
								title={t("profile.noInternetTitle")}
								message={
									t("profile.noInternetDescription") ||
									undefined
								}
								className={cn(
									branding !== "hoya" && "py-8 pr-8",
								)}
							/>
						)}
						{getValues("measurement_retention_days") > 3 ? (
							<InfoPanel
								type="done"
								title={t("profile.acceptDescription")}
								message={
									t("profile.cancelDescription") || undefined
								}
								className={cn(
									branding !== "hoya" && "py-8 pr-8",
								)}
							/>
						) : (
							<p>
								{t(
									"privacyPolicy.toBeAbleToKeepYourMeasurementPleaseAccept",
								)}{" "}
								<a
									onClick={() =>
										hasInternet ? setModalOpen(true) : null
									}
									className="cursor-pointer text-primary-80 underline"
								>
									{t("privacyPolicy.privacyPolicy")}
								</a>{" "}
								{t(
									"privacyPolicy.ifYouDontAcceptYourMeasurementWillBeDeleted",
								)}
							</p>
						)}
						<Controller
							name="privacyPolicy"
							control={control}
							rules={{
								required: {
									value: hasInternet,
									message: t(
										"privacyPolicy.acceptPrivacyPolicy",
									),
								},
							}}
							render={({ field: { value, onChange } }) => (
								<Checkbox
									label={
										<>
											{t("privacyPolicy.iAccept")}{" "}
											<a
												onClick={() =>
													hasInternet
														? setModalOpen(true)
														: null
												}
												className={cn(
													"cursor-pointer text-primary-80 underline",
												)}
											>
												{t(
													"privacyPolicy.privacyPolicy",
												)}
											</a>
											.
										</>
									}
									checked={value ? "checked" : "unchecked"}
									disabled={
										isLoading ||
										!hasInternet ||
										getValues(
											"measurement_retention_days",
										) > 3
									}
									alert={
										errors.privacyPolicy &&
										errors.privacyPolicy.message
									}
									setChecked={(v) => onChange(v)}
								/>
							)}
						/>
					</form>
				</SettingsFragment>
				<div
					ref={buttonRef}
					className={cn(
						"sticky bottom-[-1px] backdrop-blur before:absolute before:inset-0 before:bg-pure-white before:opacity-[0.88]",
					)}
				>
					<div
						className={clsx(
							"grid h-[72px] items-center justify-end",
							stuck && "shadow-[0px_-1px_0px_#e6e8e9]",
						)}
					>
						<SolidButton
							color="primary"
							form="form-privacy_policy"
							submit={true}
							disabled={isSubmited || isLoading}
						>
							{t("settings.save")}
						</SolidButton>
					</div>
				</div>
			</div>
			{branding !== "hoya" ? (
				<ModalBase
					title={t("privacyPolicy.privacyPolicy") || undefined}
					open={modalOpen}
					close={() => setModalOpen(false)}
				>
					<div className={cn("max-h-[712px] overflow-y-auto")}>
						<ModalPrivacyPolicy />
					</div>
				</ModalBase>
			) : (
				<ModalFullScreen
					open={modalOpen}
					close={() => setModalOpen(false)}
				>
					<div
						className={cn(
							"fixed top-0 z-10 w-full backdrop-blur before:absolute before:inset-0 before:bg-pure-white before:opacity-[0.88]",
						)}
					>
						<div
							className={cn(
								"relative grid h-[72px] grid-cols-[1fr_auto_1fr] items-center px-6 shadow-[0px_1px_0px_#e6e8e9]",
							)}
						>
							<ButtonText type="bold" className="col-start-2">
								{t("privacyPolicy.privacyPolicy")}
							</ButtonText>
							<Close
								className={cn(
									"cursor-pointer select-none justify-self-end fill-primary-dark-100",
								)}
								onClick={() => setModalOpen(false)}
							/>
						</div>
					</div>
					<ModalPrivacyPolicy />
				</ModalFullScreen>
			)}
		</>
	);
};

export default Profile;
