import { SVGProps } from "react";
import { Link } from "react-router-dom";
import { BodyText, HeadingText } from "../typography";
import { useBrandingStore } from "../../store";
import { cn } from "../../util/helpers";

export type MainCardProps = {
	title: string;
	to: string;
	icon?: (props: SVGProps<SVGSVGElement>) => JSX.Element;
	message: string;
	className?: string;
};

const MainCard = ({ title, to, icon, message, className }: MainCardProps) => {
	const { branding } = useBrandingStore();

	return (
		<Link
			to={to}
			className={cn(
				branding !== "hoya"
					? "flex w-full flex-row items-center justify-start gap-3 rounded-[20px] bg-[#EBECED] px-3.5 py-9"
					: "flex w-full flex-row items-center justify-start gap-6 bg-primary-100 px-6 py-5",
				className,
			)}
		>
			{icon?.call(null, {
				width: branding !== "hoya" ? 86 : 48,
				height: branding !== "hoya" ? 86 : 48,
				className: "fill-pure-white",
			})}
			<div className={cn(branding !== "hoya" && "flex flex-col gap-1")}>
				<HeadingText
					className={cn(
						branding !== "hoya"
							? "text-pure-black"
							: "text-pure-white",
					)}
					h={4}
				>
					{title}
				</HeadingText>
				<BodyText
					className={cn(
						branding !== "hoya"
							? "text-primary-dark-60"
							: "text-primary-20",
					)}
					type="normal"
				>
					{message}
				</BodyText>
			</div>
		</Link>
	);
};

export default MainCard;
