import { useEffect, useRef } from "react";
import { SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { SolidButton } from "../../components/buttons";
import { SettingsFragment } from "../../components/fragments";
import { CustomSelect, TextField } from "../../components/input";
import { TabComponent } from "../../components/tabs";
import { HeadingText } from "../../components/typography";
import {
	getAccessIdAsPromise,
	useBrandingStore,
	useSnackStore,
} from "../../store";
import {
	getHoyasettings,
	getSettings,
	home,
	setHoyasettings,
} from "../../util/api/api-store";
import { cn, CountryCodes } from "../../util/helpers";

const ExportSettings = () => {
	const { t } = useTranslation();
	const [state, updateState] = useState<"Option1" | "Option2">("Option1");
	const setValue = (v: SetStateAction<"Option1" | "Option2">) =>
		updateState(v);
	const [stuck, setStuck] = useState<boolean>(false);
	const buttonRef = useRef<HTMLDivElement>(null);
	const [isSubmited, setIsSubmited] = useState<boolean>(false);
	const openSnack = useSnackStore((state) => state.open);
	const { branding } = useBrandingStore();

	const [formData, setFormData] = useState({
		ciam_credentials: {
			login: "",
			password: "",
		},
		country_code: "",
		customer_email: "",
		customer_login: "",
		customer_number: "",
		customer_password: "",
	});

	useEffect(() => {
		const cachedRef = buttonRef.current;

		const observer = new IntersectionObserver(
			([e]) => setStuck(e.intersectionRatio < 1),
			{ threshold: [1] },
		);

		if (null !== cachedRef) {
			observer.observe(cachedRef);
		}
	}, []);

	useEffect(() => {
		getAccessIdAsPromise().then((access_id) => {
			home.setRequest({ access_id });
			home.invalidate();
			home.fetchData();
		});

		getSettings.setRequest(null);
		getSettings.fetchData().then((resp) => {
			getHoyasettings.setRequest(null);
			getHoyasettings.fetchData().then((hoyaResp) => {
				setFormData({
					...formData,
					ciam_credentials: hoyaResp.ciam_credentials,
					country_code: hoyaResp.country_code,
					customer_email: hoyaResp.customer_email,
					customer_login: hoyaResp.customer_login,
					customer_number: hoyaResp.customer_number,
					customer_password: hoyaResp.customer_password,
				});
			});
		});
	}, []);

	const settingsResponse = getSettings.useGetResult();

	const settings = settingsResponse.unwrapOrDefault({
		admin_mode: false,
		auto_exposure: 0,
		branding: "hoya",
		brightness: 0,
		contrast: 0,
		contrast_max: 0,
		contrast_min: 0,
		contrast_step: 0,
		debug_capture_timing: false,
		direct_loupe: false,
		expose_face_form_angle: false,
		exposure_time: 0,
		exposure_time_max: 0,
		exposure_time_min: 0,
		exposure_time_step: 0,
		eye_catcher: false,
		gain_max: 0,
		gain_min: 0,
		hide_shutdown: false,
		hide_wifi_settings: false,
		history_show_preview: false,
		inactive_time: 0,
		isp_digital_gain_max: 0,
		measurement_retention_days: 3,
		measurements_permanent: false,
		modules: {
			ai: false,
			b2b_optic: false,
			b2b_optic_download: false,
		},
		onboard: false,
		order_id_fixed: false,
		order_id_required: false,
		shop_name: "",
		show_aux_points: false,
		show_coordinates: false,
		show_editor_changes: false,
		show_frame_edge_editor: false,
		show_menu_item_debug: false,
		show_reevaluate_button: false,
		starlight_available: false,
		starlight_enabled: false,
		update_enabled: false,
		// user_instructions_url: string // The response does not contain this key.
	});

	const tHoyasettingsEmail = t("lensSupplierSettings.email");
	const tHoyasettingsTitle = t("lensSupplierSettings.title");

	const onSubmit = () => {
		setIsSubmited(true);
		const data = {
			country_code: formData.country_code,
			customer_email: formData.customer_email,
			customer_login: formData.customer_login,
			customer_number: formData.customer_number,
			customer_password: formData.customer_password,
			ciam_credentials: formData.ciam_credentials,
			manufacturer_code: "hoy",
			transmit_face_form_angle: true,
		};

		setHoyasettings.setRequest(data);
		setHoyasettings
			.fetchData()
			.then(() => {
				getHoyasettings.invalidate();
				getHoyasettings.fetchData();
				openSnack(t("exportSettings.savedSuccessfully"), "ok");
				setIsSubmited(false);
			})
			.catch((error) => {
				console.error(error);
				setIsSubmited(false);
			});

		getSettings.invalidate();
		getSettings.fetchData();
	};

	return (
		<div>
			<HeadingText
				h={4}
				className={cn(branding !== "hoya" && "mt-2 !text-[26px]")}
			>
				{t("exportSettings.pageTitle")}
			</HeadingText>
			<SettingsFragment
				title={t("exportSettings.emailSettings") || undefined}
				borderTop
				borderBottom
				className={cn(branding !== "hoya" && "mb-6 py-9")}
			>
				<div className={cn("flex flex-col gap-8")}>
					<TextField
						type="default"
						htmlFor="customer_email"
						label={t("exportSettings.email")}
						info={tHoyasettingsEmail}
						fieldOptions={{ type: "email" }}
						fullWidth
						value={formData.customer_email}
						setValue={(v) =>
							setFormData({
								...formData,
								customer_email: v,
							})
						}
					/>
				</div>
			</SettingsFragment>
			{branding === "hoya" && (
				<SettingsFragment title={tHoyasettingsTitle}>
					<div className={cn("flex flex-col gap-8")}>
						<TabComponent
							state={state}
							setState={(v) => setValue(v)}
							options={{
								option1: {
									label:
										branding === "hoya"
											? t("exportSettings.ilink")
											: t("exportSettings.option1"),
								},
								option2: {
									label:
										branding === "hoya"
											? t("exportSettings.ciam")
											: t("exportSettings.option2"),
								},
							}}
						/>
						{state === "Option1" && (
							<>
								<TextField
									type="default"
									htmlFor="ilink-customer_number"
									label={t(
										"lensSupplierSettings.customerNumber",
									)}
									fullWidth
									value={formData.customer_number}
									setValue={(v) =>
										setFormData({
											...formData,
											customer_number: v,
										})
									}
								/>
								<TextField
									type="default"
									htmlFor="ilink-customer_login"
									label={t("exportSettings.username")}
									fullWidth
									value={formData.customer_login}
									setValue={(v) =>
										setFormData({
											...formData,
											customer_login: v,
										})
									}
								/>
								<TextField
									type="default"
									htmlFor="ilink-customer_password"
									label={t(
										"lensSupplierSettings.customerPassword",
									)}
									fieldOptions={{ type: "password" }}
									fullWidth
									value={formData.customer_password}
									setValue={(v) =>
										setFormData({
											...formData,
											customer_password: v,
										})
									}
								/>
								<CustomSelect
									label={t("lensSupplierSettings.country")}
									value={formData.country_code}
									setValue={(v) => {
										setFormData({
											...formData,
											country_code: v,
										});
									}}
									options={CountryCodes}
									fullWidth
								/>
							</>
						)}
						{state === "Option2" && (
							<>
								<TextField
									type="default"
									htmlFor="ciam-customer_email"
									label={t("exportSettings.email")}
									fieldOptions={{ type: "email" }}
									fullWidth
									value={formData.ciam_credentials.login}
									setValue={(v) =>
										setFormData({
											...formData,
											ciam_credentials: {
												...formData.ciam_credentials,
												login: v,
											},
										})
									}
								/>
								<TextField
									type="default"
									htmlFor="ciam-customer_password"
									label={t(
										"lensSupplierSettings.customerPassword",
									)}
									fieldOptions={{ type: "password" }}
									fullWidth
									value={formData.ciam_credentials.password}
									setValue={(v) =>
										setFormData({
											...formData,
											ciam_credentials: {
												...formData.ciam_credentials,
												password: v,
											},
										})
									}
								/>
							</>
						)}
					</div>
				</SettingsFragment>
			)}
			<div
				ref={buttonRef}
				className={cn(
					"sticky bottom-[-1px] backdrop-blur before:absolute before:inset-0 before:bg-pure-white before:opacity-[0.88]",
				)}
			>
				<div
					className={cn(
						"grid h-[72px] items-center justify-end",
						stuck && "shadow-[0px_-1px_0px_#e6e8e9]",
					)}
				>
					<SolidButton
						color="primary"
						onClick={onSubmit}
						disabled={isSubmited}
					>
						{t("exportSettings.save")}
					</SolidButton>
				</div>
			</div>
		</div>
	);
};

export default ExportSettings;
