import { LegacyRef, useEffect, useRef } from "react";
import CheckboxState from "./CheckboxState";
import { cn } from "../../util/helpers";
import { useBrandingStore } from "../../store";

type PlainCheckboxProps = {
	checked: CheckboxState;
	setChecked: () => void;
	disabled?: boolean;
	className?: string;
};

const PlainCheckbox = ({
	checked,
	setChecked,
	disabled,
	className,
}: PlainCheckboxProps) => {
	const isIndeterminate = checked === "indeterminate";
	const checkboxRef = useRef(null);
	const { branding } = useBrandingStore();

	useEffect(() => {
		if (checkboxRef.current as LegacyRef<HTMLInputElement>) {
			// @ts-expect-error - Ref is not null
			checkboxRef.current.indeterminate = isIndeterminate;
		}
	}, [checkboxRef, isIndeterminate]);
	return (
		<input
			className={cn(
				"size-6 border-[3px] outline-none focus:ring-transparent",
				!disabled &&
					"cursor-pointer border-primary-100 text-primary-100 accent-primary-100",
				disabled &&
					"border-secondary-40 text-secondary-40 accent-secondary-40",
				branding !== "hoya" && "rounded-full border",
				className,
			)}
			type="checkbox"
			disabled={disabled}
			checked={checked === "checked"}
			ref={checkboxRef}
			onChange={setChecked}
		/>
	);
};

export default PlainCheckbox;
