import Modal from "react-modal";
import { cn } from "../../util/helpers";

type ModalFullScreenProps = {
	children: React.ReactNode;
	open: boolean;
	close: () => void;
	className?: string;
};

Modal.setAppElement("#root");

const ModalFullScreen = ({
	children,
	open,
	close,
	className,
}: ModalFullScreenProps) => {
	return (
		<Modal
			closeTimeoutMS={300}
			isOpen={open}
			onRequestClose={close}
			className={cn(
				className,
				"fixed inset-0 overflow-y-auto bg-pure-white focus-visible:outline-0",
			)}
			overlayClassName={cn(
				"fixed inset-0 z-30 bg-black/50 transition-opacity",
			)}
			bodyOpenClassName={cn("overflow-hidden")}
		>
			{children}
		</Modal>
	);
};

export default ModalFullScreen;
