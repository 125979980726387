import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { OutlineButton, SolidButton } from "../../components/buttons";
import { SettingsFragment } from "../../components/fragments";
import {
	Checkbox,
	CustomSelect,
	SliderComponent,
	Switch,
} from "../../components/input";
import { HeadingText } from "../../components/typography";
import {
	getAccessIdAsPromise,
	useBrandingStore,
	useSnackStore,
} from "../../store";
import {
	getSettings,
	restart,
	setSettings,
	testServerPresence,
} from "../../util/api/api-store";
import { unwrapFetchResult } from "../../util/result";
import ModalFullScreenLoading from "../../components/modals/ModalFullScreenLoading";
import TextHeading from "../../components/typography/TextHeading";
import { ModalBase } from "../../components/modals";
import TextBody from "../../components/typography/TextBody";
import { useNavigate } from "react-router-dom";
import { cn } from "../../util/helpers";

type RestartProcess = {
	started: boolean;
	errorRestart: boolean;
	errorMeasurements: boolean;
};

const General = () => {
	const { t } = useTranslation();
	const { branding } = useBrandingStore();
	const navigate = useNavigate();
	const [formData, setFormData] = useState({
		admin_mode: false,
		contrast: 0,
		contrast_max: 1,
		contrast_min: 0,
		contrast_step: 0.05,
		close_active_measurement: false,
		inactive_time: 0,
		eye_catcher: false,
		auto_exposure: false,
		exposure_time: 20,
		exposure_time_max: 0.25,
		exposure_time_min: 0.01,
		exposure_time_step: 0.01,
		ready_status_display: false,
		direct_loupe: false,
		history_show_preview: false,
	});

	const [stuck, setStuck] = useState<boolean>(false);
	const buttonRef = useRef<HTMLDivElement>(null);
	const [isSubmited, setIsSubmited] = useState<boolean>(false);
	const openSnack = useSnackStore((state) => state.open);
	const [restartProcess, setRestartProcess] = useState<RestartProcess>({
		started: false,
		errorRestart: false,
		errorMeasurements: false,
	});

	useEffect(() => {
		const cachedRef = buttonRef.current;

		const observer = new IntersectionObserver(
			([e]) => setStuck(e.intersectionRatio < 1),
			{ threshold: [1] },
		);

		if (null !== cachedRef) {
			observer.observe(cachedRef);
		}

		getSettings.setRequest(null);
		getSettings.fetchData().then((resp) =>
			setFormData({
				...formData,
				admin_mode: resp.admin_mode,
				contrast: resp.contrast,
				contrast_max: resp.contrast_max,
				contrast_min: resp.contrast_min,
				contrast_step: resp.contrast_step,
				eye_catcher: resp.eye_catcher,
				auto_exposure: resp.auto_exposure === 0 ? false : true,
				exposure_time: resp.exposure_time,
				exposure_time_max: resp.exposure_time_max,
				exposure_time_min: resp.exposure_time_min,
				exposure_time_step: resp.exposure_time_step,
				direct_loupe: resp.direct_loupe,
				history_show_preview: resp.history_show_preview,
				close_active_measurement:
					resp.inactive_time === 0 ? false : true,
				ready_status_display: resp.starlight_enabled,
				inactive_time: resp.inactive_time,
			}),
		);
	}, []);

	// const fetchResult = getSettings.useGetResult();

	const restartMachine = () => {
		setRestartProcess({
			started: true,
			errorRestart: false,
			errorMeasurements: false,
		});
		getAccessIdAsPromise()
			.then((access_id) => {
				restart.setRequest(
					{
						access_id: null, // @todo null?
						action: "reset",
					},
					true,
				);
				restart.invalidate();
				restart
					.fetchData()
					.then(checkForAvailability)
					.then(() => {
						openSnack(t("general.successfulRestart"), "ok");
						navigate("/");
					})
					.catch((err) => {
						console.log(err);
						setRestartProcess({
							...restartProcess,
							errorMeasurements: true,
						});
					});
			})
			.catch((err) => {
				console.log(err);
				setRestartProcess({ ...restartProcess, errorRestart: true });
			});
	};

	const checkForAvailability = () => {
		return new Promise<void>((resolve, reject) => {
			testServerPresence.setRequest(null, true);
			let tries = 0;
			/* eslint prefer-const: ["error", { ignoreReadBeforeAssign: true }] */
			const testServer = () => {
				if (tries++ > 10) {
					clearInterval(interval);
					reject();
				}
				testServerPresence.invalidate();
				testServerPresence
					.fetchData()
					.then((resp) => {
						if (resp.success) {
							clearInterval(interval);
							resolve();
						}
					})
					.catch((err) => {
						console.log(err);
					});
			};
			let interval = setInterval(testServer, 3000);
		});
	};

	const onSubmit = () => {
		setIsSubmited(true);
		const getSettingsResp = unwrapFetchResult(getSettings.getResult());
		const settings = {
			auto_exposure: formData.auto_exposure ? 1 : 0,
			contrast: formData.contrast,
			direct_loupe: formData.direct_loupe,
			exposure_time: formData.exposure_time,
			eye_catcher: formData.eye_catcher,
			history_show_preview: formData.history_show_preview,
			inactive_time: formData.close_active_measurement
				? formData.inactive_time
				: 0,
			starlight_enabled: formData.ready_status_display,
			onboard: getSettingsResp.onboard,
		};
		setSettings.setRequest(settings);
		setSettings.fetchData().then(() => {
			openSnack(t("general.saveSuccessful"), "ok");
			getSettings.invalidate();
			getSettings.fetchData().then((resp) => {
				setFormData({
					...formData,
					contrast: resp.contrast,
					contrast_max: resp.contrast_max,
					contrast_min: resp.contrast_min,
					contrast_step: resp.contrast_step,
					eye_catcher: resp.eye_catcher,
					auto_exposure: resp.auto_exposure === 0 ? false : true,
					exposure_time: resp.exposure_time,
					exposure_time_max: resp.exposure_time_max,
					exposure_time_min: resp.exposure_time_min,
					exposure_time_step: resp.exposure_time_step,
					direct_loupe: resp.direct_loupe,
					history_show_preview: resp.history_show_preview,
					close_active_measurement:
						resp.inactive_time === 0 ? false : true,
					inactive_time: resp.inactive_time,
				});
			});
			setIsSubmited(false);
		});
	};

	const settings = (
		<>
			<div>
				<HeadingText
					h={4}
					className={cn(branding !== "hoya" && "my-2 text-[26px]")}
				>
					{t("general.pageTitle")}
				</HeadingText>
				<SettingsFragment>
					<div className={cn("flex flex-col gap-8")}>
						<div
							className={cn(
								branding !== "hoya" &&
									"border-b border-b-quaternary-100 pb-8 pt-3",
							)}
						>
							<SliderComponent
								label={t("general.contrast")}
								min={formData.contrast_min}
								max={formData.contrast_max}
								step={formData.contrast_step}
								value={formData.contrast}
								setValue={(v) => {
									setFormData({ ...formData, contrast: v });
								}}
								fullWidth
							/>
						</div>
						<div
							className={cn(
								branding !== "hoya" &&
									"border-b border-b-quaternary-100 pb-8",
							)}
						>
							{branding !== "hoya" ? (
								<Switch
									value={
										formData.close_active_measurement
											? true
											: false
									}
									setValue={() =>
										setFormData({
											...formData,
											close_active_measurement:
												!formData.close_active_measurement,
										})
									}
									label={t("general.closeActive")}
									info={
										t("general.closeActiveDescription") ||
										undefined
									}
									disabled={formData.admin_mode}
								/>
							) : (
								<Checkbox
									label={t("general.closeActive")}
									info={
										t("general.closeActiveDescription") ||
										undefined
									}
									checked={
										formData.close_active_measurement
											? "checked"
											: "unchecked"
									}
									disabled={formData.admin_mode}
									setChecked={() =>
										setFormData({
											...formData,
											close_active_measurement:
												!formData.close_active_measurement,
										})
									}
								/>
							)}
							{formData.close_active_measurement &&
								!formData.admin_mode && (
									<div
										className={cn(
											branding !== "hoya"
												? "pb-6 pt-8"
												: "ml-9 mt-6",
										)}
									>
										<CustomSelect
											label={t("general.closeAfter")}
											value={formData.inactive_time.toString()}
											fullWidth
											disabled={
												!formData.close_active_measurement
											}
											setValue={(v) => {
												setFormData({
													...formData,
													inactive_time: parseInt(v),
												});
											}}
											options={[
												{
													value: "60",
													label: t(
														"general.closeAfter60",
													),
												},
												{
													value: "90",
													label: t(
														"general.closeAfter90",
													),
												},
												{
													value: "120",
													label: t(
														"general.closeAfter120",
													),
												},
											]}
										/>
									</div>
								)}
						</div>
						<div
							className={cn(
								branding !== "hoya" &&
									"border-b border-b-quaternary-100 pb-8",
							)}
						>
							{branding !== "hoya" ? (
								<Switch
									value={formData.eye_catcher ? true : false}
									setValue={() =>
										setFormData({
											...formData,
											eye_catcher: !formData.eye_catcher,
										})
									}
									label={t("start.eyeCatcher")}
									info={
										t("general.eyeCatcherDescription") ||
										undefined
									}
								/>
							) : (
								<Checkbox
									label={t("start.eyeCatcher")}
									info={
										t("general.eyeCatcherDescription") ||
										undefined
									}
									checked={
										formData.eye_catcher
											? "checked"
											: "unchecked"
									}
									setChecked={() =>
										setFormData({
											...formData,
											eye_catcher: !formData.eye_catcher,
										})
									}
								/>
							)}
						</div>
						<div
							className={cn(
								branding !== "hoya" &&
									"border-b border-b-quaternary-100 pb-8",
							)}
						>
							{branding !== "hoya" ? (
								<Switch
									value={
										formData.auto_exposure ? true : false
									}
									setValue={() =>
										setFormData({
											...formData,
											auto_exposure:
												!formData.auto_exposure,
										})
									}
									label={t("general.autoExposure")}
									info={
										t("general.autoExposureDescription") ||
										undefined
									}
								/>
							) : (
								<Checkbox
									label={t("general.autoExposure")}
									info={
										t("general.autoExposureDescription") ||
										undefined
									}
									checked={
										formData.auto_exposure
											? "checked"
											: "unchecked"
									}
									setChecked={() =>
										setFormData({
											...formData,
											auto_exposure:
												!formData.auto_exposure,
										})
									}
								/>
							)}
							{!formData.auto_exposure && (
								<div
									className={cn(
										branding !== "hoya"
											? "pb-3 pt-10"
											: "ml-9 mt-6",
									)}
								>
									<SliderComponent
										label={t("general.exposureTime")}
										min={formData.exposure_time_min}
										max={formData.exposure_time_max}
										step={formData.exposure_time_step}
										value={formData.exposure_time}
										setValue={(v) =>
											setFormData({
												...formData,
												exposure_time: v,
											})
										}
										fullWidth
										disabled={formData.auto_exposure}
									/>
								</div>
							)}
						</div>
						<div
							className={cn(
								branding !== "hoya" &&
									"border-b border-b-quaternary-100 pb-8",
							)}
						>
							{branding !== "hoya" ? (
								<Switch
									value={
										formData.ready_status_display
											? true
											: false
									}
									setValue={() =>
										setFormData({
											...formData,
											ready_status_display:
												!formData.ready_status_display,
										})
									}
									label={t("general.readyStatusDisplay")}
									info={
										t(
											"general.readyStatusDisplayDescription",
										) || undefined
									}
								/>
							) : (
								<Checkbox
									label={t("general.readyStatusDisplay")}
									info={
										t(
											"general.readyStatusDisplayDescription",
										) || undefined
									}
									checked={
										formData.ready_status_display
											? "checked"
											: "unchecked"
									}
									setChecked={() =>
										setFormData({
											...formData,
											ready_status_display:
												!formData.ready_status_display,
										})
									}
								/>
							)}
						</div>
						<div
							className={cn(
								branding !== "hoya" &&
									"border-b border-b-quaternary-100 pb-8",
							)}
						>
							{branding !== "hoya" ? (
								<Switch
									value={formData.direct_loupe ? true : false}
									setValue={() =>
										setFormData({
											...formData,
											direct_loupe:
												!formData.direct_loupe,
										})
									}
									label={t("general.loupe")}
									info={
										t("general.loupeDescription") ||
										undefined
									}
								/>
							) : (
								<Checkbox
									label={t("general.loupe")}
									info={
										t("general.loupeDescription") ||
										undefined
									}
									checked={
										formData.direct_loupe
											? "checked"
											: "unchecked"
									}
									setChecked={() =>
										setFormData({
											...formData,
											direct_loupe:
												!formData.direct_loupe,
										})
									}
								/>
							)}
						</div>
						<div
							className={cn(
								branding !== "hoya" &&
									"border-b border-b-quaternary-100 pb-8",
							)}
						>
							{branding !== "hoya" ? (
								<Switch
									value={
										formData.history_show_preview
											? true
											: false
									}
									setValue={() =>
										setFormData({
											...formData,
											history_show_preview:
												!formData.history_show_preview,
										})
									}
									label={t("general.preview")}
									info={
										t("general.previewDescription") ||
										undefined
									}
								/>
							) : (
								<Checkbox
									label={t("general.preview")}
									info={
										t("general.previewDescription") ||
										undefined
									}
									checked={
										formData.history_show_preview
											? "checked"
											: "unchecked"
									}
									setChecked={() =>
										setFormData({
											...formData,
											history_show_preview:
												!formData.history_show_preview,
										})
									}
								/>
							)}
						</div>
					</div>
					<div
						className={cn(
							branding !== "hoya" &&
								"border-b border-b-quaternary-100 pb-8",
						)}
					>
						<OutlineButton
							color="primary"
							submit={false}
							disabled={isSubmited}
							onClick={restartMachine}
							className="mt-9"
						>
							{t("settings.reset")}
						</OutlineButton>
					</div>
				</SettingsFragment>
				<div
					ref={buttonRef}
					className={cn(
						"sticky bottom-[-1px] backdrop-blur before:absolute before:inset-0 before:bg-pure-white before:opacity-[0.88]",
					)}
				>
					<div
						className={cn(
							"grid h-[72px] items-center justify-end",
							stuck && "shadow-[0px_-1px_0px_#e6e8e9]",
						)}
					>
						<SolidButton
							color="primary"
							onClick={onSubmit}
							disabled={isSubmited}
						>
							{t("general.save")}
						</SolidButton>
					</div>
				</div>
			</div>
			<ModalFullScreenLoading
				open={
					restartProcess.started &&
					(!restartProcess.errorRestart ||
						!restartProcess.errorMeasurements)
				}
				loadingClass="min-w-[48px] min-h-[48px] fill-white"
			>
				<TextHeading h={4} className="text-2xl font-bold text-white">
					{t("general.restarting")}
				</TextHeading>
			</ModalFullScreenLoading>
			<ModalBase
				title={
					restartProcess.errorRestart
						? "Error"
						: "Restart not possible"
				}
				open={
					restartProcess.errorRestart ||
					restartProcess.errorMeasurements
				}
				close={() =>
					setRestartProcess({
						started: false,
						errorRestart: false,
						errorMeasurements: false,
					})
				}
			>
				<TextBody className={cn("px-6 py-5")} type={"normal"}>
					<>
						{restartProcess.errorRestart &&
							t("general.errorDescription")}
						{restartProcess.errorMeasurements &&
							t("general.errorDescriptionMeasurements")}
					</>
				</TextBody>
				{restartProcess.errorMeasurements && (
					<p className="text-right">
						<SolidButton
							color="primary"
							onClick={() => {
								setRestartProcess({
									started: false,
									errorRestart: false,
									errorMeasurements: false,
								});
								restartMachine();
							}}
						>
							Try again
						</SolidButton>
					</p>
				)}
			</ModalBase>
		</>
	);

	// @todo switch?
	// switch (fetchResult.kind) {
	// 	case "loading":
	// 		return <p>Loading</p>;
	// 	case "err":
	// 		return <p>Error: {fetchResult.error.toString()}</p>;
	// 	case "ok":
	// 		return settings;
	// }

	return settings;
};

export default General;
