import { SVGProps } from "react";

const OSNext = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<circle cx="12" cy="12" r="12" fill="currentColor" />
			<path
				d="M6.14852 11.535L12.133 11.535C12.4098 11.535 12.6345 11.7597 12.6345 12.0365C12.6345 12.3134 12.4098 12.5381 12.133 12.5381L6.14852 12.5381C5.87167 12.5381 5.64697 12.3134 5.64697 12.0365C5.64697 11.7597 5.87167 11.535 6.14852 11.535Z"
				fill="white"
			/>
			<mask
				id="mask0_1_3126"
				style={{ maskType: "luminance" }}
				maskUnits="userSpaceOnUse"
				x="11"
				y="8"
				width="7"
				height="8"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M11.6313 15.8823L11.6313 8.19075L17.9416 8.19075L17.9416 15.8823L11.6313 15.8823Z"
					fill="white"
				/>
			</mask>
			<g mask="url(#mask0_1_3126)">
				<path
					d="M12.1329 8.19055C12.2265 8.19055 12.3188 8.21663 12.4004 8.26745L17.7074 11.6118C17.8532 11.7041 17.9422 11.8639 17.9422 12.0364C17.9422 12.209 17.8532 12.3688 17.7074 12.4611L12.4004 15.8054C12.2459 15.9024 12.0506 15.9084 11.8908 15.8194C11.7303 15.7312 11.6313 15.5633 11.6313 15.3807L11.6313 8.6921C11.6313 8.50953 11.7303 8.34168 11.8908 8.25341C11.9664 8.21128 12.05 8.19055 12.1329 8.19055Z"
					fill="white"
				/>
			</g>
		</svg>
	);
};

export default OSNext;
