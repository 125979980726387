import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Info } from "../icons";
import { ModalBase, ModalSystemInfo } from "../modals";
import { BodyText, ButtonText } from "../typography";
import { getSettings } from "../../util/api/api-store";
import { useBrandingStore } from "../../store";
import { application, cn } from "../../util/helpers";

type FooterProps = {
	children?: React.ReactNode;
	stickyFooter?: boolean;
};

const Footer = ({ children, stickyFooter }: FooterProps) => {
	const { t } = useTranslation();
	const { branding } = useBrandingStore();
	const [modalOpen, setmodalOpen] = useState<boolean>(false);

	const { aiModule } = getSettings
		.useGetResult()
		.map((resp) => ({
			aiModule: resp.modules.ai,
		}))
		.unwrapOrDefault({
			aiModule: false,
		});

	const closeModal = () => {
		setmodalOpen(false);
	};

	return (
		<footer
			className={cn(
				stickyFooter ? "sticky bottom-0 z-10" : "relative",
				branding !== "optiswiss" && "before:opacity-[0.88]",
				"w-full self-end backdrop-blur before:absolute before:inset-0 before:bg-pure-white",
			)}
		>
			{children ? (
				<div
					className={cn(
						"relative grid h-[72px] items-center shadow-[0px_-1px_0px_#e6e8e9]",
					)}
				>
					{children}
				</div>
			) : (
				<>
					<div
						className={cn(
							"relative h-[72px] shadow-[0px_-1px_0px_#e6e8e9]",
							branding !== "hoya"
								? "flex items-center justify-center gap-10"
								: "grid grid-cols-[1fr_auto_1fr] items-center px-6",
						)}
					>
						{branding === "hoya" ? (
							<ButtonText type="bold" className="col-start-2">
								{t("application.title_visureal")}{" "}
								{t("application.title")}
								{aiModule && (
									<sup
										className={cn("ml-1 text-primary-100")}
									>
										AI
									</sup>
								)}
							</ButtonText>
						) : (
							<div className={cn("flex flex-col items-center")}>
								<BodyText
									type={"normal"}
									className={cn(
										"!leading-[1em] text-pure-black",
									)}
								>
									<span
										dangerouslySetInnerHTML={{
											__html: application[branding].title,
										}}
									></span>
								</BodyText>
								<p
									className={cn("text-[8px] text-pure-black")}
									dangerouslySetInnerHTML={{
										__html: application[branding]
											.title_visureal,
									}}
								></p>
							</div>
						)}
						<Info
							onClick={() => setmodalOpen(true)}
							className={cn(
								"cursor-pointer",
								branding !== "hoya"
									? "text-primary-100"
									: "justify-self-end",
							)}
						/>
					</div>
					<ModalBase
						title={t("footer.systemInfo") || undefined}
						open={modalOpen}
						close={closeModal}
					>
						<ModalSystemInfo />
					</ModalBase>
				</>
			)}
		</footer>
	);
};

export default Footer;
