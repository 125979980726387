import { SVGProps } from "react";
const RouteSettings = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			width="20"
			height="21"
			viewBox="0 0 20 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M17.3392 6.85305L16.8205 5.95298C16.3817 5.19138 15.4092 4.92864 14.6466 5.36564V5.36564C14.2835 5.57949 13.8504 5.64017 13.4425 5.53428C13.0347 5.42839 12.6858 5.16464 12.4727 4.80118C12.3355 4.57016 12.2619 4.30704 12.2591 4.03841V4.03841C12.2714 3.60773 12.109 3.19038 11.8086 2.88143C11.5083 2.57249 11.0957 2.39826 10.6649 2.39844H9.61987C9.19776 2.39843 8.79305 2.56664 8.49528 2.86583C8.19752 3.16502 8.03126 3.57053 8.03329 3.99264V3.99264C8.02078 4.86415 7.31068 5.56405 6.43909 5.56396C6.17046 5.56117 5.90733 5.48749 5.67631 5.35038V5.35038C4.91367 4.91339 3.94121 5.17612 3.5024 5.93772L2.94557 6.85305C2.50729 7.6137 2.76645 8.58555 3.52528 9.02697V9.02697C4.01854 9.31174 4.32239 9.83803 4.32239 10.4076C4.32239 10.9771 4.01854 11.5034 3.52528 11.7882V11.7882C2.76742 12.2267 2.50797 13.1961 2.94557 13.9545V13.9545L3.47189 14.8622C3.67749 15.2332 4.02245 15.507 4.43045 15.6229C4.83844 15.7389 5.27582 15.6875 5.6458 15.4801V15.4801C6.00952 15.2678 6.44294 15.2097 6.84973 15.3185C7.25653 15.4274 7.60298 15.6942 7.81209 16.0598C7.9492 16.2908 8.02288 16.5539 8.02567 16.8225V16.8225C8.02567 17.703 8.73941 18.4167 9.61987 18.4167H10.6649C11.5424 18.4168 12.2549 17.7076 12.2591 16.8302V16.8302C12.257 16.4067 12.4243 16.0001 12.7238 15.7006C13.0232 15.4012 13.4298 15.2339 13.8533 15.236C14.1213 15.2431 14.3833 15.3165 14.6161 15.4495V15.4495C15.3767 15.8878 16.3485 15.6287 16.79 14.8698V14.8698L17.3392 13.9545C17.5517 13.5896 17.6101 13.155 17.5013 12.747C17.3925 12.339 17.1255 11.9912 16.7595 11.7806V11.7806C16.3934 11.57 16.1264 11.2222 16.0176 10.8142C15.9088 10.4061 15.9672 9.97156 16.1797 9.60668C16.318 9.36532 16.5181 9.16521 16.7595 9.02697V9.02697C17.5137 8.58579 17.7723 7.61962 17.3392 6.86068V6.86068V6.85305Z"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<ellipse
				cx="10.146"
				cy="10.4075"
				rx="2.1968"
				ry="2.1968"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default RouteSettings;
