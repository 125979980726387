import { SVGProps } from "react";
const SvgLoading = (props: SVGProps<SVGSVGElement>) => (
	<svg
		fill="currentColor"
		height={props.height || 24}
		width={props.width || 24}
		xmlns="http://www.w3.org/2000/svg"
		role="img"
		viewBox="0 0 48 48"
		{...props}
	>
		<path
			clipRule="evenodd"
			d="M24 6c5 0 9.4 2 12.8 5.2L42 6v14.2H27.8l6-6c-2.4-2.6-6-4.2-9.8-4.2-7 0-12.8 5.2-13.8 12h-4c1-9 8.6-16 17.8-16zm-9.8 27.8c2.4 2.6 6 4.2 9.8 4.2 7 0 12.8-5.2 13.8-12h4c-1 9-8.6 16-17.8 16-5 0-9.4-2-12.8-5.2L6 42V27.8h14.2z"
			fill={`unset`}
			fillRule="evenodd"
		/>
	</svg>
);
export default SvgLoading;
