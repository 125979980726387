import { SVGProps } from "react";
const RouteMeasurement = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			width="18"
			height="19"
			viewBox="0 0 18 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g clipPath="url(#clip0_1_3283)">
				<mask
					id="mask0_1_3283"
					style={{ maskType: "luminance" }}
					maskUnits="userSpaceOnUse"
					x="0"
					y="0"
					width="18"
					height="19"
				>
					<path d="M0 0.500002H18V18.5H0V0.500002Z" fill="white" />
				</mask>
				<g mask="url(#mask0_1_3283)">
					<path
						d="M9 5.28136C4.32137 5.32288 0.527344 9.49993 0.527344 9.49993C0.527344 9.49993 4.32137 13.677 9 13.7185C13.6786 13.677 17.4727 9.49993 17.4727 9.49993C17.4727 9.49993 13.6786 5.32288 9 5.28136Z"
						stroke="currentColor"
						strokeMiterlimit="10"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M17.4727 15.8633V17.9727H15.3633"
						stroke="currentColor"
						strokeMiterlimit="10"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M0.527344 15.8633V17.9727H2.63672"
						stroke="currentColor"
						strokeMiterlimit="10"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M17.4727 3.13672V1.02734H15.3633"
						stroke="currentColor"
						strokeMiterlimit="10"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M0.527344 3.13672V1.02734H2.63672"
						stroke="currentColor"
						strokeMiterlimit="10"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M10.7578 9.5C10.7578 10.4708 9.9708 11.2578 9 11.2578C8.0292 11.2578 7.24219 10.4708 7.24219 9.5C7.24219 8.5292 8.0292 7.74219 9 7.74219C9.9708 7.74219 10.7578 8.5292 10.7578 9.5Z"
						stroke="currentColor"
						strokeMiterlimit="10"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M13.2188 9.5C13.2188 11.8299 11.3299 13.7188 9 13.7188C6.67005 13.7188 4.78125 11.8299 4.78125 9.5C4.78125 7.17005 6.67005 5.28125 9 5.28125C11.3299 5.28125 13.2188 7.17005 13.2188 9.5Z"
						stroke="currentColor"
						strokeMiterlimit="10"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
			</g>
			<defs>
				<clipPath id="clip0_1_3283">
					<rect
						width="18"
						height="18"
						fill="white"
						transform="translate(0 0.5)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default RouteMeasurement;
