import J from "joi";

export type IRequestData = null;

export interface IResponseData {
	company_name: string;
	country_code: string;
	customer_number: string;
	my_name: string;
	shop_name: string;
}

export type IResponseError = null;

const responseDataShape = J.object({
	company_name: J.string().allow(""),
	country_code: J.string().allow(""),
	customer_number: J.string().allow(""),
	my_name: J.string().allow(""),
	shop_name: J.string().allow(""),
})
	.required()
	.unknown();

const uri = import.meta.env.VITE_MASTER_SERVER_HOST + "get_optician_id";

// eslint-disable-next-line
export function isValidResponse(resp: any): resp is IResponseData {
	const validationError = responseDataShape.validate(resp).error;
	return validationError === undefined;
}

// eslint-disable-next-line
export function isValidResponseError(respErr: any): respErr is IResponseError {
	return respErr === null;
}

// eslint-disable-next-line
export function request(_: IRequestData): Promise<Response> {
	return fetch(uri, {
		method: "GET",
	});
}
