import belgiumIcon from "/icons/country_flags/belgium.svg";
import chinaIcon from "/icons/country_flags/china.svg";
import czechRepublicIcon from "/icons/country_flags/czech_republic.svg";
import denmarkIcon from "/icons/country_flags/denmark.svg";
import finlandIcon from "/icons/country_flags/finland.svg";
import franceIcon from "/icons/country_flags/france.svg";
import germanyIcon from "/icons/country_flags/germany.svg";
import hungaryIcon from "/icons/country_flags/hungary.svg";
import italyIcon from "/icons/country_flags/italy.svg";
import japanIcon from "/icons/country_flags/japan.svg";
import lithuaniaIcon from "/icons/country_flags/lithuania.svg";
import netherlandsIcon from "/icons/country_flags/netherlands.svg";
import polandIcon from "/icons/country_flags/poland.svg";
import portugalIcon from "/icons/country_flags/portugal.svg";
import spainIcon from "/icons/country_flags/spain.svg";
import swedenIcon from "/icons/country_flags/sweden.svg";
import unitedKingdomIcon from "/icons/country_flags/united_kingdom.svg";
import taiwanIcon from "/icons/country_flags/taiwan.svg";
import brasilIcon from "/icons/country_flags/brasil.svg";
import romaniaIcon from "/icons/country_flags/romania.svg";
import thailandIcon from "/icons/country_flags/thailand.svg";
import turkeyIcon from "/icons/country_flags/turkey.svg";
import koreaIcon from "/icons/country_flags/korea.svg";

export interface languageIcon {
	name: string;
	code: string;
	iconSrc: string;
}

const languageIcons: Array<languageIcon> = [
	{
		name: "português do Brasil",
		code: "pt-BR",
		iconSrc: brasilIcon,
	},
	{
		name: "Français (BE)",
		code: "fr-BE",
		iconSrc: belgiumIcon,
	},
	{
		name: "Čeština",
		code: "cs-CZ",
		iconSrc: czechRepublicIcon,
	},
	{
		name: "中文",
		code: "zh-CN",
		iconSrc: chinaIcon,
	},
	{
		name: "Dansk",
		code: "da-DK",
		iconSrc: denmarkIcon,
	},
	{
		name: "Deutsch",
		code: "de-DE",
		iconSrc: germanyIcon,
	},
	{
		name: "English",
		code: "en-GB",
		iconSrc: unitedKingdomIcon,
	},
	{
		name: "Español",
		code: "es-ES",
		iconSrc: spainIcon,
	},
	{
		name: "Suomi",
		code: "fi-FI",
		iconSrc: finlandIcon,
	},
	{
		name: "Français",
		code: "fr-FR",
		iconSrc: franceIcon,
	},
	{
		name: "Italiano",
		code: "it-IT",
		iconSrc: italyIcon,
	},
	{
		name: "日本の",
		code: "ja-JP",
		iconSrc: japanIcon,
	},
	{
		name: "한국인",
		code: "ko-KR",
		iconSrc: koreaIcon,
	},
	{
		name: "Lietuvių",
		code: "lt-LT",
		iconSrc: lithuaniaIcon,
	},
	{
		name: "Magyar",
		code: "hu-HU",
		iconSrc: hungaryIcon,
	},
	{
		name: "Nederlands",
		code: "nl-NL",
		iconSrc: netherlandsIcon,
	},
	{
		name: "Nederlands (BE)",
		code: "nl-BE",
		iconSrc: belgiumIcon,
	},
	{
		name: "Polski",
		code: "pl-PL",
		iconSrc: polandIcon,
	},
	{
		name: "Português",
		code: "pt-PT",
		iconSrc: portugalIcon,
	},
	{
		name: "limba română",
		code: "ro-RO",
		iconSrc: romaniaIcon,
	},
	{
		name: "Svenska",
		code: "sv-SE",
		iconSrc: swedenIcon,
	},
	{
		name: "ภาษาไทย",
		code: "th-TH",
		iconSrc: thailandIcon,
	},
	{
		name: "Türkçe",
		code: "tr-TR",
		iconSrc: turkeyIcon,
	},
	{
		name: "繁體中文",
		code: "zh-TW",
		iconSrc: taiwanIcon,
	},
];

export default languageIcons;
