import { MeasurementLoading, Photo, Warning } from "../../icons";
import { useTranslation } from "react-i18next";
import { HeadingText } from "../../typography";
import { useBrandingStore } from "../../../store";
import { cn } from "../../../util/helpers";

export type DisplayOption = {
	message: string;
	isActive: boolean;
};

export type ErrorsProps = {
	errors: DisplayOption[];
	status: DisplayOption[];
};

function enumerate<T extends object>(arr: T[]): (T & { key: number })[] {
	return arr.map((obj, index) => ({ ...obj, key: index }));
}

const CanvasErrors = ({ errors, status }: ErrorsProps) => {
	const { t } = useTranslation();
	const { branding } = useBrandingStore();
	const hasError = errors.reduce((acc, s) => acc || s.isActive, false);
	const errorsWithKeys = enumerate(errors);
	const statusWithKeys = enumerate(status);

	return (
		<div
			className={cn(
				"container flex flex-col gap-3 py-[72px] text-center",
			)}
		>
			{hasError ? (
				<>
					<div
						className={cn(
							"mb-7 mt-12 grid place-items-center text-quaternary-100 md:mt-0",
						)}
					>
						<p className={cn("col-start-1 row-start-1")}>
							<svg
								fill="none"
								width={128}
								height={80}
								className="mx-auto"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 128 80"
							>
								<path
									d="M126.85 37.71C126.27 36 107.25 0 64 0S1.73 36 1.15 37.71L0 40l1.15 2.29C1.73 44 20.75 80 64 80s62.27-36 62.85-37.71L128 40l-1.15-2.29Z"
									fill="currentColor"
								/>
								<path
									d="M64 64c13.255 0 24-10.745 24-24S77.255 16 64 16 40 26.745 40 40s10.745 24 24 24Z"
									fill="#fff"
								/>
							</svg>
						</p>
						<span className={cn("col-start-1 row-start-1")}>
							<Warning />
						</span>
					</div>
					<HeadingText h={2}>{t("canvasErrors.error")}</HeadingText>
					{errorsWithKeys.map((opt) =>
						opt.isActive ? (
							<p key={opt.key}>{opt.message}</p>
						) : (
							<p className="hidden" key={opt.key}></p>
						),
					)}
				</>
			) : (
				<>
					<div
						className={cn(
							"mb-7 mt-12 grid place-items-center text-quaternary-100 md:mt-0",
						)}
					>
						{branding === "hoya" ? (
							<>
								<p className={cn("col-start-1 row-start-1")}>
									<svg
										fill="none"
										width={128}
										height={80}
										className="mx-auto"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 128 80"
									>
										<path
											d="M126.85 37.71C126.27 36 107.25 0 64 0S1.73 36 1.15 37.71L0 40l1.15 2.29C1.73 44 20.75 80 64 80s62.27-36 62.85-37.71L128 40l-1.15-2.29Z"
											fill="currentColor"
										/>
										<path
											d="M64 64c13.255 0 24-10.745 24-24S77.255 16 64 16 40 26.745 40 40s10.745 24 24 24Z"
											fill="currentColor"
										/>
									</svg>
								</p>
								<span className="col-start-1 row-start-1">
									<div className="loader"></div>
								</span>
							</>
						) : (
							<MeasurementLoading
								className={cn(
									"size-[140px] animate-loading text-primary-100",
								)}
							/>
						)}
					</div>
					<HeadingText h={2}>{t("canvasErrors.loading")}</HeadingText>
					{statusWithKeys.map((opt) =>
						opt.isActive ? (
							<p key={opt.key}>{opt.message}</p>
						) : (
							<p className="hidden" key={opt.key}></p>
						),
					)}
				</>
			)}
		</div>
	);
};

export default CanvasErrors;
