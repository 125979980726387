import { cn } from "../../util/helpers";

type TextButtonProps = {
	type: "normal" | "bold";
	children?: string | React.ReactNode;
	className?: string;
	onClick?: () => void;
};

const TextButton = ({
	type,
	children,
	className,
	onClick,
}: TextButtonProps) => {
	return (
		<span
			onClick={onClick}
			className={cn(
				type === "normal" ? "font-normal" : "font-bold",
				"text-sm leading-5 md:text-base md:leading-6",
				className,
			)}
		>
			{children}
		</span>
	);
};

export default TextButton;
