import { useState } from "react";
import { useTranslation } from "react-i18next";
import { usePopper } from "react-popper";
import OutsideClickHandler from "react-outside-click-handler";
import {
	Details,
	Download,
	IconDelete,
	MoreVertical,
	OSDownload,
	PhotoLibrary,
	Trash,
} from "../icons";
import { PlainCheckbox } from "../input";
import { BodyText, ButtonText } from "../typography";
import DraftTag from "./DraftTag";
import { format, formatRelative } from "date-fns";
import { TextButton } from "../buttons";
import { Dropdown } from "../cards";
import { useNavigate } from "react-router-dom";
import { cn, downloadXml } from "../../util/helpers";
import { useBrandingStore } from "../../store";

type TableRowActions = {
	toggleSelection: (b: boolean) => void;
	deleteMeasurement: () => void;
};

type TableRowProps = {
	isSelected: boolean;
	reevaluate?: boolean;
	showPreview: boolean;

	content: {
		id: string;
		preview: string;
		image1: string;
		image2: string;
		name: string;
		date: Date;
		lastModified: Date;
		cameraPair: number;
		xml: string;
		isDraft?: boolean;
	};

	actions: TableRowActions;
};

const TableRow = ({
	// actions,
	content,
	isSelected,
	actions,
	reevaluate = false,
	showPreview,
}: TableRowProps) => {
	const { t } = useTranslation();
	const { branding } = useBrandingStore();
	const { id, name, date, lastModified, preview, xml, isDraft } = content;
	const { deleteMeasurement, toggleSelection } = actions;
	const navigate = useNavigate();

	const [visible, setVisible] = useState<boolean>(false);

	const [referenceElement, setReferenceElement] =
		useState<HTMLButtonElement | null>(null);
	const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
		null,
	);
	const { styles, attributes } = usePopper(referenceElement, popperElement, {
		placement: branding !== "hoya" ? "left-start" : "top-end",
		modifiers: [
			{
				name: "offset",
				options: {
					offset: [0, 7.5],
				},
			},
		],
	});

	const measurementUrl = reevaluate
		? `/measurement/eyes/${id}/process/reevaluate`
		: `/measurement/eyes/${id}/process`;

	if (branding !== "hoya") {
		return (
			<div
				className={cn(
					"flex flex-col items-center gap-3 rounded-[10px] border border-quaternary-80 px-5 py-2.5",
				)}
			>
				<div className={cn("flex w-full items-center justify-between")}>
					<PlainCheckbox
						checked={isSelected ? "checked" : "unchecked"}
						setChecked={() => toggleSelection(!isSelected)}
						className="mr-3"
					/>
					<OutsideClickHandler
						onOutsideClick={() => setVisible(false)}
					>
						<TextButton
							icon={MoreVertical}
							color={"secondary"}
							onClick={() => setVisible(!visible)}
							forwardRef={setReferenceElement}
							className={cn(
								visible ? "bg-quaternary-100" : undefined,
								"!size-6 rotate-90",
							)}
						/>
						{visible && (
							<Dropdown
								forwardRef={setPopperElement}
								styles={styles.popper}
								attributes={{ ...attributes.popper }}
								className={cn(
									"rounded-[10px] p-0 shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)]",
								)}
							>
								<TextButton
									icon={Trash}
									color={"secondary"}
									fullWidth
									className={cn(
										"!justify-start border-b border-quaternary-100 p-4 [&>span]:font-normal [&>svg]:text-primary-100",
									)}
									onClick={deleteMeasurement}
								>
									{t("tableRow.deleteMeasurement")}
								</TextButton>
								<TextButton
									onClick={() =>
										navigate(`/history/${id}`, {
											state: { measurementData: content },
										})
									}
									icon={Details}
									color={"secondary"}
									fullWidth
									className={cn(
										"!justify-start border-b border-quaternary-100 [&>span]:font-normal [&>svg]:text-primary-100",
									)}
								>
									{t("tableRow.showDetails")}
								</TextButton>
								{xml && (
									<TextButton
										icon={OSDownload}
										color={"secondary"}
										fullWidth
										className={cn(
											"!justify-start [&>span]:font-normal [&>svg]:text-primary-100",
										)}
										onClick={() => {
											downloadXml(
												xml,
												name,
												format(date, "yyyy-MM-dd"),
											);
											setVisible(false);
										}}
									>
										{t("tableRow.downloadXml")}
									</TextButton>
								)}
							</Dropdown>
						)}
					</OutsideClickHandler>
				</div>
				{showPreview && (
					<img
						src={preview}
						alt={name}
						className={cn(
							"size-20 cursor-pointer rounded-full object-cover object-center",
						)}
						onClick={() =>
							navigate(measurementUrl, {
								state: { previousPage: "/history" },
							})
						}
						loading="lazy"
					/>
				)}
				<ButtonText type="bold">{name}</ButtonText>
				<hr className="w-full border-t-quaternary-80" />
				<BodyText
					type="normal"
					className="text-center text-quaternary-80"
				>
					{`${formatRelative(date, new Date())}`}
				</BodyText>
			</div>
		);
	}

	return (
		<div
			className={cn(
				"flex min-h-[70px] w-full flex-row gap-3 md:items-center",
			)}
		>
			<div className={cn("flex shrink-0 flex-row gap-3 md:items-center")}>
				<PlainCheckbox
					checked={isSelected ? "checked" : "unchecked"}
					setChecked={() => toggleSelection(!isSelected)}
					className="mr-3"
				/>
				{showPreview && (
					<img
						src={preview}
						alt={name}
						className="h-[70px] w-12 cursor-pointer object-cover"
						onClick={() =>
							navigate(measurementUrl, {
								state: { previousPage: "/history" },
							})
						}
						loading="lazy"
					/>
				)}
			</div>
			<div
				onClick={() =>
					navigate(measurementUrl, {
						state: { previousPage: "/history" },
					})
				}
				className={cn(
					"flex basis-full cursor-pointer flex-col items-start gap-3 md:min-h-[70px] md:flex-row md:items-center",
				)}
			>
				<ButtonText type="bold">{name}</ButtonText>
				{isDraft && <DraftTag className="select-none" />}
				<ButtonText type="normal" className="md:ml-auto">
					{`${formatRelative(
						lastModified,
						new Date(),
					)} / ${formatRelative(date, new Date())}`}
				</ButtonText>
			</div>
			<div className="ml-auto">
				<OutsideClickHandler onOutsideClick={() => setVisible(false)}>
					<TextButton
						icon={MoreVertical}
						color={"secondary"}
						onClick={() => setVisible(!visible)}
						forwardRef={setReferenceElement}
						className={visible ? "bg-quaternary-20" : undefined}
					/>
					{visible && (
						<Dropdown
							forwardRef={setPopperElement}
							styles={styles.popper}
							attributes={{ ...attributes.popper }}
						>
							<TextButton
								icon={IconDelete}
								color={"secondary"}
								fullWidth
								className={cn(
									"!justify-start [&>span]:font-normal",
								)}
								onClick={deleteMeasurement}
							>
								{t("tableRow.deleteMeasurement")}
							</TextButton>
							<TextButton
								onClick={() =>
									navigate(`/history/${id}`, {
										state: { measurementData: content },
									})
								}
								icon={PhotoLibrary}
								color={"secondary"}
								fullWidth
								className={cn(
									"!justify-start [&>span]:font-normal",
								)}
							>
								{t("tableRow.showDetails")}
							</TextButton>
							{xml && (
								<TextButton
									icon={Download}
									color={"secondary"}
									fullWidth
									className={cn(
										"!justify-start [&>span]:font-normal",
									)}
									onClick={() => {
										downloadXml(
											xml,
											name,
											format(date, "yyyy-MM-dd"),
										);
										setVisible(false);
									}}
								>
									{t("tableRow.downloadXml")}
								</TextButton>
							)}
						</Dropdown>
					)}
				</OutsideClickHandler>
			</div>
		</div>
	);
};

export default TableRow;
