import { useState, useEffect, useRef, useLayoutEffect, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
	FloatingButton,
	SolidButton,
	TextButton,
} from "../../components/buttons";
import {
	Close,
	Hand,
	LeftHand,
	OSArrowLeft,
	Photo,
	RightHand,
} from "../../components/icons";
import { ModalBase } from "../../components/modals";
import { BodyText, HeadingText } from "../../components/typography";
import {
	EyesCanvasController,
	CanvasErrors,
} from "../../components/measurement/eyes";
import {
	processMeasurement,
	getSettings,
	editorChange,
	editorPage1,
	shot,
	deleteMeasurement,
	listMeasurements,
	editorChangeNotification,
	calculate,
} from "../../util/api/api-store";
import { Option, newOption, newNone, newSome } from "../../util/result";
import EyesHandle1 from "../../assets/icons/svg/eyes_handle_1.svg";
import OSEyesHandle1 from "../../assets/icons/svg/os_eyes_handle_1.svg";
import EyesHandle2Right from "../../assets/icons/svg/eyes_handle_2_right.svg";
import OSEyesHandle2Right from "../../assets/icons/svg/os_eyes_handle_2_right.svg";
import EyesHandle2Left from "../../assets/icons/svg/eyes_handle_2_left.svg";
import OSEyesHandle2Left from "../../assets/icons/svg/os_eyes_handle_2_left.svg";
import {
	IrisPositions,
	ImageObjectOptions,
	HandleObjectOptions,
} from "../../util/drawing";
import {
	getAccessIdAsPromise,
	useBrandingStore,
	useSnackStore,
} from "../../store";
import deepEqual from "deep-equal";
import { cn, measurementOwnerHelper } from "../../util/helpers";

type UniversalRequest = {
	access_id: string;
	measurement: string;
};

const Eyes = () => {
	const { t } = useTranslation();
	const { branding } = useBrandingStore();
	const navigate = useNavigate();
	const { state } = useLocation();
	const [modalNewPhotosAlertOpen, setModalNewPhotosAlertOpen] =
		useState<boolean>(false);
	const [leftHand, setLeftHand] = useState<boolean>(false);
	const [modalLeaveOpen, setModalLeaveOpen] = useState<boolean>(false);

	const closeNewPhotosAlertModal = () => {
		setModalNewPhotosAlertOpen(false);
	};

	const canvasWrapperRef = useRef<HTMLDivElement>(null);

	const [imageObjects, setImageObjects] = useState<ImageObjectOptions>({
		topImage: newNone(),
		bottomImage: newNone(),
	});
	const [handleObjects, setHandleObjects] = useState<HandleObjectOptions>({
		handle1: newNone(),
		handle2Right: newNone(),
		handle2Left: newNone(),
	});

	const [canvasMaxSize, setCanvasMaxSize] = useState<{
		width: number;
		height: number;
	}>({
		width: window.innerWidth,
		height: window.innerHeight,
	});

	const [irisPositions, setIrisPositions] =
		useState<Option<IrisPositions>>(newNone());

	const measurementIdOpt = newOption(useParams().measurement);
	const methodIdOpt = newOption(useParams().method);
	const reevaluate = newOption(useParams().reevaluate);
	const method = methodIdOpt
		.map((id) => {
			switch (id) {
				case "process":
					return {
						...processMeasurement,
						setRequest: (
							req: UniversalRequest,
							shouldInvalidate?: boolean,
						) =>
							processMeasurement.setRequest(
								{
									...req,
									reevaluate: reevaluate
										.map((s) => s === "reevaluate")
										.unwrapOrDefault(false),
								},
								shouldInvalidate,
							),
					};
				case "shot":
					return {
						...shot,
						setRequest: (
							req: UniversalRequest,
							shouldInvalidate?: boolean,
						) =>
							shot.setRequest(
								{
									...req,
									action: "shoot",
								},
								shouldInvalidate,
							),
					};
				default:
					return {
						...editorPage1,
						setRequest: (
							req: UniversalRequest,
							shouldInvalidate?: boolean,
						) => editorPage1.setRequest(req, shouldInvalidate),
					};
			}
		})
		.expect("This should exist.");

	useEffect(() => {
		if (measurementIdOpt.kind === "some") {
			getAccessIdAsPromise().then((access_id) => {
				method.setRequest({
					access_id,
					measurement: measurementIdOpt.data,
				});
				method
					.fetchData()
					.then((resp) => {
						setIrisPositions(
							newSome({
								left: {
									x: resp.editable._R.iris.position[0],
									y1: resp.editable._R.iris.position[1],
									y2: resp.editable._R.iris.position[2],
								},
								right: {
									x: resp.editable._L.iris.position[0],
									y1: resp.editable._L.iris.position[1],
									y2: resp.editable._L.iris.position[2],
								},
							}),
						);
					})
					.catch((err) => {
						console.log(err);
						if (
							err.kind === "api-response-error" &&
							(err.responseCode === 423 ||
								err.responseCode === 470)
						) {
							closeMeasurement();
							useSnackStore
								.getState()
								.open(
									t("eyes.measurementCurrentlyEditing"),
									"warning",
								);
							navigate(
								state?.previousPage
									? `${state.previousPage}`
									: "/",
							);
						}
					});
			});
		}
		getSettings.setRequest(null);
		getSettings.fetchData();
	}, []);

	useEffect(() => {
		const measurementResult = method.getResult();
		if (
			measurementResult.kind === "ok" &&
			measurementIdOpt.kind === "some" &&
			measurementResult.data.measurement === measurementIdOpt.data
		) {
			const measurement = measurementResult.data;
			setImageObjects({ topImage: newNone(), bottomImage: newNone() });
			const topImage = new Image();
			topImage.addEventListener("load", () => {
				setImageObjects(({ bottomImage }) => ({
					topImage: newSome(topImage),
					bottomImage,
				}));
			});
			topImage.src =
				import.meta.env.VITE_MASTER_SERVER_HOST +
				measurement.images._CO;
			const bottomImage = new Image();
			bottomImage.addEventListener("load", () => {
				setImageObjects(({ topImage }) => ({
					bottomImage: newSome(bottomImage),
					topImage,
				}));
			});
			bottomImage.src =
				import.meta.env.VITE_MASTER_SERVER_HOST +
				measurement.images._CU;
		} else {
			setImageObjects({ topImage: newNone(), bottomImage: newNone() });
		}
	}, [method.useGetResult()]);

	useEffect(() => {
		const handle1 = new Image(
			branding !== "hoya" ? 30 : 40,
			branding !== "hoya" ? 30 : 40,
		);
		handle1.addEventListener("load", () => {
			setHandleObjects((handles) => ({
				...handles,
				handle1: newSome(handle1),
			}));
		});
		handle1.src = branding !== "hoya" ? OSEyesHandle1 : EyesHandle1;
		handle1.dataset.branding = branding;
		const handle2Right = new Image(
			branding !== "hoya" ? 30 : 48,
			branding !== "hoya" ? 30 : 24,
		);
		handle2Right.addEventListener("load", () => {
			setHandleObjects((handles) => ({
				...handles,
				handle2Right: newSome(handle2Right),
			}));
		});
		handle2Right.src =
			branding !== "hoya" ? OSEyesHandle2Right : EyesHandle2Right;
		handle2Right.dataset.branding = branding;
		const handle2Left = new Image(
			branding !== "hoya" ? 30 : 48,
			branding !== "hoya" ? 30 : 24,
		);
		handle2Left.addEventListener("load", () => {
			setHandleObjects((handles) => ({
				...handles,
				handle2Left: newSome(handle2Left),
			}));
		});
		handle2Left.src =
			branding !== "hoya" ? OSEyesHandle2Left : EyesHandle2Left;
		handle2Left.dataset.branding = branding;
	}, []);

	useLayoutEffect(() => {
		const canvasWrapperRefOpt = newOption(canvasWrapperRef.current);
		const canvasWrapperRefInner = canvasWrapperRefOpt.expect(
			"componentDidMount has already fired",
		);
		const updateResize = () =>
			setCanvasMaxSize({
				width: canvasWrapperRefInner.clientWidth,
				height: window.innerHeight,
			});
		window.addEventListener("resize", updateResize);
		return () => window.removeEventListener("resize", updateResize);
	});

	const measurementResult = method.useGetResult();
	const getSettingsResult = getSettings.useGetResult();

	const saveMeasurement = () => {
		if (irisPositions.kind === "some" && measurementResult.kind === "ok") {
			getAccessIdAsPromise().then((access_id) => {
				editorChange.setRequest({
					...measurementResult.data,
					access_id: access_id,
					editable: {
						_L: {
							...measurementResult.data.editable._L,
							iris: {
								changed: hasLMeasurementChanged,
								position: [
									irisPositions.data.right.x,
									irisPositions.data.right.y1,
									irisPositions.data.right.y2,
								],
							},
						},
						_R: {
							...measurementResult.data.editable._R,
							iris: {
								changed: hasRMeasurementChanged,
								position: [
									irisPositions.data.left.x,
									irisPositions.data.left.y1,
									irisPositions.data.left.y2,
								],
							},
						},
					},
				});
				editorChange.invalidate();
				editorPage1.invalidate();
				processMeasurement.invalidate();
				editorChange.fetchData().catch((err) => {
					if (
						err.kind === "api-response-error" &&
						err.responseCode === 423
					) {
						useSnackStore
							.getState()
							.open(
								t("eyes.measurementCannotBeOpened"),
								"warning",
							);
					}
				});
			});
		}
	};

	const hasLMeasurementChanged = useMemo(
		() =>
			measurementResult
				.map((res) =>
					irisPositions
						.map((pos) => {
							return !deepEqual(
								res.editable._L.iris.position,
								Object.values(pos.right),
							);
						})
						.unwrapOrDefault(false),
				)
				.unwrapOrDefault(false),
		[measurementResult, irisPositions],
	);

	const hasRMeasurementChanged = useMemo(
		() =>
			measurementResult
				.map((res) =>
					irisPositions
						.map((pos) => {
							return !deepEqual(
								res.editable._R.iris.position,
								Object.values(pos.left),
							);
						})
						.unwrapOrDefault(false),
				)
				.unwrapOrDefault(false),
		[measurementResult, irisPositions],
	);

	const closeMeasurement = () => {
		processMeasurement.invalidate();
		editorPage1.invalidate();
		listMeasurements.invalidate();
	};

	const notifyEdit = () => {
		if (measurementResult.happy) {
			return getAccessIdAsPromise().then((access_id) => {
				editorChangeNotification.setRequest({
					access_id,
					measurement: measurementResult.data.measurement,
				});
				editorChangeNotification.invalidate();
				return editorChangeNotification.fetchData();
			});
		}
	};

	measurementOwnerHelper(
		saveMeasurement,
		() => {
			closeMeasurement();
			navigate(state?.previousPage ? `${state.previousPage}` : "/");
		},
		measurementResult.asOption().map((resp) => resp.measurement),
	);

	const canShow =
		canvasWrapperRef.current !== undefined && // Sorry
		canvasWrapperRef.current !== null &&
		handleObjects.handle1.kind === "some" &&
		handleObjects.handle2Right.kind === "some" &&
		handleObjects.handle2Left.kind === "some" &&
		imageObjects.topImage.kind === "some" &&
		imageObjects.bottomImage.kind === "some" &&
		measurementResult.kind === "ok" &&
		getSettingsResult.kind === "ok";

	const delMeasurement = () => {
		if (measurementIdOpt.kind === "some") {
			getAccessIdAsPromise().then((access_id) => {
				deleteMeasurement.setRequest({
					measurement: measurementIdOpt.data,
					access_id,
				});
				deleteMeasurement.invalidate();
				deleteMeasurement.fetchData();
				listMeasurements.invalidate();
				processMeasurement.invalidate();
				editorPage1.invalidate();
			});
		}
	};

	const getFrameType = () => {
		return new Promise((resolve, reject) => {
			if (measurementIdOpt.kind === "some") {
				getAccessIdAsPromise().then((access_id) => {
					calculate.setRequest({
						access_id: access_id,
						measurement: measurementIdOpt.data,
					});
					calculate.invalidate();
					calculate.fetchData().then((res) => {
						resolve(res.frame_type);
					});
				});
			} else {
				resolve(undefined);
			}
		});
	};

	return (
		<>
			<div
				className={cn(
					"fixed top-0 z-10 w-full shadow-[0px_1px_0px_#e6e8e9] backdrop-blur before:absolute before:inset-0 before:bg-pure-white before:opacity-[0.88]",
				)}
			>
				<div
					className={cn(
						"relative grid grid-cols-[minmax(48px,1fr)_auto_1fr] items-center px-6",
						branding !== "hoya" ? "container h-[85px]" : "h-[72px]",
					)}
				>
					<TextButton
						color="primary"
						icon={branding !== "hoya" ? OSArrowLeft : Photo}
						iconClasses={
							branding !== "hoya"
								? "size-[30px] !rounded-[10px] border border-quaternary-80 p-[5px]"
								: undefined
						}
						onClick={() => {
							setModalNewPhotosAlertOpen(true);
						}}
						className={cn(
							"-ml-3 rounded-full md:rounded-none [&>span:not(.ripple)]:hidden md:[&>span:not(.ripple)]:inline-block",
						)}
					>
						{t("eyes.takeNew")}
					</TextButton>
					<BodyText
						type="bold16"
						className={cn("col-start-2 line-clamp-2 text-center")}
					>
						{measurementResult.kind === "ok"
							? measurementResult.data.order_id
							: ""}
					</BodyText>
					<div
						className={cn(
							"col-start-3 flex items-center justify-self-end",
							branding !== "hoya" ? "gap-5" : "gap-3 md:gap-6",
						)}
					>
						<TextButton
							color="secondary"
							// @todo az ikon nevek forditva vannak
							//icon={leftHand ? RightHand : LeftHand}
							icon={
								branding !== "hoya"
									? Hand
									: leftHand
										? RightHand
										: LeftHand
							}
							iconClasses={cn(
								branding !== "hoya" ? "size-[17px]" : undefined,
								branding !== "hoya" &&
									!leftHand &&
									"-scale-x-100",
							)}
							className={cn(
								branding !== "hoya" &&
									"h-fit !rounded-[10px] border border-quaternary-80 p-[5px]",
							)}
							onClick={() => setLeftHand(!leftHand)}
						/>
						<TextButton
							color="secondary"
							disabled={false}
							fullWidth={false}
							icon={Close}
							iconClasses={
								branding !== "hoya" ? "size-[17px]" : undefined
							}
							onClick={() => {
								setModalLeaveOpen(true);
							}}
							className={cn(
								"justify-self-end",
								branding !== "hoya" &&
									"h-fit !rounded-[10px] border border-quaternary-80 p-[5px]",
							)}
						/>
					</div>
				</div>
			</div>
			<div
				className={cn(
					"grid min-h-dvh items-center sm:hidden",
					branding !== "hoya" ? "pt-[85px]" : "pt-[72px]",
				)}
			>
				<div className="container text-center">
					<svg
						fill="none"
						width={48}
						height={48}
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 48 48"
						className="mx-auto mb-6"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M36 8h-2v4l-6-6 6-6v4h2c4.4 0 8 3.6 8 8v2h4l-6 6-6-6h4v-2c0-2.2-1.8-4-4-4ZM8 8h14v12h4V4H4v16h4V8ZM4 46h40V24H4v22Zm4-18h32v14H8V28Z"
							fill="#2D2926"
						/>
					</svg>
					<HeadingText h={4}>{t("eyes.toEditRotate")}</HeadingText>
				</div>
			</div>
			<div
				className={cn(
					branding !== "optiswiss" ? "pt-[72px]" : "pt-[85px]",
					"hidden min-h-dvh grid-cols-1 grid-rows-[1fr_auto] justify-items-center sm:grid",
				)}
			>
				<div
					className={cn(
						"flex h-full w-full max-w-[64rem] flex-col items-center justify-center place-self-center lg:h-auto",
					)}
					ref={canvasWrapperRef}
				>
					{canvasWrapperRef.current !== undefined && // Sorry
					canvasWrapperRef.current !== null &&
					handleObjects.handle1.kind === "some" &&
					handleObjects.handle2Right.kind === "some" &&
					handleObjects.handle2Left.kind === "some" &&
					imageObjects.topImage.kind === "some" &&
					imageObjects.bottomImage.kind === "some" &&
					measurementResult.kind === "ok" &&
					getSettingsResult.kind === "ok" ? (
						<EyesCanvasController
							alwaysUseLoupe={getSettingsResult.data.direct_loupe}
							leftHand={leftHand}
							maxWidth={canvasWrapperRef.current.clientWidth}
							maxHeight={
								canvasMaxSize.height >= 1024
									? canvasMaxSize.height - 144
									: canvasWrapperRef.current.clientWidth *
										(769 / 808)
							}
							imageObjects={{
								topImage: imageObjects.topImage.data,
								bottomImage: imageObjects.bottomImage.data,
							}}
							handleObjects={{
								handle1: handleObjects.handle1.data,
								handle2Left: handleObjects.handle2Left.data,
								handle2Right: handleObjects.handle2Right.data,
							}}
							irisRadii={{
								left: measurementResult.data.editable._R
									.iris_radius_pixels,
								right: measurementResult.data.editable._L
									.iris_radius_pixels,
							}}
							onIrisPositionsChange={(pos) => {
								setIrisPositions(newSome(pos));
								notifyEdit();
							}}
							startingIrisPositions={{
								left: {
									x: measurementResult.data.editable._R.iris
										.position[0],
									y1: measurementResult.data.editable._R.iris
										.position[1],
									y2: measurementResult.data.editable._R.iris
										.position[2],
								},
								right: {
									x: measurementResult.data.editable._L.iris
										.position[0],
									y1: measurementResult.data.editable._L.iris
										.position[1],
									y2: measurementResult.data.editable._L.iris
										.position[2],
								},
							}}
						/>
					) : (
						<CanvasErrors
							status={[
								{
									isActive:
										measurementResult.kind === "loading",
									message: t("eyes.waitLoadingDetails"),
								},
								{
									isActive:
										measurementResult.kind === "ok" &&
										(imageObjects.topImage.kind ===
											"none" ||
											imageObjects.bottomImage.kind ===
												"none"),
									message: t("eyes.waitLoadingPictures"),
								},
								{
									isActive:
										getSettingsResult.kind === "loading",
									message: t("eyes.waitLoadingSettings"),
								},
							]}
							errors={[
								{
									isActive:
										measurementResult.kind === "ok" &&
										measurementIdOpt.kind === "some" &&
										measurementResult.data.measurement !==
											measurementIdOpt.data,
									message: t("eyes.errorIdMismatch"),
								},
								{
									isActive:
										measurementResult.kind === "err" &&
										(measurementResult.error.kind !==
											"api-response-error" ||
											measurementResult.error
												.responseCode !== 423),
									message: t("eyes.errorUnexpectedDetails"),
								},
								{
									isActive: getSettingsResult.kind === "err",
									message: t("eyes.errorUnexpectedSettings"),
								},
								{
									isActive:
										measurementResult.kind === "err" &&
										measurementResult.error.kind ===
											"api-response-error" &&
										(measurementResult.error
											.responseCode === 423 ||
											measurementResult.error
												.responseCode === 470),
									message: t("eyes.errorAnotherUser"),
								},
								{
									isActive:
										measurementResult.kind === "err" &&
										(measurementResult.error.kind !==
											"api-response-error" ||
											!(
												measurementResult.error
													.responseCode === 423 ||
												measurementResult.error
													.responseCode === 470
											)),
									message: t("eyes.errorUnexpectedDetails"),
								},
							]}
						/>
					)}
				</div>
				<div
					className={cn(
						"relative w-full self-end shadow-[0px_-1px_0px_#e6e8e9] backdrop-blur before:absolute before:inset-0 before:bg-pure-white before:opacity-[0.88]",
					)}
				>
					<div
						className={cn(
							"container",
							branding !== "hoya" && "py-4",
						)}
					>
						<FloatingButton
							mx={false}
							num={2}
							col={branding !== "hoya" ? true : false}
							buttons={{
								left: {
									label: t("eyes.saveAsDraftAndQuit"),
									onClick: () => {
										saveMeasurement();
										closeMeasurement();
										useSnackStore.getState().open(
											t("eyes.savedAsDraft", {
												orderid:
													measurementResult.kind ===
													"ok"
														? measurementResult.data
																.order_id
														: "",
											}),
											"ok",
										);
										navigate(
											state?.previousPage
												? `${state.previousPage}`
												: "/",
										);
									},
									disabled: !canShow,
								},
								right: {
									label: t("eyes.next"),
									onClick: () => {
										saveMeasurement();
										navigate(
											`/measurement/frames/${measurementIdOpt.expect(
												"Route has been defined to carry this information",
											)}`,
											{ state: state },
										);
									},
									disabled: !canShow,
								},
							}}
						/>
					</div>
				</div>
			</div>
			<ModalBase
				title={t("eyes.takeNew") || undefined}
				small
				open={modalNewPhotosAlertOpen}
				close={closeNewPhotosAlertModal}
			>
				<div
					className={cn(
						branding !== "hoya" ? "px-12 py-11 text-center" : "p-6",
					)}
				>
					<p className={cn(branding !== "hoya" ? "mb-8" : "mb-6")}>
						{t("eyes.takeNewConfirm")}
					</p>
					<p className="text-right">
						<SolidButton
							color="primary"
							onClick={async () => {
								const frame_type = await getFrameType();
								saveMeasurement();
								closeMeasurement();
								delMeasurement();
								navigate("/measurement/start", {
									state: {
										...state,
										frame_type,
										order_id:
											measurementResult.kind === "ok"
												? measurementResult.data
														.order_id
												: "",
									},
								});
							}}
							fullWidth={branding !== "hoya"}
						>
							{t("eyes.takeNew")}
						</SolidButton>
					</p>
				</div>
			</ModalBase>
			<ModalBase
				title={t("eyes.leave") || undefined}
				open={modalLeaveOpen}
				close={() => {
					setModalLeaveOpen(false);
				}}
				small
			>
				<div
					className={cn(
						branding !== "hoya" ? "gap-10 px-12 py-10" : "p-6",
					)}
				>
					<p
						className={cn(
							branding !== "hoya" ? "mb-10 text-center" : "mb-6",
						)}
					>
						{hasLMeasurementChanged || hasRMeasurementChanged
							? t("eyes.leaveConfirmNoSave")
							: t("eyes.leaveConfirm")}
					</p>
					{hasLMeasurementChanged || hasRMeasurementChanged ? (
						<>
							<SolidButton
								color="primary"
								onClick={() => {
									saveMeasurement();
									closeMeasurement();
									useSnackStore.getState().open(
										t("eyes.savedAsDraft", {
											orderid:
												measurementResult.kind === "ok"
													? measurementResult.data
															.order_id
													: "",
										}),
										"ok",
									);
									navigate(
										state?.previousPage
											? `${state.previousPage}`
											: "/",
									);
								}}
								fullWidth
							>
								{t("eyes.saveAsDraft")}
							</SolidButton>
							<TextButton
								color="primary"
								className={cn("mt-4 text-primary-100")}
								onClick={() => {
									closeMeasurement();
									navigate(
										state?.previousPage
											? `${state.previousPage}`
											: "/",
									);
								}}
								fullWidth
							>
								{t("eyes.quitWithoutSave")}
							</TextButton>
						</>
					) : (
						<p className="text-right">
							<SolidButton
								color="primary"
								onClick={() => {
									closeMeasurement();
									navigate(
										state?.previousPage
											? `${state.previousPage}`
											: "/",
									);
								}}
								fullWidth={branding !== "hoya"}
							>
								{t("eyes.quit")}
							</SolidButton>
						</p>
					)}
				</div>
			</ModalBase>
		</>
	);
};

export default Eyes;
