import J from "joi";

export interface IRequestData {
	company_name: string;
	country_code: string;
	customer_number: string;
	my_name: string;
	shop_name: string;
}

export interface IResponseData {
	success: boolean;
}

export type IResponseError = null;

const responseDataShape = J.object({
	success: J.boolean(),
})
	.required()
	.unknown();

const uri = import.meta.env.VITE_MASTER_SERVER_HOST + "set_optician_id";

// eslint-disable-next-line
export function isValidResponse(resp: any): resp is IResponseData {
	const validationError = responseDataShape.validate(resp).error;
	return validationError === undefined;
}

export function isValidResponseError(respErr: any): respErr is IResponseError {
	return respErr === null;
}

export function request(req: IRequestData): Promise<Response> {
	return fetch(uri, {
		method: "POST",
		body: JSON.stringify(req),
	});
}
