import { SVGProps } from "react";
const RouteMaintenance = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			width="20"
			height="21"
			viewBox="0 0 20 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g clipPath="url(#clip0_1_3340)">
				<path
					d="M0.494207 17.9657C0.497757 17.5044 0.6441 17.0462 0.926323 16.719H0.929815L1.07626 16.5726L9.54518 8.10366L9.76768 7.88116L9.66333 7.58429C9.37897 6.77535 9.33586 5.91471 9.50092 5.03927C9.70811 4.08426 10.1628 3.18464 10.8938 2.4929L10.9045 2.48279L10.9146 2.47207C11.5274 1.81976 12.3008 1.37203 13.1573 1.17022L13.1573 1.17023L13.1606 1.16943C14.0304 0.958257 14.9389 0.959795 15.8024 1.20793L15.8024 1.20796L15.808 1.20951L16.3065 1.34646L16.3381 1.35515L16.3567 1.35771C16.3577 1.35913 16.3588 1.36089 16.36 1.36302C16.3623 1.36704 16.3643 1.37153 16.3658 1.3762C16.3674 1.38087 16.3682 1.38491 16.3685 1.38794C16.3686 1.38842 16.3687 1.38886 16.3687 1.38925L16.3637 1.40393L16.3584 1.42579C16.3556 1.43721 16.3528 1.44681 16.35 1.4547L16.3171 1.47406L14.0054 2.83259L13.8687 2.91291L13.8033 3.0573L13.1679 4.45965L13.025 4.77487L13.2697 5.01957L15.4883 7.23814L15.7323 7.48217L16.047 7.34055L17.3617 6.74893L17.5037 6.68503L17.5844 6.5518L19.036 4.15245L19.0464 4.13524L19.0538 4.12054C19.0548 4.12012 19.0562 4.11962 19.0581 4.11917C19.0676 4.1169 19.0812 4.11771 19.0958 4.12757L19.0958 4.12769L19.1056 4.13399C19.1224 4.14471 19.1382 4.1564 19.1517 4.16791C19.1533 4.16922 19.1548 4.1705 19.1562 4.17174L19.1703 4.2186L19.3054 4.66731C19.5533 5.57325 19.5542 6.47724 19.3472 7.33915C19.0923 8.17291 18.6334 9.00083 18.0246 9.60964C17.3182 10.316 16.4181 10.8044 15.4828 10.966L15.4676 10.9686L15.4527 10.9722C14.5974 11.1748 13.7389 11.1363 12.8721 10.8474L12.578 10.7494L12.3595 10.9691L3.94334 19.429C3.52415 19.8079 3.0267 20.0033 2.55936 19.9711L2.54218 19.9699H2.52495C2.01054 19.9699 1.51145 19.7901 1.11461 19.3933C0.722756 19.0014 0.53435 18.4955 0.494207 17.9657Z"
					fill="white"
					stroke="currentColor"
				/>
			</g>
			<defs>
				<clipPath id="clip0_1_3340">
					<rect
						width="20"
						height="19.9617"
						fill="white"
						transform="translate(0 0.519287)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default RouteMaintenance;
