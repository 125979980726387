import { SVGProps } from "react";

const HelpFAQ = (props: SVGProps<SVGSVGElement> & { title?: string }) => {
	return (
		<svg
			width="94"
			height="94"
			viewBox="0 0 94 94"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			{...props}
		>
			<g filter="url(#filter0_d_1_6549)">
				<circle cx="47" cy="43" r="43" fill="currentColor" />
				<g clipPath="url(#clip0_1_6549)">
					<path
						d="M40.0008 38.798C40.0008 37.9197 40.2853 37.0296 40.8547 36.1279C41.4238 35.2264 42.2545 34.4796 43.3465 33.8878C44.4384 33.2962 45.7125 33 47.1683 33C48.5215 33 49.7162 33.2476 50.7521 33.7422C51.7881 34.2371 52.5883 34.9096 53.153 35.7605C53.7175 36.6113 54 37.536 54 38.5347C54 39.3206 53.839 40.0097 53.5169 40.6013C53.1949 41.1931 52.812 41.704 52.369 42.134C51.9255 42.564 51.1301 43.2877 49.9822 44.3047C49.6648 44.5914 49.4103 44.8433 49.2192 45.0606C49.0279 45.2779 48.8854 45.4766 48.7921 45.657C48.6987 45.8374 48.6262 46.0176 48.5751 46.198C48.5238 46.3784 48.4467 46.6952 48.344 47.1481C48.1666 48.1099 47.6111 48.5905 46.6781 48.5905C46.1927 48.5905 45.7844 48.4335 45.4531 48.119C45.1216 47.8048 44.9562 47.3376 44.9562 46.7181C44.9562 45.9413 45.0774 45.2685 45.3201 44.6998C45.5628 44.1311 45.8845 43.6317 46.2859 43.2019C46.6871 42.7719 47.2284 42.2612 47.91 41.6691C48.5071 41.1514 48.939 40.7605 49.2048 40.4972C49.4706 40.2338 49.6948 39.9402 49.8769 39.6163C50.059 39.2926 50.1499 38.9415 50.1499 38.5621C50.1499 37.8225 49.872 37.1983 49.3168 36.6897C48.7613 36.1813 48.0451 35.9269 47.1678 35.9269C46.1411 35.9269 45.385 36.1833 44.8999 36.6966C44.4146 37.2098 44.0039 37.9657 43.668 38.9644C43.3506 40.0094 42.7486 40.5318 41.8621 40.5318C41.3393 40.5318 40.8983 40.3494 40.539 39.984C40.1795 39.6188 40 39.2234 40 38.798H40.0008ZM46.8324 54C46.2631 54 45.7662 53.8173 45.3414 53.4522C44.9166 53.0871 44.7045 52.5761 44.7045 51.9194C44.7045 51.3367 44.9097 50.8469 45.3206 50.4492C45.7312 50.0518 46.2351 49.8528 46.8324 49.8528C47.4298 49.8528 47.9149 50.0518 48.3163 50.4492C48.7174 50.8469 48.9182 51.337 48.9182 51.9194C48.9182 52.5667 48.7082 53.0754 48.2883 53.4451C47.8684 53.8148 47.3828 54 46.8324 54Z"
						fill="white"
					/>
					<path
						d="M52.6679 21.6743C60.8905 23.6163 67.3127 29.9512 69.257 38.0181C70.7267 44.1159 69.6621 49.9371 66.8893 54.6755C66.5403 55.2721 66.448 55.9804 66.6295 56.6448L67.7171 60.6224L68.254 62.5865C68.669 64.1035 67.2521 65.4917 65.7041 65.0851L63.6999 64.5589L59.4067 63.4233C58.666 63.2274 57.8795 63.3093 57.1931 63.6447C53.1953 65.5995 48.5347 66.4463 43.6287 65.7698C33.6138 64.3886 25.7003 56.6755 24.2492 46.8667C21.9126 31.0706 36.3396 17.8183 52.6679 21.6743ZM46.9588 63.8797C50.5346 63.8797 54.0615 62.9748 57.1582 61.2629L57.1723 61.2553C57.6577 60.9869 58.2313 60.9164 58.7696 61.0587L58.7851 61.0629L64.2601 62.5112L64.3574 62.5368C65.1443 62.7434 65.8642 62.0379 65.6533 61.2667L65.6272 61.1714L64.2463 56.121C64.0895 55.5468 64.1854 54.9343 64.5115 54.4329C66.633 51.1691 67.7545 47.3888 67.7545 43.5003C67.7545 31.5647 57.2308 21.9929 44.7995 23.2277C35.0253 24.1988 27.2638 31.8048 26.2732 41.3838C25.0132 53.5665 34.7803 63.8793 46.9592 63.8793L46.9588 63.8797Z"
						fill="white"
					/>
				</g>
			</g>
			<defs>
				<filter
					id="filter0_d_1_6549"
					x="0"
					y="0"
					width="94"
					height="94"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="4" />
					<feGaussianBlur stdDeviation="2" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
					/>
					<feBlend
						mode="normal"
						in2="BackgroundImageFix"
						result="effect1_dropShadow_1_6549"
					/>
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="effect1_dropShadow_1_6549"
						result="shape"
					/>
				</filter>
				<clipPath id="clip0_1_6549">
					<rect
						width="45.9184"
						height="45"
						fill="white"
						transform="translate(24 21)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default HelpFAQ;
