import { useTranslation } from "react-i18next";
import {
	addDays,
	startOfWeek,
	endOfWeek,
	startOfMonth,
	endOfMonth,
	startOfDay,
	endOfDay,
} from "date-fns";
import { useRef } from "react";
import DatePicker, {
	CalendarContainer,
	CalendarContainerProps,
	registerLocale,
} from "react-datepicker";
import { cn, useMediaQuery } from "../../util/helpers";
import i18n, { locales } from "../../util/i18n";
import { OutlineButton } from "../buttons";
import { ArrowLeft, OSSearch } from "../icons";
import { BodyText } from "../typography";
import { offset } from "@floating-ui/dom";
import { useBrandingStore } from "../../store";
import { cva } from "class-variance-authority";

// import "react-datepicker/dist/react-datepicker.css";

type TextFieldType = "default" | "alert" | "disabled";

type TextFieldProps = {
	type: TextFieldType;
	label: string;
	info?: string;
	clearable?: boolean;
	htmlFor: string;
	startDate: Date | null;
	endDate: Date | null;
	setValue?: (dates: [Date, Date]) => void;
	fullWidth?: boolean;
	placeholderText?: string;
	className?: string;
};

const Datepicker = ({
	label,
	type,
	info,
	htmlFor,
	startDate,
	endDate,
	setValue,
	// fullWidth,
	placeholderText,
	className,
}: TextFieldProps) => {
	const { t } = useTranslation();
	const datePickerRef = useRef<DatePicker>(null);
	const onChange = (dates: [Date, Date]) => {
		setValue &&
			setValue([
				dates[0] && startOfDay(dates[0]),
				dates[1] && endOfDay(dates[1]),
			]);
	};

	const matches = useMediaQuery("sm");

	registerLocale(
		i18n.language.split("-")[0],
		locales[i18n.language.split("-")[0]],
	);

	const { branding } = useBrandingStore();

	const variants = cva("", {
		variants: {
			brand: {
				hoya: "",
				optiswiss: "my-4 !pt-3",
			},
		},
		defaultVariants: {
			brand: "hoya",
		},
	});

	const CalendarMyContainer = ({
		className,
		children,
	}: CalendarContainerProps) => {
		return (
			<CalendarContainer
				className={cn(
					variants({
						brand: branding === "hoya" ? "hoya" : "optiswiss",
						className,
					}),
				)}
			>
				<div className="relative mb-6 before:pointer-events-none before:absolute before:inset-y-0 before:right-0 before:z-20 before:w-12 before:bg-gradient-to-l before:from-white before:md:hidden">
					<div
						className={cn(
							"flex w-full snap-x gap-2 overflow-x-auto",
							branding !== "hoya" &&
								"gap-5 border-b border-b-primary-dark-60 pb-3",
						)}
					>
						<OutlineButton
							color="primary"
							className={cn(
								"!min-w-fit shrink-0 snap-center",
								branding !== "hoya" && "rounded-md",
							)}
							onClick={() => {
								const today = new Date();
								onChange([startOfDay(today), endOfDay(today)]);
								datePickerRef.current?.setOpen(false);
							}}
						>
							{t("datepicker.today")}
						</OutlineButton>
						<OutlineButton
							color="primary"
							className={cn(
								"!min-w-fit shrink-0 snap-center",
								branding !== "hoya" && "rounded-md",
							)}
							onClick={() => {
								onChange([
									startOfDay(addDays(new Date(), -1)),
									endOfDay(addDays(new Date(), -1)),
								]);
								datePickerRef.current?.setOpen(false);
							}}
						>
							{t("datepicker.yesterday")}
						</OutlineButton>
						<OutlineButton
							color="primary"
							className={cn(
								"!min-w-fit shrink-0 snap-center",
								branding !== "hoya" && "rounded-md",
							)}
							onClick={() => {
								onChange([
									startOfWeek(new Date()),
									endOfWeek(new Date()),
								]);
								datePickerRef.current?.setOpen(false);
							}}
						>
							{t("datepicker.thisWeek")}
						</OutlineButton>
						<OutlineButton
							color="primary"
							className={cn(
								"!min-w-fit shrink-0 snap-center",
								branding !== "hoya" && "rounded-md",
							)}
							onClick={() => {
								onChange([
									startOfWeek(addDays(new Date(), -7)),
									endOfWeek(addDays(new Date(), -7)),
								]);
								datePickerRef.current?.setOpen(false);
							}}
						>
							{t("datepicker.lastWeek")}
						</OutlineButton>
						<OutlineButton
							color="primary"
							className={cn(
								"!min-w-fit shrink-0 snap-center",
								branding !== "hoya" && "rounded-md",
							)}
							onClick={() => {
								onChange([
									startOfMonth(new Date()),
									endOfMonth(new Date()),
								]);
								datePickerRef.current?.setOpen(false);
							}}
						>
							{t("datepicker.thisMonth")}
						</OutlineButton>
					</div>
				</div>
				<div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
					{children}
				</div>
			</CalendarContainer>
		);
	};

	return (
		<div
			className={cn(
				"flex flex-col",
				// fullWidth ? "w-full" : "max-w-[400px]",
				// "mb-6",
				className,
			)}
		>
			<label htmlFor={htmlFor}>
				<BodyText
					type={branding !== "hoya" ? "bold16" : "bold14"}
					className=""
				>
					{label}
				</BodyText>
			</label>
			<DatePicker
				ref={datePickerRef}
				icon={
					<OSSearch className="pointer-events-none absolute left-4 top-1/2 z-10 -translate-y-1/2" />
				}
				showIcon={branding !== "hoya"}
				selected={startDate}
				onChange={onChange}
				startDate={startDate}
				endDate={endDate}
				calendarContainer={CalendarMyContainer}
				locale={i18n.language.split("-")[0]}
				onFocus={(e) => e.target.blur()}
				renderCustomHeader={({
					monthDate,
					customHeaderCount,
					decreaseMonth,
					increaseMonth,
				}) => (
					<>
						<div className="react-datepicker__header--customtop place-items-center">
							<button
								type="button"
								aria-label={
									t("datepicker.previousMonth") || undefined
								}
								className={cn(
									"text-primary-100",
									customHeaderCount === 1
										? "md:hidden"
										: undefined,
								)}
								onClick={decreaseMonth}
							>
								{branding !== "hoya" ? (
									<svg
										width="11"
										height="18"
										viewBox="0 0 11 18"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M0.5 8.99512C0.5 9.16439 0.529297 9.32064 0.587891 9.46387C0.652995 9.6071 0.750651 9.74382 0.880859 9.87402L8.48828 17.3154C8.70964 17.5368 8.97656 17.6475 9.28906 17.6475C9.50391 17.6475 9.69596 17.5954 9.86523 17.4912C10.041 17.3936 10.181 17.2568 10.2852 17.0811C10.3893 16.9118 10.4414 16.723 10.4414 16.5146C10.4414 16.2021 10.321 15.9255 10.0801 15.6846L3.22461 8.99512L10.0801 2.30566C10.321 2.06478 10.4414 1.79134 10.4414 1.48535C10.4414 1.27051 10.3893 1.07845 10.2852 0.90918C10.181 0.739909 10.041 0.606445 9.86523 0.508789C9.69596 0.404622 9.50391 0.352539 9.28906 0.352539C8.97656 0.352539 8.70964 0.459961 8.48828 0.674805L0.880859 8.11621C0.750651 8.24642 0.652995 8.38314 0.587891 8.52637C0.529297 8.6696 0.5 8.82585 0.5 8.99512Z"
											fill="currentColor"
										/>
									</svg>
								) : (
									<ArrowLeft />
								)}
							</button>
							<span
								className={cn(
									"react-datepicker__current-month col-span-5 col-start-2",
								)}
							>
								{monthDate.toLocaleString(i18n.language, {
									month: "long",
									year: "numeric",
								})}
							</span>
							<button
								type="button"
								aria-label={
									t("datepicker.nextMonth") || undefined
								}
								className={cn(
									"text-primary-100",
									customHeaderCount === 0
										? "md:hidden"
										: undefined,
								)}
								onClick={increaseMonth}
							>
								{branding !== "hoya" ? (
									<svg
										width="11"
										height="18"
										viewBox="0 0 11 18"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M10.5 9.00488C10.5 8.83561 10.4707 8.67936 10.4121 8.53613C10.347 8.3929 10.2493 8.25618 10.1191 8.12598L2.51172 0.68457C2.29037 0.463216 2.02344 0.352538 1.71094 0.352538C1.4961 0.352538 1.30404 0.404622 1.13477 0.508788C0.958986 0.606444 0.819012 0.743163 0.714845 0.918944C0.610678 1.08822 0.558595 1.27702 0.558595 1.48535C0.558595 1.79785 0.679038 2.07454 0.919923 2.31543L7.77539 9.00488L0.919922 15.6943C0.679036 15.9352 0.558594 16.2087 0.558594 16.5146C0.558594 16.7295 0.610677 16.9215 0.714844 17.0908C0.819011 17.2601 0.958984 17.3936 1.13477 17.4912C1.30404 17.5954 1.49609 17.6475 1.71094 17.6475C2.02344 17.6475 2.29036 17.54 2.51172 17.3252L10.1191 9.88379C10.2493 9.75358 10.347 9.61686 10.4121 9.47363C10.4707 9.3304 10.5 9.17415 10.5 9.00488Z"
											fill="currentColor"
										/>
									</svg>
								) : (
									<ArrowLeft className="rotate-180" />
								)}
							</button>
						</div>
					</>
				)}
				selectsRange
				className={cn(
					"text-base",
					"relative w-full border p-3 leading-6",
					branding !== "hoya" && "mt-1 rounded-[10px] pl-10",
					type === "default" &&
						"border-secondary-100 text-primary-dark-100 [&.react-datepicker-ignore-onclickoutside]:border-primary-100 [&.react-datepicker-ignore-onclickoutside]:outline-none",
					type === "alert" &&
						"border-system-danger-100 text-primary-dark-100",
					type === "disabled" &&
						"border-secondary-40 bg-secondary-20 text-secondary-100",
				)}
				placeholderText={placeholderText}
				showPopperArrow={false}
				popperPlacement={"bottom-start"}
				popperModifiers={[offset(-9)]}
				monthsShown={matches ? 2 : 1}
				isClearable
				dateFormat={"P"}
				// open={true}
				// inline
			/>
			{info && (
				<label className="select-none">
					<BodyText
						className={cn(
							type === "default" && "text-primary-dark-60",
							type === "disabled" && "text-primary-dark-60",
							type === "alert" && "text-system-danger-100",
							"",
						)}
						type="mini"
					>
						{info}
					</BodyText>
				</label>
			)}
		</div>
	);
};
export default Datepicker;
