import { HeadingText } from "../typography";
import { useBrandingStore } from "../../store";
import { cn } from "../../util/helpers";

type SettingsFragmentProps = {
	title?: string;
	borderTop?: boolean;
	borderBottom?: boolean;
	className?: string;
	children: React.ReactNode;
};

const SettingsFragment = ({
	title,
	children,
	borderBottom,
	borderTop,
	className,
}: SettingsFragmentProps) => {
	const { branding } = useBrandingStore();

	return (
		<div
			className={cn(
				borderTop && "border-t",
				borderBottom && "border-b",
				"border-secondary-40",
				branding !== "hoya" ? "py-6" : "py-12",
				className,
			)}
		>
			{title && (
				<HeadingText
					h={5}
					className={cn(
						branding === "hoya" && "text-primary-dark-80",
						"mb-5 print:mb-1 print:text-black",
					)}
				>
					{title}
				</HeadingText>
			)}
			{children}
		</div>
	);
};

export default SettingsFragment;
