import { SVGProps } from "react";

const MeasurementLoading = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			width="149"
			height="145"
			viewBox="0 0 149 145"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g filter="url(#filter0_d_1_1753)">
				<circle cx="74.5" cy="70.5" r="70" fill="currentColor" />
				<g clipPath="url(#clip0_1_1753)">
					<path
						d="M67.4756 79.7912C67.4756 75.9684 70.6219 72.8682 74.5015 72.8682C78.3811 72.8682 81.5274 75.9684 81.5274 79.7912C81.5274 83.614 78.3811 86.7142 74.5015 86.7142C70.6219 86.7142 67.4756 83.614 67.4756 79.7912Z"
						fill="white"
					/>
					<path
						d="M82.6617 88.8168C83.2101 89.6351 82.9751 90.7375 82.1446 91.2778C79.8726 92.7538 77.2246 93.5289 74.4982 93.5289C66.8111 93.5289 60.5498 87.3655 60.5498 79.7909C60.5498 78.4168 60.7598 77.0581 61.164 75.755C61.4586 74.8194 62.4739 74.2914 63.4203 74.5816C64.3761 74.8657 64.912 75.8631 64.6174 76.8049C64.3166 77.7652 64.1599 78.7719 64.1599 79.7909C64.1599 85.4047 68.7979 89.9747 74.4982 89.9747C76.5195 89.9747 78.475 89.4004 80.1578 88.3104C80.9945 87.77 82.1133 87.9954 82.6585 88.8199L82.6617 88.8168Z"
						fill="white"
					/>
					<path
						d="M41.3713 60.998C40.7916 60.998 40.2244 60.7232 39.8734 60.2168L35.4172 53.7199C34.8594 52.9077 35.0756 51.8023 35.8998 51.2495C36.724 50.6999 37.8458 50.913 38.4068 51.7251L42.863 58.222C43.4208 59.0372 43.2046 60.1427 42.3773 60.6923C42.067 60.8992 41.716 60.998 41.3682 60.998H41.3713Z"
						fill="white"
					/>
					<path
						d="M51.6816 55.6535C50.9953 55.6535 50.3404 55.2644 50.0364 54.6097L46.4984 46.9394C46.0847 46.044 46.4858 44.991 47.3946 44.5865C48.3034 44.182 49.372 44.5772 49.7826 45.4696L53.3206 53.1399C53.7342 54.0354 53.3331 55.0884 52.4243 55.496C52.183 55.604 51.9292 55.6565 51.6785 55.6565L51.6816 55.6535Z"
						fill="white"
					/>
					<path
						d="M63.3954 52.3274C62.5587 52.3274 61.8066 51.7468 61.6311 50.9069L59.8167 42.2176C59.6161 41.2542 60.2428 40.3155 61.2206 40.1179C62.1983 39.9202 63.151 40.5378 63.3515 41.5012L65.166 50.1906C65.3666 51.154 64.7398 52.0927 63.7621 52.2903C63.6399 52.315 63.5176 52.3274 63.3954 52.3274Z"
						fill="white"
					/>
					<path
						d="M74.5013 51.3425C73.5048 51.3425 72.6963 50.5458 72.6963 49.5639V40.2786C72.6963 39.2967 73.5048 38.5 74.5013 38.5C75.4979 38.5 76.3064 39.2967 76.3064 40.2786V49.567C76.3064 50.5489 75.4979 51.3456 74.5013 51.3456V51.3425Z"
						fill="white"
					/>
					<path
						d="M107.629 60.9984C107.281 60.9984 106.93 60.8996 106.62 60.6927C105.796 60.143 105.576 59.0376 106.134 58.2224L110.59 51.7255C111.148 50.9133 112.27 50.6972 113.097 51.2499C113.921 51.7996 114.141 52.905 113.58 53.7202L109.124 60.2171C108.776 60.7267 108.205 60.9984 107.626 60.9984H107.629Z"
						fill="white"
					/>
					<path
						d="M97.3216 55.6536C97.0709 55.6536 96.8171 55.6011 96.5758 55.493C95.6701 55.0854 95.2659 54.0325 95.6795 53.137L99.2176 45.4667C99.6312 44.5743 100.703 44.179 101.605 44.5835C102.511 44.9911 102.915 46.0441 102.502 46.9365L98.9637 54.6068C98.6597 55.2614 98.0048 55.6505 97.3185 55.6505L97.3216 55.6536Z"
						fill="white"
					/>
					<path
						d="M85.6077 52.3274C85.4887 52.3274 85.3633 52.315 85.2411 52.2903C84.2634 52.0927 83.6366 51.1509 83.8372 50.1906L85.6548 41.5012C85.8553 40.5378 86.808 39.9202 87.7857 40.1179C88.7635 40.3155 89.3902 41.2573 89.1896 42.2176L87.3721 50.9069C87.1966 51.7468 86.4445 52.3274 85.6077 52.3274Z"
						fill="white"
					/>
					<path
						d="M112.401 64.5771C111.985 64.5771 111.565 64.435 111.223 64.1479C101.634 56.0082 88.2464 51.3424 74.4986 51.3424H74.4014C60.6974 51.364 47.3507 56.0298 37.7802 64.1479C37.025 64.7901 35.8843 64.7037 35.2356 63.9595C34.5838 63.2153 34.6715 62.0944 35.4267 61.4521C45.6397 52.7875 59.8451 47.8068 74.3983 47.7852H74.4986C89.0988 47.7852 103.345 52.7659 113.58 61.4521C114.335 62.0913 114.42 63.2153 113.771 63.9595C113.414 64.3671 112.909 64.5771 112.401 64.5771Z"
						fill="white"
					/>
					<path
						d="M82.1446 91.2778C79.8726 92.7538 77.2246 93.5289 74.4982 93.5289C66.8111 93.5289 60.5498 87.3655 60.5498 79.7909C60.5498 78.4168 60.7598 77.0581 61.164 75.755C61.4586 74.8194 62.4739 74.2914 63.4203 74.5816C64.3761 74.8657 64.912 75.8631 64.6174 76.8049C64.3166 77.7652 64.1599 78.7719 64.1599 79.7909C64.1599 85.4047 68.7979 89.9747 74.4982 89.9747C76.5195 89.9747 78.475 89.4004 80.1578 88.3104C80.9945 87.77 82.1133 87.9954 82.6585 88.8199C83.207 89.6382 82.9719 90.7405 82.1415 91.2809L82.1446 91.2778Z"
						fill="white"
					/>
					<path
						d="M81.5274 79.7912C81.5274 83.614 78.3811 86.7142 74.5015 86.7142C70.6219 86.7142 67.4756 83.614 67.4756 79.7912C67.4756 75.9684 70.6219 72.8682 74.5015 72.8682C78.3811 72.8682 81.5274 75.9684 81.5274 79.7912Z"
						fill="white"
					/>
					<path
						d="M74.4107 56.086C55.2038 56.1169 38.0684 65.6924 31.5 79.7948C38.0872 93.9311 55.2822 103.504 74.5016 103.504H74.5924C93.7993 103.473 110.935 93.891 117.503 79.7948C110.903 65.6307 93.6646 56.0582 74.4138 56.086H74.4107ZM74.5016 97.6798C64.4735 97.6798 56.3476 89.6729 56.3476 79.7917C56.3476 69.9105 64.4735 61.8974 74.5016 61.8974C84.5296 61.8974 92.6555 69.9105 92.6555 79.7917C92.6555 89.6729 84.5296 97.6798 74.5016 97.6798Z"
						fill="white"
					/>
				</g>
			</g>
			<defs>
				<filter
					id="filter0_d_1_1753"
					x="0.5"
					y="0.5"
					width="148"
					height="148"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="4" />
					<feGaussianBlur stdDeviation="2" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
					/>
					<feBlend
						mode="normal"
						in2="BackgroundImageFix"
						result="effect1_dropShadow_1_1753"
					/>
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="effect1_dropShadow_1_1753"
						result="shape"
					/>
				</filter>
				<clipPath id="clip0_1_1753">
					<rect
						width="86"
						height="65"
						fill="white"
						transform="translate(31.5 38.5)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default MeasurementLoading;
