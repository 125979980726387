import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	OutlineButton,
	SolidButton,
	TextButton,
} from "../../components/buttons";
import {
	SettingsConnectivityStatus,
	SettingsFragment,
} from "../../components/fragments";
import { CustomSelect, Switch, TextField } from "../../components/input";
import { SelectOption } from "../../components/input/CustomSelect";
import { ModalBase, ModalLoading } from "../../components/modals";
import { InfoPanel } from "../../components/panels";
import { BodyText, HeadingText } from "../../components/typography";
import {
	apChannelAutoconfig,
	getCommSettings,
	getInternetConnectionState,
	getSSIDs,
	setApPassword,
	setAutoconfigApChannel,
	setVPN,
	wifiConnect,
	wifiDisconnect,
	testServerPresence,
} from "../../util/api/api-store";
import { Close, Done, Dot } from "../../components/icons";
import { useNavigate } from "react-router-dom";
import { cn } from "../../util/helpers";
import { useBrandingStore } from "../../store";

type ApPasswordChangeProcess = {
	password: string;
	started: boolean;
	errorOccured: boolean;
	finished: boolean;
};

const Wifi = () => {
	const { t } = useTranslation();
	const { branding } = useBrandingStore();
	const navigate = useNavigate();
	const [ssdisOptions, setSsdisOptions] = useState<SelectOption[]>([]);
	const [selectedSsid, setSelectedSsid] = useState<string>("");
	const [isConnected, setIsConnected] = useState<boolean>(false);
	const [isSubmited, setIsSubmited] = useState<boolean>(false);
	const [isConnectingFailed, setConnectingFailed] = useState<boolean>(false);
	const [modalPasswordOpen, setModalPasswordOpen] = useState<boolean>(false);
	const getCommSettingsResponse = getCommSettings.useGetResult();
	const [hasLeftApPasswordField, setHasLeftApPasswordField] =
		useState<boolean>(false);
	const [hasEthernet, setHasEthernet] = useState<boolean>(false);
	const [apPasswordChangeProcess, setApPasswrodChangeProcess] =
		useState<ApPasswordChangeProcess>({
			password: "",
			started: false,
			errorOccured: false,
			finished: false,
		});
	const [formData, setFormData] = useState({
		inet_connected: false,
		update_enabled: false,
		unattended_update: false, //@todo ????
		autoconfig_ap_channel: false,
		autoconfig_ap_channel_at_start: false,
		ap_channel: 0,
		inet_config: {
			SSID: "",
			key: "",
		},
		vpn_on: false,
	});

	const [isRefreshing, setIsRefreshing] = useState(false);

	const apPasswordValidty = [
		{
			valid: /[a-z]/.test(apPasswordChangeProcess.password),
			message: t("wifi.lowercaseMessage"),
		},
		{
			valid: /[A-Z]/.test(apPasswordChangeProcess.password),
			message: t("wifi.uppercaseMessage"),
		},
		{
			valid: /[0-9]/.test(apPasswordChangeProcess.password),
			message: t("wifi.numberMessage"),
		},
		{
			valid:
				/^[a-zA-Z0-9!$%*+,-./:;_]*$/.test(
					apPasswordChangeProcess.password,
				) && /[!$%*+,-./:;_]/.test(apPasswordChangeProcess.password),
			message: t("wifi.specialCharacterMessage"),
		},
		{
			valid:
				apPasswordChangeProcess.password.length >= 8 &&
				apPasswordChangeProcess.password.length <= 30,
			message: t("wifi.lengthMessage"),
		},
	];

	const commSettings = getCommSettingsResponse.unwrapOrDefault({
		SSIDs: [],
		admin_mode: false,
		ap_channel: 0,
		autoconfig_ap_channel: false,
		autoconfig_ap_channel_at_start: false,
		inet_config: {
			SSID: "",
			address: "",
			encryption: "",
		},
		inet_connected: false,
		update_enabled: false,
		vpn_on: false,
	});

	const refresh = () => {
		setIsRefreshing(true);
		testServerPresence.setRequest(null, true);
		testServerPresence.invalidate();
		testServerPresence
			.fetchData()
			.then((resp) => {
				if (resp.success) {
					navigate(0);
				}
			})
			.finally(() => {
				setIsRefreshing(false);
			});
	};

	const setApChannelAutoconfig = () => {
		setIsSubmited(true);
		apChannelAutoconfig.setRequest({ action: "autoconfig" });
		apChannelAutoconfig.fetchData().then((resp) => {
			setFormData({
				...formData,
				ap_channel: Number(resp.ap_channel),
			});
			setIsSubmited(false);
		});
	};

	const toggleSetAutoconfigApChannel = (data: {
		autoconfig_ap_channel: boolean;
		autoconfig_ap_channel_at_start: boolean;
	}) => {
		setIsSubmited(true);
		setAutoconfigApChannel.setRequest({
			autoconfig_ap_channel: data.autoconfig_ap_channel,
			autoconfig_ap_channel_at_start: data.autoconfig_ap_channel_at_start,
		});
		setAutoconfigApChannel.fetchData().then((resp) => {
			setFormData({
				...formData,
				ap_channel: Number(resp.ap_channel),
				autoconfig_ap_channel: data.autoconfig_ap_channel,
				autoconfig_ap_channel_at_start:
					data.autoconfig_ap_channel_at_start,
			});
			setIsSubmited(false);
		});
	};

	const connect = () => {
		setIsSubmited(true);
		setConnectingFailed(false);
		wifiConnect.setRequest(
			{
				// inet_connected: formData.inet_connected,
				// update_enabled: formData.update_enabled,
				// unattended_update: false,//@todo ????
				// autoconfig_ap_channel: formData.autoconfig_ap_channel,
				// autoconfig_ap_channel_at_start: formData.autoconfig_ap_channel_at_start,
				// ap_channel: formData.ap_channel,
				inet_config: {
					SSID: formData.inet_config.SSID,
					key: formData.inet_config.key,
					encryption: "psk2",
				},
			},
			true,
		);
		wifiConnect.invalidate();
		wifiConnect
			.fetchData()
			.then((resp) => {
				if (resp.success) {
					setModalPasswordOpen(false);
					getCommSettings.invalidate();
					setWifi();
				} else {
					setConnectingFailed(true);
				}
			})
			.catch((error) => console.error(error))
			.finally(() => setIsSubmited(false));
	};

	const disconnect = () => {
		setIsSubmited(true);
		wifiDisconnect.setRequest(null, true);
		wifiDisconnect.invalidate();
		wifiDisconnect
			.fetchData()
			.then(() => {
				setSelectedSsid("");
				setIsConnected(false);

				getCommSettings.invalidate();
				setWifi();
			})
			.finally(() => setIsSubmited(false));
	};

	const setWifi = () => {
		getCommSettings.fetchData().then((resp) => {
			const ssids = resp.SSIDs
				? resp.SSIDs.map((e) => ({ value: e, label: e }))
				: [];
			setSsdisOptions(ssids);

			if (resp.inet_connected) {
				setSelectedSsid(resp.inet_config?.SSID);
				setIsConnected(true);
			}

			setFormData({
				inet_connected: resp.inet_connected,
				update_enabled: resp.update_enabled,
				unattended_update: false, //@todo ????
				autoconfig_ap_channel: resp.autoconfig_ap_channel,
				autoconfig_ap_channel_at_start:
					resp.autoconfig_ap_channel_at_start,
				ap_channel: resp.ap_channel,
				inet_config: {
					SSID: resp.inet_config?.SSID ? resp.inet_config.SSID : "",
					key: "",
				},
				vpn_on: resp.vpn_on,
			});
		});
	};

	const closePasswordModal = () => {
		setModalPasswordOpen(false);
		setSelectedSsid("");
		setIsSubmited(false);
		setConnectingFailed(false);
	};

	const changeApPassword = () => {
		setApPasswrodChangeProcess((prevState) => ({
			...prevState,
			started: true,
		}));
		setApPassword.setRequest({
			ap_password: apPasswordChangeProcess.password,
		});

		const timeout = new Promise<never>((resolve) => {
			setTimeout(resolve, 15000, { success: true });
		});

		Promise.race([timeout, setApPassword.fetchData()])
			.then((resp) => {
				console.log(resp);
				setApPasswrodChangeProcess((prevState) => ({
					...prevState,
					errorOccured: !resp.success,
					finished: resp.success,
				}));
			})
			.catch((error) => {
				console.error(error);
				setApPasswrodChangeProcess((prevState) => ({
					...prevState,
					errorOccured: true,
				}));
			});
	};

	useEffect(() => {
		getCommSettings.setRequest(null);
		setWifi();
	}, []);

	useEffect(() => {
		getSSIDs.setRequest(null);
		getSSIDs.fetchData().then((resp) => {
			const ssids = resp.SSIDs
				? resp.SSIDs.map((e) => ({ value: e, label: e }))
				: [];
			setSsdisOptions(ssids);
		});
	}, []);

	useEffect(() => {
		getInternetConnectionState.setRequest(null);
		getInternetConnectionState
			.fetchData()
			.then((resp) => {
				setHasEthernet(resp.internet_connected);
			})
			.catch(() => {
				setHasEthernet(false);
			});
	}, []);

	return (
		<>
			<div>
				<HeadingText
					h={4}
					className={cn(branding !== "hoya" && "py-2 !text-[26px]")}
				>
					{t("settings.wifi")}
				</HeadingText>
				{commSettings.admin_mode && (
					<InfoPanel
						type="default"
						title={t("wifi.noPermissionMessage")}
						message={t("wifi.noPermissionDescription") || undefined}
						className={cn(
							branding !== "hoya" ? "mt-5 py-8" : "mt-12",
						)}
					/>
				)}
				<SettingsFragment
					title={t("wifi.connectionToLocalNetwork") || undefined}
					borderBottom
				>
					<div className={cn("flex flex-col gap-8")}>
						<p className={cn("text-primary-dark-80")}>
							{t("wifi.canConnect")}
						</p>
						{isConnected ? (
							<SettingsConnectivityStatus type="success">
								<strong>
									{hasEthernet
										? t("wifi.connectedToWifi")
										: t("wifi.connectedToWifiNoInternet")}
								</strong>
							</SettingsConnectivityStatus>
						) : (
							<SettingsConnectivityStatus type="warning">
								<strong>{t("wifi.notConnected")}</strong>
							</SettingsConnectivityStatus>
						)}
						{!isConnected && hasEthernet && (
							<InfoPanel
								type="info"
								title={t("wifi.connectedByCable")}
								message={
									t("wifi.connectedByCableDescription") ||
									undefined
								}
							/>
						)}
						<div className={cn(branding !== "hoya" && "pb-2.5")}>
							<CustomSelect
								label={t("wifi.network")}
								fullWidth
								value={selectedSsid}
								defaultLabel={
									t("wifi.chooseNetwork") || undefined
								}
								setValue={(v) => {
									// setSelectedSsid(v);
									setFormData({
										...formData,
										inet_config: {
											SSID: v,
											key: "",
										},
									});
									setModalPasswordOpen(true);
								}}
								disabled={commSettings.admin_mode}
								options={ssdisOptions}
								menuOpened={() => {
									getSSIDs.invalidate();
									getSSIDs.fetchData().then((resp) => {
										const ssids = resp.SSIDs
											? resp.SSIDs.map((e) => ({
													value: e,
													label: e,
												}))
											: [];
										setSsdisOptions(ssids);
									});
								}}
							/>
						</div>

						{isConnected && (
							<p
								className={cn(
									"text-right",
									branding !== "hoya" && "-mt-5",
								)}
							>
								<SolidButton
									color="primary"
									onClick={disconnect}
									disabled={
										isSubmited || commSettings.admin_mode
									}
								>
									{t("settings.disconnect")}
								</SolidButton>
							</p>
						)}
					</div>
				</SettingsFragment>
				<SettingsFragment
					title={t("wifi.connectionToMaster") || undefined}
					borderBottom
					className={cn(branding !== "hoya" && "mt-4")}
				>
					<div className={cn("flex flex-col gap-8")}>
						<BodyText
							type="normal"
							className="text-primary-dark-80"
						>
							{t("wifi.changePasswordDescription")}
						</BodyText>
						<form
							onSubmit={(e) => {
								e.preventDefault();
								changeApPassword();
							}}
							className={cn("flex flex-col gap-8")}
						>
							<div
								className={cn(
									branding !== "hoya" && "pb-2.5 pt-4",
								)}
							>
								<TextField
									type={
										!commSettings.admin_mode
											? hasLeftApPasswordField &&
												!apPasswordValidty.every(
													(v) => v.valid,
												)
												? "alert"
												: "default"
											: "disabled"
									}
									value={apPasswordChangeProcess.password}
									setValue={(newVal) =>
										setApPasswrodChangeProcess(
											(prevState) => ({
												...prevState,
												password: newVal,
											}),
										)
									}
									htmlFor="inputmodal"
									label={t("settings.newPassword")}
									showPasswordButton={true}
									fieldOptions={{
										onBlur: () =>
											setHasLeftApPasswordField(
												apPasswordChangeProcess.password
													.length > 0,
											),
										type: "password",
									}}
									fullWidth
								/>
							</div>
							{branding === "hoya" &&
								apPasswordChangeProcess.password.length > 0 && (
									<div>
										<BodyText type={"normal"}>
											{t("wifi.passwordRequirements")}
										</BodyText>
										{apPasswordValidty.map((v, index) => (
											<div
												key={index}
												className={cn(
													"flex flex-row",
													v.valid
														? "text-system-success-100"
														: hasLeftApPasswordField
															? "text-system-danger-100"
															: "text-primary-dark-60",
												)}
											>
												{v.valid ? (
													<Done
														className="m-[6px]"
														width={12}
														height={12}
													/>
												) : hasLeftApPasswordField ? (
													<Close
														className="m-[6px]"
														width={12}
														height={12}
													/>
												) : (
													<Dot
														className="m-[6px]"
														width={12}
														height={12}
													/>
												)}
												<BodyText
													type={"normal"}
													className="flex-auto"
												>
													{v.message}
												</BodyText>
											</div>
										))}
									</div>
								)}
							<p className="text-right">
								<OutlineButton
									color="primary"
									submit={true}
									disabled={
										isSubmited ||
										commSettings.admin_mode ||
										!apPasswordValidty.every((v) => v.valid)
									}
									className={cn(
										branding !== "hoya" &&
											"mb-2.5 [&>span]:font-normal",
									)}
								>
									{t("settings.changePasswordButton")}
								</OutlineButton>
							</p>
						</form>
					</div>
				</SettingsFragment>
				<SettingsFragment
					title={t("wifi.settings") || undefined}
					className={cn(branding !== "hoya" && "mt-4 pb-12")}
				>
					<div className={cn("flex flex-col gap-4")}>
						<Switch
							label={t("settings.vpnOn")}
							value={formData.vpn_on}
							disabled={isSubmited || commSettings.admin_mode}
							setValue={(v) => {
								setIsSubmited(true);
								setVPN.setRequest({ vpn_on: v });
								setVPN.fetchData().then(() => {
									setFormData({
										...formData,
										vpn_on: v,
									});
									setIsSubmited(false);
								});
							}}
							info={t("wifi.vpnDescription") || undefined}
						/>
						<div
							className={cn(
								"relative flex flex-col items-start justify-between gap-y-3 md:flex-row",
							)}
						>
							<Switch
								label={t(
									"settings.automaticChannelOptimization",
								)}
								value={formData.autoconfig_ap_channel}
								disabled={isSubmited || commSettings.admin_mode}
								setValue={(v) => {
									toggleSetAutoconfigApChannel({
										autoconfig_ap_channel: v,
										autoconfig_ap_channel_at_start:
											formData.autoconfig_ap_channel_at_start,
									});
								}}
								info={
									t("wifi.channelOptimizationDescription") ||
									undefined
								}
								className={cn(branding !== "hoya" && "w-full")}
							/>
							<BodyText
								type="normal"
								className={cn(
									branding !== "hoya"
										? "absolute right-16 top-1 text-quaternary-80"
										: "ml-12 shrink-0 text-primary-dark-80 md:ml-0",
								)}
							>
								{`${t("settings.channel")} ${
									formData.ap_channel
								}`}
							</BodyText>
						</div>
						<div
							className={cn(
								"relative flex flex-col items-start justify-between gap-y-3 md:flex-row",
							)}
						>
							<Switch
								label={t(
									"settings.automaticChannelOptimizationAutomatic",
								)}
								value={formData.autoconfig_ap_channel_at_start}
								disabled={isSubmited || commSettings.admin_mode}
								setValue={(v) => {
									toggleSetAutoconfigApChannel({
										autoconfig_ap_channel:
											formData.autoconfig_ap_channel,
										autoconfig_ap_channel_at_start: v,
									});
								}}
								info={
									t(
										"wifi.automaticChannelOptimizationDescription",
									) || undefined
								}
								className={cn(branding !== "hoya" && "w-full")}
							/>
							<TextButton
								color="primary"
								ignorePadding
								disableRipple
								className={cn(
									branding !== "hoya"
										? "!absolute right-16 top-1"
										: "ml-12 md:ml-0",
								)}
								onClick={setApChannelAutoconfig}
								disabled={
									!formData.autoconfig_ap_channel_at_start ||
									isSubmited ||
									commSettings.admin_mode
								}
							>
								{t(
									"settings.automaticChannelOptimizationAtStart",
								)}
							</TextButton>
						</div>
					</div>
				</SettingsFragment>
			</div>
			<ModalBase
				disableHeader
				open={
					apPasswordChangeProcess.started &&
					!apPasswordChangeProcess.errorOccured
				}
				small
				close={() => undefined}
			>
				<ModalLoading
					title={t("wifi.changingPassword")}
					text={t("wifi.changingPasswordDescription")}
				/>
			</ModalBase>
			<ModalBase
				title={t("wifi.failedToChangePassword") || undefined}
				open={apPasswordChangeProcess.errorOccured}
				small
				close={() =>
					setApPasswrodChangeProcess({
						...apPasswordChangeProcess,
						started: false,
						errorOccured: false,
					})
				}
			>
				<BodyText
					className={cn(
						branding !== "hoya"
							? "px-12 py-11 text-center"
							: "px-6 py-5",
					)}
					type={"normal"}
				>
					{t("settings.errorChangingWifiPassword")}
				</BodyText>
			</ModalBase>
			<ModalBase
				title={t("wifi.successfullyChangedPassword") || undefined}
				disableClose={true}
				open={apPasswordChangeProcess.finished}
				close={() => undefined}
			>
				<div
					className={cn(
						branding !== "hoya" ? "px-12 py-11" : "px-6 py-5",
					)}
				>
					<HeadingText h={5} className="mb-3">
						{t("wifi.followSteps")}
					</HeadingText>
					<ol className="pl-4" style={{ listStyle: "auto" }}>
						<li>
							<BodyText type={"normal"}>
								{t("wifi.goToWifi")}
							</BodyText>
						</li>
						<li>
							<BodyText type={"normal"}>
								{t("wifi.connectWithNewPassword")}
							</BodyText>
						</li>
						<li>
							<BodyText type={"normal"}>
								{t("wifi.refreshWebPage")}
							</BodyText>
						</li>
					</ol>
					<p
						className={cn(
							"text-right",
							branding !== "hoya" ? "mt-8" : "mt-6",
						)}
					>
						<SolidButton
							color="primary"
							onClick={refresh}
							loading={isRefreshing}
						>
							{t("factoryReset.refresh")}
						</SolidButton>
					</p>
				</div>
			</ModalBase>
			<ModalBase
				title={
					isConnectingFailed
						? t("wifi.failedConnectingToWifi", {
								ssid: formData.inet_config?.SSID,
							}) || undefined
						: t("wifi.connectingToWifi", {
								ssid: formData.inet_config?.SSID,
							}) || undefined
				}
				open={modalPasswordOpen}
				close={closePasswordModal}
				small
			>
				<form
					onSubmit={(e) => {
						e.preventDefault();
						connect();
					}}
					className={cn(branding !== "hoya" ? "px-12 py-11" : "p-6")}
				>
					<TextField
						type="default"
						htmlFor="inputmodal"
						label={t("lensSupplierSettings.customerPassword")}
						showPasswordButton={true}
						fieldOptions={{ type: "password", autoFocus: true }}
						fullWidth
						value={formData.inet_config.key}
						setValue={(v) => {
							setFormData({
								...formData,
								inet_config: {
									SSID: formData.inet_config.SSID,
									key: v,
								},
							});
						}}
					/>
					<p
						className={cn(
							branding !== "hoya"
								? "mt-12 w-full"
								: "mt-8 text-right",
						)}
					>
						<SolidButton
							color="primary"
							submit={true}
							loading={isSubmited}
							fullWidth={branding !== "hoya"}
						>
							{isSubmited
								? t("wifi.connecting")
								: t("settings.connect")}
						</SolidButton>
					</p>
					{isConnectingFailed && (
						<InfoPanel
							type="info"
							title={t("wifi.pleaseCheck")}
							message={
								t("wifi.pleaseCheckDescription") || undefined
							}
							className={cn(
								branding !== "hoya" ? "mt-10 py-8" : "mt-6",
							)}
						/>
					)}
				</form>
			</ModalBase>
		</>
	);
};

export default Wifi;
