import { cn } from "../../util/helpers";
import { Loading } from "../icons";
import { ButtonText, HeadingText } from "../typography";

type ModalLoadingParams = {
	title: string;
	text: string;
};

// TODO primary gray
const ModalLoading = ({ title, text }: ModalLoadingParams) => {
	return (
		<div className={cn("p-6 md:py-20")}>
			<div className={cn("flex flex-col items-center gap-3")}>
				<Loading
					height={48}
					width={48}
					className={cn("animate-spin-slow fill-primary-dark-100")}
				/>
				<HeadingText h={4}>{title}</HeadingText>
				<ButtonText
					type="normal"
					className={cn("text-center text-primary-dark-60")}
				>
					{text}
				</ButtonText>
			</div>
		</div>
	);
};

export default ModalLoading;
