import { SVGProps } from "react";

const OSZoomIn = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			width="20"
			height="18"
			viewBox="0 0 20 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<ellipse
				cx="9.33975"
				cy="8.82491"
				rx="7.13467"
				ry="6.74142"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M14.302 13.8638L17.0992 16.4999"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M8.47314 12V9.51084H6V8.47059H8.47314V6H9.52686V8.47059H12V9.51084H9.52686V12H8.47314Z"
				fill="currentColor"
			/>
		</svg>
	);
};

export default OSZoomIn;
