import { CSSProperties, Dispatch, SetStateAction } from "react";
import { cn } from "../../util/helpers";

type DropdownProps = {
	forwardRef: Dispatch<SetStateAction<HTMLDivElement | null>>;
	styles: CSSProperties;
	attributes: object;
	children: React.ReactNode;
	className?: string;
};

const Dropdown = ({
	forwardRef,
	styles,
	attributes,
	children,
	className,
}: DropdownProps) => {
	return (
		<div
			ref={forwardRef}
			style={styles}
			{...attributes}
			className={cn(
				"z-10 grid min-w-max bg-pure-white py-3 drop-shadow-dropdownbg",
				className,
			)}
		>
			{children}
		</div>
	);
};
export default Dropdown;
