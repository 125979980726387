import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { SolidButton } from "../../components/buttons";
import {
	SettingsConnectivityStatus,
	SettingsFragment,
} from "../../components/fragments";
import { TextField } from "../../components/input";
import { FrameLayout } from "../../components/layouts";
import { ModalBase } from "../../components/modals";
import { ButtonText, HeadingText } from "../../components/typography";
import {
	changeAdminPassword,
	getSettings,
	home,
} from "../../util/api/api-store";
import {
	getAccessIdAsPromise,
	useBrandingStore,
	useHeaderStore,
} from "../../store";
import { cn } from "../../util/helpers";

const Setup = () => {
	const { t } = useTranslation();
	const { branding } = useBrandingStore();
	const navigate = useNavigate();
	const [formData, setFormData] = useState({
		access_id: "",
		password: "",
	});
	const [isSubmited, setIsSubmited] = useState<boolean>(false);
	const [modalStartOpen, setmodalStartOpen] = useState<boolean>(false);
	const { loggedOut } = useHeaderStore();
	const { state } = useLocation();

	const onSubmit = () => {
		setIsSubmited(true);

		changeAdminPassword.setRequest(formData);
		changeAdminPassword.fetchData().then((resp) => {
			console.log(resp);
			getSettings.setRequest(null, true);
			getSettings.invalidate();
			getSettings.fetchData();
			setIsSubmited(false);
			setmodalStartOpen(true);
		});
	};

	useEffect(() => {
		const unSub = useHeaderStore.subscribe((state) => {
			console.log(state);

			if (state.logout) {
				navigate("/");
				loggedOut();
			}
		});

		return () => unSub();
	}, [formData]);

	useEffect(() => {
		document.title = t("setup.title");

		console.log(state);
		if (!state?.setupRequired) {
			navigate("/admin/login");
			return;
		}

		getAccessIdAsPromise().then((access_id) => {
			home.setRequest({ access_id });
			home.invalidate();
			home.fetchData();
			setFormData({
				access_id: access_id,
				password: "",
			});
		});

		getSettings.setRequest(null);
		getSettings.fetchData().then((resp) => {
			console.log(resp);
			if (resp.admin_mode) {
				navigate("/admin/settings", { state: { loggedIn: true } });
			}
		});
	}, []);

	const closeStartModal = () => {
		setmodalStartOpen(false);
	};

	return (
		<FrameLayout hiddenFooter={true} headerType="admin">
			<div
				className={cn("container flex flex-col gap-12 py-12 md:py-16")}
			>
				<HeadingText
					h={2}
					className={cn(
						branding !== "hoya" && "!text-[26px] !leading-normal",
					)}
				>
					{t("setup.pageTitle")}
				</HeadingText>
				<SettingsConnectivityStatus type="warning">
					<strong>{t("setup.inactive")}</strong>
				</SettingsConnectivityStatus>
				<SettingsFragment
					borderTop
					className={cn(branding !== "hoya" && "py-10")}
				>
					<div
						className={cn(
							"flex flex-col gap-6",
							branding !== "hoya" && "gap-8",
						)}
					>
						<ButtonText
							type={branding !== "hoya" ? "bold" : "normal"}
						>
							{t("setup.toActivate")}
						</ButtonText>
						<form
							onSubmit={(e) => {
								e.preventDefault();
								onSubmit();
							}}
							className={cn(
								branding !== "hoya"
									? "flex flex-col gap-12"
									: "grid gap-6 sm:items-end md:grid-cols-8",
							)}
						>
							<div className={cn("md:col-span-4 lg:col-span-3")}>
								<TextField
									htmlFor="admin-new-password"
									type="default"
									showPasswordButton={true}
									label={t("settings.newPassword")}
									fieldOptions={{
										type: "password",
										autoFocus: true,
									}}
									value={formData.password}
									setValue={(v) =>
										setFormData({
											...formData,
											password: v,
										})
									}
									fullWidth
									className={cn(
										branding !== "hoya" &&
											"md:grid-cols-[1fr_292px]",
									)}
								/>
							</div>
							<div
								className={cn(
									"md:col-span-4 lg:col-span-5",
									branding !== "hoya" && "self-end",
								)}
							>
								<SolidButton
									color="primary"
									submit={true}
									disabled={isSubmited}
								>
									{t("setup.activate")}
								</SolidButton>
							</div>
						</form>
					</div>
				</SettingsFragment>
			</div>
			<ModalBase
				title={t("setup.isActivated") || undefined}
				open={modalStartOpen}
				small
				close={closeStartModal}
			>
				<div
					className={cn(
						"flex flex-col",
						branding !== "hoya"
							? "gap-10 px-12 py-10 text-center"
							: "p-6",
					)}
				>
					<p className={cn(branding === "hoya" && "mb-6")}>
						{t("setup.activatedDescription")}
					</p>
					<p className="text-right">
						<SolidButton
							color="primary"
							onClick={() =>
								navigate("/admin/settings", {
									state: { loggedIn: true },
								})
							}
							fullWidth={branding !== "hoya"}
						>
							{t("settings.ok")}
						</SolidButton>
					</p>
				</div>
			</ModalBase>
		</FrameLayout>
	);
};

export default Setup;
