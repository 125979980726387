import { SVGProps } from "react";
const History = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			width="87"
			height="86"
			viewBox="0 0 87 86"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<circle cx="43.5" cy="43" r="43" fill="currentColor" />
			<path
				d="M43.2988 56.9375C46.0801 56.9375 48.799 56.1128 51.1115 54.5676C53.4241 53.0223 55.2265 50.8261 56.2909 48.2565C57.3552 45.6869 57.6337 42.8594 57.0911 40.1315C56.5485 37.4037 55.2092 34.898 53.2425 32.9313C51.2758 30.9646 48.7701 29.6253 46.0423 29.0827C43.3144 28.5401 40.4869 28.8186 37.9173 29.883C35.3478 30.9473 33.1515 32.7497 31.6063 35.0623C30.0611 37.3749 29.2363 40.0937 29.2363 42.875C29.2419 46.6029 30.7253 50.1765 33.3613 52.8125C35.9974 55.4485 39.5709 56.9319 43.2988 56.9375ZM41.7363 38.1875C41.7363 37.7731 41.9009 37.3757 42.194 37.0827C42.487 36.7896 42.8844 36.625 43.2988 36.625C43.7132 36.625 44.1107 36.7896 44.4037 37.0827C44.6967 37.3757 44.8613 37.7731 44.8613 38.1875V42.2188L47.7207 45.0781C48.0129 45.3728 48.1765 45.7712 48.1757 46.1862C48.1749 46.6012 48.0096 46.9989 47.7162 47.2924C47.4227 47.5858 47.025 47.751 46.61 47.7519C46.195 47.7527 45.7966 47.5891 45.502 47.2969L42.1895 43.9844C41.9028 43.6857 41.7407 43.289 41.7363 42.875V38.1875Z"
				fill="white"
			/>
			<path
				d="M43.2979 21C42.8835 21 42.486 21.1647 42.193 21.4577C41.9 21.7507 41.7354 22.1481 41.7354 22.5625C41.7354 22.9769 41.9 23.3744 42.193 23.6674C42.486 23.9604 42.8835 24.125 43.2979 24.125C47.672 24.107 51.9159 25.6124 55.3009 28.3829C58.6858 31.1533 61.0005 35.0159 61.8475 39.3073C62.6945 43.5986 62.0209 48.051 59.9424 51.8998C57.8638 55.7485 54.5101 58.7535 50.4571 60.3987C46.4042 62.0438 41.9048 62.2265 37.7318 60.9153C33.5588 59.6041 29.9725 56.8809 27.5889 53.2133C25.2052 49.5457 24.1729 45.1626 24.6692 40.8167C25.1655 36.4708 27.1595 32.4333 30.3087 29.3975L30.2027 30.2057C30.1488 30.6166 30.2604 31.0322 30.5129 31.3608C30.7654 31.6895 31.1382 31.9044 31.5491 31.9583C31.9601 32.0122 32.3756 31.9006 32.7043 31.6481C33.033 31.3956 33.2479 31.0229 33.3018 30.6119L33.9099 25.9641C33.9471 25.5561 33.829 25.1491 33.5793 24.8244C33.3295 24.4997 32.9664 24.2811 32.5626 24.2124L27.9148 23.6047C27.7085 23.5719 27.4977 23.5808 27.295 23.6308C27.0922 23.6809 26.9015 23.7711 26.7342 23.8961C26.5669 24.0211 26.4263 24.1784 26.3208 24.3586C26.2153 24.5389 26.147 24.7385 26.12 24.9456C26.093 25.1527 26.1077 25.3631 26.1634 25.5644C26.2191 25.7657 26.3145 25.9538 26.4442 26.1175C26.5738 26.2813 26.7349 26.4174 26.9181 26.5179C27.1012 26.6183 27.3026 26.681 27.5104 26.7022L28.4833 26.8297C24.7532 30.291 22.3491 34.9464 21.6861 39.9916C21.0232 45.0369 22.1431 50.1553 24.8525 54.4627C27.5618 58.7701 31.6904 61.9961 36.5252 63.5834C41.36 65.1706 46.5973 65.0195 51.3325 63.1561C56.0677 61.2928 60.0035 57.8342 62.46 53.3777C64.9165 48.9213 65.7394 43.7468 64.7867 38.7482C63.834 33.7495 61.1655 29.2405 57.242 26C53.3185 22.7595 48.3865 20.991 43.2979 21Z"
				fill="white"
			/>
		</svg>
	);
};

export default History;
