import { BrandingType } from "../../util/api/request-definitions/get-settings";
import { cn } from "../../util/helpers";
import { useBrandingStore } from "../../store";

type BrandLogoProps = {
	override?: BrandingType;
	size?: "24px" | "40px" | "37px" | "18px";
};

const sizes = {
	hoya: "24px",
	vs: "40px",
	ollendorf: "24px",
	optiswiss: "37px",
	visiono: "18px",
};

const BrandLogo = ({ override, size }: BrandLogoProps) => {
	const { branding } = useBrandingStore();
	const selected = override ?? branding;

	return (
		<img
			style={{ "--size": size ?? sizes[selected] } as React.CSSProperties}
			className={cn("col-start-3 h-[--size] w-auto justify-self-end")}
			src={`/images/logos/${selected}_logo.svg`}
		/>
	);
};
export default BrandLogo;
