import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { MenuItem, TextButton } from "../../components/buttons";
import {
	Back,
	Notification,
	OSArrowLeft,
	OSUpdate,
} from "../../components/icons";
import { FrameLayout } from "../../components/layouts";
import { HeadingText } from "../../components/typography";
import { getServerStatus, getSettings } from "../../util/api/api-store";
import { useBrandingStore } from "../../store";
import { cn } from "../../util/helpers";

const Settings = () => {
	const { t } = useTranslation();
	const { branding } = useBrandingStore();
	const serverStatusResponse = getServerStatus.getResult();

	const serverStatus = serverStatusResponse.unwrapOrDefault({
		show_scheduler: false,
		update_ready: false,
		updated_from: null,
		version: "",
	});

	const settingsResponse = getSettings.useGetResult();

	const settings = settingsResponse.unwrapOrDefault({
		admin_mode: false,
		auto_exposure: 0,
		branding: "hoya",
		brightness: 0,
		contrast: 0,
		contrast_max: 0,
		contrast_min: 0,
		contrast_step: 0,
		debug_capture_timing: false,
		direct_loupe: false,
		expose_face_form_angle: false,
		exposure_time: 0,
		exposure_time_max: 0,
		exposure_time_min: 0,
		exposure_time_step: 0,
		eye_catcher: false,
		gain_max: 0,
		gain_min: 0,
		hide_shutdown: false,
		hide_wifi_settings: false,
		history_show_preview: false,
		inactive_time: 0,
		isp_digital_gain_max: 0,
		measurement_retention_days: 3,
		measurements_permanent: false,
		modules: {
			ai: false,
			b2b_optic: false,
			b2b_optic_download: false,
		},
		onboard: false,
		order_id_fixed: false,
		order_id_required: false,
		shop_name: "",
		show_aux_points: false,
		show_coordinates: false,
		show_editor_changes: false,
		show_frame_edge_editor: false,
		show_menu_item_debug: false,
		show_reevaluate_button: false,
		starlight_available: false,
		starlight_enabled: false,
		update_enabled: false,
		// user_instructions_url: string // The response does not contain this key.
	});

	useEffect(() => {
		getSettings.setRequest(null);
		getSettings.fetchData();

		getServerStatus.setRequest(null);
		getServerStatus.fetchData();
	}, []);

	return (
		<FrameLayout hiddenFooter>
			<div className="container">
				<TextButton
					to="/"
					color="primary"
					icon={branding !== "hoya" ? OSArrowLeft : Back}
					iconClasses={cn(
						branding !== "hoya" &&
							"size-[30px] !rounded-[10px] border border-quaternary-80 p-[5px]",
					)}
					className={cn(
						"-m-3 mb-6",
						branding !== "hoya" ? "mt-6 h-fit" : "mt-3",
					)}
				>
					{t("settingsSettings.pageTitle")}
				</TextButton>
				<HeadingText
					h={2}
					className={cn(
						branding !== "hoya" ? "mb-5 md:mb-8" : "mb-8 md:mb-12",
					)}
				>
					{t("menu.settings")}
				</HeadingText>
				<div
					className={cn(
						"relative grid grid-cols-1 items-start gap-12 md:grid-cols-[14.75rem_auto]",
					)}
				>
					<div
						className={cn(
							"sticky top-[calc(4.5rem+1px)] z-10 before:pointer-events-none before:absolute before:inset-y-0 before:right-0 before:z-20 before:w-12 before:bg-gradient-to-l before:from-white md:top-[6rem] before:md:hidden",
						)}
					>
						<nav
							className={cn(
								"flex snap-x flex-row gap-6 overflow-x-auto bg-white py-3 md:flex-col md:gap-3 md:py-0",
							)}
						>
							<MenuItem
								to="profile"
								color="primary"
								className={cn("shrink-0 snap-center md:w-full")}
							>
								{t("settingsSettings.profile")}
							</MenuItem>
							<MenuItem
								to="export-settings"
								color="primary"
								className={cn("shrink-0 snap-center md:w-full")}
							>
								{t("settingsSettings.exportSettings")}
							</MenuItem>
							<MenuItem
								to="general"
								color="primary"
								className={cn("shrink-0 snap-center md:w-full")}
							>
								{t("settingsSettings.general")}
							</MenuItem>
							<MenuItem
								to="wifi"
								color="primary"
								className={cn("shrink-0 snap-center md:w-full")}
							>
								{t("settings.wifi")}
							</MenuItem>
							<MenuItem
								to="update"
								color="primary"
								badge={
									branding === "hoya" &&
									serverStatus?.update_ready &&
									settings?.update_enabled
										? 1
										: undefined
								}
								icon={
									branding !== "hoya" &&
									serverStatus?.update_ready &&
									settings?.update_enabled
										? OSUpdate
										: undefined
								}
								className={cn(
									"shrink-0 snap-center md:w-full",
									branding !== "hoya" &&
										"flex items-center gap-1",
								)}
							>
								{t("settingsSettings.update")}
							</MenuItem>
						</nav>
					</div>
					<Outlet />
				</div>
			</div>
		</FrameLayout>
	);
};

export default Settings;
