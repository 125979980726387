import { Done, GreenTick, OSWarning, Warning } from "../icons";
import { ButtonText } from "../typography";
import { useBrandingStore } from "../../store";

type SettingsConnectivityStatusProps = {
	children: string | React.ReactNode;
	type: "success" | "warning";
};

const SettingsConnectivityStatus = ({
	children,
	type,
}: SettingsConnectivityStatusProps) => {
	const { branding } = useBrandingStore();

	return (
		<div className="flex flex-row gap-3 text-primary-dark-100">
			{type === "success" ? (
				<>
					{branding !== "hoya" ? (
						<GreenTick className="size-6 translate-y-0.5 [&>g]:filter-none" />
					) : (
						<Done />
					)}
				</>
			) : (
				<>
					{branding !== "hoya" ? (
						<OSWarning className="size-6 [&>g]:filter-none" />
					) : (
						<Warning />
					)}
				</>
			)}
			<ButtonText type="normal">{children}</ButtonText>
		</div>
	);
};

export default SettingsConnectivityStatus;
