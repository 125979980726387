import { useMemo, useState, useEffect } from "react";
import {
	ImageObjects,
	FramePosition,
	FramesPositions,
	FramesPositionsSingle,
	FramePositionSingle,
	FramesPositionsSide,
	FramesPositionsSideSingle,
	IrisPositions,
	IrisRadii,
	HandleObjects,
	FramesHandle,
	CornerHandle,
} from "../../../util/drawing";
import * as drawing from "../../../util/drawing";
import { FramesCanvas } from ".";

export type FramesCanvasControllerProps = {
	maxWidth: number;
	maxHeight: number;

	imageObjects: ImageObjects;
	handleObjects: HandleObjects;

	irisRadii: IrisRadii;
	startingIrisPositions: IrisPositions;
	startingFramesPositions: FramesPositions;
	framesPositionsChangedSignal: boolean;
	onFramesPositionsChange: (p: FramesPositions) => void;
	onSetEdgeCountChange: (count: number) => void;

	leftHand: boolean;
	alwaysUseLoupe: boolean;
};

type SidedCorner = {
	side: "upper" | "lower";
	handle: CornerHandle;
};

const FramesCanvasController = ({
	maxWidth,
	maxHeight,
	irisRadii,
	startingIrisPositions,
	startingFramesPositions,
	framesPositionsChangedSignal,
	imageObjects,
	handleObjects,
	leftHand,
	alwaysUseLoupe,
	onFramesPositionsChange,
	onSetEdgeCountChange,
}: FramesCanvasControllerProps) => {
	const { topWidth, topHeight, bottomWidth, bottomHeight } = useMemo(
		() => ({
			topWidth: maxWidth,
			topHeight: maxHeight / 2,
			bottomWidth: maxWidth,
			bottomHeight: maxHeight / 2,
		}),
		[maxWidth, maxHeight],
	);

	const [setEdges, setSetEdges] = useState<SidedCorner[]>([]);

	const [framesPositions, setFramesPositions] = useState<FramesPositions>(
		startingFramesPositions,
	);

	useEffect(() => {
		setFramesPositions(startingFramesPositions);
	}, [framesPositionsChangedSignal]);

	const topImgUtils = drawing.createImageUtils(
		topWidth,
		topHeight,
		{ x: startingIrisPositions.left.x, y: startingIrisPositions.left.y1 },
		{ x: startingIrisPositions.right.x, y: startingIrisPositions.right.y1 },
		irisRadii.left,
		irisRadii.right,
		0,
	);

	const bottomImgUtils = drawing.createImageUtils(
		bottomWidth,
		bottomHeight,
		{ x: startingIrisPositions.left.x, y: startingIrisPositions.left.y2 },
		{ x: startingIrisPositions.right.x, y: startingIrisPositions.right.y2 },
		irisRadii.left,
		irisRadii.right,
		0,
	);

	const updateSetEdges = (
		newSetEdge: CornerHandle,
		side: "upper" | "lower",
	) => {
		if (setEdges.find((e) => e.handle == newSetEdge && e.side == side)) {
			return;
		}

		setSetEdges((prevSetEdges) => {
			onSetEdgeCountChange(prevSetEdges.length + 1);
			return [...prevSetEdges, { side, handle: newSetEdge }];
		});
	};

	const topFramePositions = {
		left: fromDoubleToSingleSideTop(
			framesPositions.left,
			fromDoubleToSingleTop,
		),
		right: fromDoubleToSingleSideTop(
			framesPositions.right,
			fromDoubleToSingleTop,
		),
	};

	const bottomFramePositions = {
		left: fromDoubleToSingleSideTop(
			framesPositions.left,
			fromDoubleToSingleBottom,
		),
		right: fromDoubleToSingleSideTop(
			framesPositions.right,
			fromDoubleToSingleBottom,
		),
	};

	return (
		<div>
			<FramesCanvas
				width={topWidth}
				height={topHeight}
				imageUtils={topImgUtils}
				imageObject={imageObjects.topImage}
				leftHandleObject={handleObjects.handle1}
				rightHandleObject={handleObjects.handle1}
				irisPosition={{
					left: {
						x: startingIrisPositions.left.x,
						y: startingIrisPositions.left.y1,
					},
					right: {
						x: startingIrisPositions.right.x,
						y: startingIrisPositions.right.y1,
					},
				}}
				framePositions={topFramePositions}
				irisRadii={irisRadii}
				leftHand={leftHand}
				alwaysUseLoupe={alwaysUseLoupe}
				onFramesPositionChange={(newpos) => {
					setFramesPositions((prevpos) =>
						updateFramePositions(prevpos, newpos, false),
					);
				}}
				// eslint-disable-next-line
				onFramesPositionFinialized={(newpos) =>
					onFramesPositionsChange(
						updateFramePositions(framesPositions, newpos, false),
					)
				}
				onEdgeSet={(newEdge) => updateSetEdges(newEdge, "upper")}
				showSideHandles={true}
			></FramesCanvas>
			<FramesCanvas
				width={bottomWidth}
				height={bottomHeight}
				imageUtils={bottomImgUtils}
				imageObject={imageObjects.bottomImage}
				leftHandleObject={handleObjects.handle2Left}
				rightHandleObject={handleObjects.handle2Right}
				irisPosition={{
					left: {
						x: startingIrisPositions.left.x,
						y: startingIrisPositions.left.y2,
					},
					right: {
						x: startingIrisPositions.right.x,
						y: startingIrisPositions.right.y2,
					},
				}}
				framePositions={bottomFramePositions}
				irisRadii={irisRadii}
				leftHand={leftHand}
				alwaysUseLoupe={alwaysUseLoupe}
				// eslint-disable-next-line
				onFramesPositionChange={(newpos) => {
					setFramesPositions((prevpos) =>
						updateFramePositions(prevpos, newpos, true),
					);
				}}
				// eslint-disable-next-line
				onFramesPositionFinialized={(newpos) =>
					onFramesPositionsChange(
						updateFramePositions(framesPositions, newpos, true),
					)
				}
				onEdgeSet={(newEdge) => updateSetEdges(newEdge, "lower")}
				showSideHandles={false}
			></FramesCanvas>
		</div>
	);
};

export default FramesCanvasController;

const fromDoubleToSingleTop = (a: FramePosition): FramePositionSingle => ({
	x: a.x,
	y: a.y1,
});
const fromDoubleToSingleBottom = (a: FramePosition): FramePositionSingle => ({
	x: a.x,
	y: a.y2,
});

const fromDoubleToSingleSideTop = (
	a: FramesPositionsSide,
	f: (p: FramePosition) => FramePositionSingle,
): FramesPositionsSideSingle => ({
	bottomNasal: f(a.bottomNasal),
	bottom: f(a.bottom),
	bottomTemporal: f(a.bottomTemporal),
	nasal: f(a.nasal),
	temporal: f(a.temporal),
	topNasal: f(a.topNasal),
	top: f(a.top),
	topTemporal: f(a.topTemporal),
});

const updateFramePositionsSingle = (
	prevpos: FramePosition,
	newpos: FramePositionSingle,
	boxAngle: number,
	isBottom: boolean,
	isCurve: boolean,
) => {
	return isCurve
		? isBottom
			? {
					x: prevpos.x,
					y1: prevpos.y1,
					y2: newpos.y,
				}
			: {
					x: prevpos.x,
					y1: newpos.y,
					y2: prevpos.y2,
				}
		: isBottom
			? {
					x: prevpos.x,
					y1: prevpos.y1,
					y2: newpos.y,
				}
			: {
					x: newpos.x,
					y1: newpos.y,
					y2: prevpos.y2,
				};
};
const updateFramePositionsSide = (
	prevpos: FramesPositionsSide,
	newpos: FramesPositionsSideSingle,
	boxAngle: number,
	isBottom: boolean,
) => ({
	bottomNasal: updateFramePositionsSingle(
		prevpos.bottomNasal,
		newpos.bottomNasal,
		boxAngle,
		isBottom,
		true,
	),
	bottom: updateFramePositionsSingle(
		prevpos.bottom,
		newpos.bottom,
		boxAngle,
		isBottom,
		false,
	),
	bottomTemporal: updateFramePositionsSingle(
		prevpos.bottomTemporal,
		newpos.bottomTemporal,
		boxAngle,
		isBottom,
		true,
	),
	nasal: updateFramePositionsSingle(
		prevpos.nasal,
		newpos.nasal,
		boxAngle,
		isBottom,
		false,
	),
	temporal: updateFramePositionsSingle(
		prevpos.temporal,
		newpos.temporal,
		boxAngle,
		isBottom,
		false,
	),
	topNasal: updateFramePositionsSingle(
		prevpos.topNasal,
		newpos.topNasal,
		boxAngle,
		isBottom,
		true,
	),
	top: updateFramePositionsSingle(
		prevpos.top,
		newpos.top,
		boxAngle,
		isBottom,
		false,
	),
	topTemporal: updateFramePositionsSingle(
		prevpos.topTemporal,
		newpos.topTemporal,
		boxAngle,
		isBottom,
		true,
	),
});
const updateFramePositions = (
	prevpos: FramesPositions,
	newpos: FramesPositionsSingle,
	isBottom: boolean,
) => {
	const boxAngle = drawing.getVectorAngle({
		x: newpos.right.top.x - newpos.left.top.x,
		y: newpos.right.top.y - newpos.left.top.y,
	});
	return {
		left: updateFramePositionsSide(
			prevpos.left,
			newpos.left,
			boxAngle,
			isBottom,
		),
		right: updateFramePositionsSide(
			prevpos.right,
			newpos.right,
			boxAngle,
			isBottom,
		),
	};
};
