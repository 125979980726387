import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TextButton } from "../buttons";
import {
	Close,
	Fullscreen,
	Logout,
	Menu,
	NormalScreen,
	Notification,
	OSFullScreen,
	OSNormalScreen,
	RouteLogout,
} from "../icons";
import BrandLogo from "../logo/BrandLogo";
import { ButtonText } from "../typography";
import { useBrandingStore, useHeaderStore, useMenuStore } from "../../store";
import screenfull from "screenfull";
import { getServerStatus, getSettings } from "../../util/api/api-store";
import { cn } from "../../util/helpers";

type HeaderDefaultProps = {
	type: string;
};

type HeaderOnboardingProps = {
	type: "onboarding";
};

type HeaderProps = HeaderDefaultProps | HeaderOnboardingProps;

const Header = (props: HeaderProps) => {
	const { toggle, opened } = useMenuStore();
	const { t } = useTranslation();
	const { clickedLogout } = useHeaderStore();
	const [fullScreen, setFullScreen] = useState<boolean>(false);
	const [fullScreenClass, setFullScreenClass] = useState<boolean>(false);
	const { branding } = useBrandingStore();

	const settingsResponse = getSettings.useGetResult();

	const settings = settingsResponse.unwrapOrDefault({
		admin_mode: false,
		auto_exposure: 0,
		branding: "hoya",
		brightness: 0,
		contrast: 0,
		contrast_max: 0,
		contrast_min: 0,
		contrast_step: 0,
		debug_capture_timing: false,
		direct_loupe: false,
		expose_face_form_angle: false,
		exposure_time: 0,
		exposure_time_max: 0,
		exposure_time_min: 0,
		exposure_time_step: 0,
		eye_catcher: false,
		gain_max: 0,
		gain_min: 0,
		hide_shutdown: false,
		hide_wifi_settings: false,
		history_show_preview: false,
		inactive_time: 0,
		isp_digital_gain_max: 0,
		measurement_retention_days: 3,
		measurements_permanent: false,
		modules: {
			ai: false,
			b2b_optic: false,
			b2b_optic_download: false,
		},
		onboard: false,
		order_id_fixed: false,
		order_id_required: false,
		shop_name: "",
		show_aux_points: false,
		show_coordinates: false,
		show_editor_changes: false,
		show_frame_edge_editor: false,
		show_menu_item_debug: false,
		show_reevaluate_button: false,
		starlight_available: false,
		starlight_enabled: false,
		update_enabled: false,
		// user_instructions_url: string // The response does not contain this key.
	});

	const serverStatusResponse = getServerStatus.getResult();

	const serverStatus = serverStatusResponse.unwrapOrDefault({
		show_scheduler: false,
		update_ready: false,
		updated_from: null,
		version: "",
	});

	useEffect(() => {
		console.log(window.navigator);
		// setFullScreen(screenfull.isFullscreen);

		getServerStatus.setRequest(null);
		getServerStatus.fetchData();
	}, []);

	const toggleFullScreen = async () => {
		setFullScreenClass(true);
		if (screenfull.isEnabled) {
			await screenfull.toggle();
			setFullScreen(screenfull.isFullscreen);
		}
		setTimeout(() => {
			setFullScreenClass(false);
		}, 2000);
	};

	return (
		<header className="shadow-[0px_1px_0px_#e6e8e9]">
			{props.type === "admin" ? (
				<div
					className={cn(
						"relative flex flex-row items-center justify-end px-6",
						branding !== "hoya"
							? "container h-[85px] justify-between"
							: "h-[72px]",
					)}
				>
					{branding !== "hoya" && <BrandLogo />}
					<TextButton
						onClick={clickedLogout}
						color="primary"
						icon={branding !== "hoya" ? RouteLogout : Logout}
						iconClasses={
							branding !== "hoya"
								? "size-[30px] !rounded-[10px] border border-quaternary-80 p-[4px]"
								: undefined
						}
						iconPosition="suffix"
						className="-m-3"
					>
						{t("header.logout")}
					</TextButton>
				</div>
			) : (
				<div
					className={cn(
						"relative grid items-center px-6",
						branding !== "hoya"
							? "container h-[85px] grid-cols-[auto_1fr_auto_auto] grid-rows-1 gap-4"
							: "h-[72px] grid-cols-[1fr_auto_1fr] justify-between",
					)}
				>
					{props.type === "onboarding" ? (
						<>
							<ButtonText
								type="bold"
								className={cn(
									branding !== "hoya" &&
										"[grid-area:1/1/1/1]",
								)}
							>
								{t("application.title")}
							</ButtonText>
						</>
					) : (
						<>
							<div
								className={cn(
									"flex flex-row gap-6",
									branding !== "hoya" && "col-start-4 gap-0",
								)}
							>
								<TextButton
									color="secondary"
									disabled={false}
									fullWidth={false}
									icon={opened ? Close : Menu}
									iconClasses={
										branding !== "hoya"
											? "size-[17px]"
											: undefined
									}
									onClick={() => {
										toggle();
										document.body.classList.toggle(
											"overflow-hidden",
										);
									}}
									className={cn(
										branding !== "hoya" &&
											"h-fit !rounded-[10px] border border-quaternary-80 p-[5px]",
									)}
								/>
								{branding === "hoya" && (
									<div
										data-after={
											fullScreen
												? t("header.switchToFull")
												: t("header.switchToNormal")
										}
										className={cn(
											fullScreenClass
												? "fullscreen active after:content-[attr(data-after)]"
												: "fullscreen after:content-[attr(data-after)]",
										)}
									>
										<TextButton
											color="secondary"
											disabled={false}
											fullWidth={false}
											icon={
												fullScreen
													? NormalScreen
													: Fullscreen
											}
											onClick={toggleFullScreen}
										/>
									</div>
								)}
							</div>

							{serverStatus.update_ready &&
								settings.update_enabled && (
									<div
										className={cn(
											"flex items-center gap-4",
											branding !== "hoya" &&
												"[grid-area:1/3/1/3]",
											branding !== "hoya" &&
												opened &&
												"hidden",
										)}
									>
										<TextButton
											color="primary"
											to="/settings/update"
											className={cn(
												"hidden sm:inline-block",
												branding !== "hoya" && "px-0",
											)}
										>
											{t("menu.update available")}
										</TextButton>
										<TextButton
											color="secondary"
											disabled={false}
											fullWidth={false}
											icon={Notification}
											iconClasses="!size-[17px]"
											to="/settings/update"
											className={cn(
												"h-fit !rounded-[10px] border border-quaternary-80 p-[5px]",
												branding === "hoya" && "hidden",
											)}
										/>
									</div>
								)}
						</>
					)}
					<div
						className={cn(
							"flex items-center gap-5 [grid-area:1/4/1/4]",
							branding !== "hoya" &&
								"!justify-self-start [grid-area:1/1/1/1]",
						)}
					>
						<BrandLogo />
						{branding !== "hoya" && (
							<div
								data-after={
									fullScreen
										? t("header.switchToFull")
										: t("header.switchToNormal")
								}
								className={cn(
									fullScreenClass
										? "fullscreen fullscreen-os active after:content-[attr(data-after)]"
										: "fullscreen fullscreen-os after:content-[attr(data-after)]",
								)}
							>
								<TextButton
									color="secondary"
									disabled={false}
									fullWidth={false}
									icon={
										fullScreen
											? OSNormalScreen
											: OSFullScreen
									}
									iconClasses="size-[17px]"
									onClick={toggleFullScreen}
									className={cn(
										"h-fit !rounded-[10px] border border-quaternary-80 p-[5px]",
									)}
								/>
							</div>
						)}
					</div>
				</div>
			)}
		</header>
	);
};

export default Header;
