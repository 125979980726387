import { useEffect, useState } from "react";
import { useSnackStore } from "../../store";
import { Close, Done, SnackOk, SnackWarning, Warning } from "../icons";
import { useBrandingStore } from "../../store";
import { cn } from "../../util/helpers";
import { BodyText } from "../typography";
import { TextButton } from "../buttons";

type SnackbarProps = {
	text: string | JSX.Element;
	icon: "ok" | "warning" | null;
};

const Snackbar = ({ text, icon }: SnackbarProps) => {
	const { branding } = useBrandingStore();
	const [distance, setDistance] = useState("0");
	const clearSnack = useSnackStore((state) => state.clear);
	const closeTime = useSnackStore((state) => state.closeTime);

	useEffect(() => {
		setDistance("100%");

		setTimeout(() => {
			clearSnack();
		}, closeTime * 1000);
	});

	if (branding !== "hoya") {
		return (
			<div
				className={cn(
					"fixed left-1/2 top-4 z-50 grid h-[72px] w-[90%] max-w-[700px] -translate-x-1/2 rounded-[20px] bg-primary-100 px-4 md:px-11",
				)}
			>
				<div
					className={cn(
						"flex items-center gap-3 pr-8 [grid-area:1/1] md:place-content-center md:pr-0",
					)}
				>
					{icon === "ok" && <SnackOk />}
					{icon === "warning" && <SnackWarning />}
					<BodyText type="bold16" className="text-pure-white">
						{text}
					</BodyText>
				</div>
				<TextButton
					color="secondary"
					icon={Close}
					iconClasses={"size-[17px] text-pure-white"}
					onClick={clearSnack}
					className={cn(
						"h-fit place-self-end self-center !rounded-[10px] border border-pure-white p-[5px] [grid-area:1/1]",
					)}
				/>
			</div>
		);
	}

	return (
		<div className={cn("fixed inset-x-0 z-40 bg-primary-dark-100 p-6")}>
			<div className={cn("grid grid-cols-[auto_auto_auto] items-center")}>
				<div
					className={cn(
						"flex flex-row gap-3 md:col-start-2 md:justify-self-center",
					)}
				>
					{icon === "ok" && <Done className="fill-pure-white" />}
					{icon === "warning" && (
						<Warning className="fill-pure-white" />
					)}
					<p
						className={cn(
							"text-xs leading-5 text-pure-white md:text-center md:text-base md:leading-6",
						)}
					>
						{text}
					</p>
				</div>
				<Close
					className={cn(
						"cursor-pointer justify-self-end fill-primary-dark-60",
					)}
					onClick={clearSnack}
				/>
			</div>
			<div
				style={{
					width: distance,
					transition: `width ${closeTime - 1}s linear 1s`,
				}}
				className={cn("absolute bottom-0 left-0 h-1 bg-secondary-40")}
			></div>
		</div>
	);
};

export default Snackbar;
