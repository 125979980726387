import J from "joi";

export interface MeasurementPosition {
	changed: boolean;
	position: number[];
}

export interface MeasurementSide {
	iris: MeasurementPosition;
	iris_radius_pixels: number;
}

export interface IRequestData {
	access_id: string;
	measurement: string;
}

export interface IResponseData {
	editable: {
		_L: MeasurementSide;
		_R: MeasurementSide;
	};
	images: {
		_CO: string;
		_CU: string;
	};
	measurement: string;
	order_id: string;
	page: number;
	time_stamp: string;
}

export type IResponseError = null;

const measurementPosition = () =>
	J.object({
		changed: J.boolean(),
		position: J.array().items(J.number()),
	})
		.unknown()
		.required();

const measurementSide = () =>
	J.object({
		iris: measurementPosition(),
		iris_radius_pixels: J.number(),
	}).unknown();

const responseDataShape = J.object({
	editable: J.object({
		_L: measurementSide(),
		_R: measurementSide(),
	}).unknown(),
	images: J.object({
		_CO: J.string(),
		_CU: J.string(),
	}).unknown(),
	measurement: J.string(),
	order_id: J.string().allow(""),
	page: J.number(),
	time_stamp: J.string(),
})
	.required()
	.unknown();

const responseErrorShape = J.object({
	success: J.boolean(),
	error: J.string(),
})
	.required()
	.unknown();

const uri = import.meta.env.VITE_MASTER_SERVER_HOST + "editor";

export function isValidResponse(resp: unknown): resp is IResponseData {
	const validationError = responseDataShape.validate(resp).error;
	return validationError === undefined;
}

export function isValidResponseError(
	respErr: unknown,
): respErr is IResponseError {
	const validationError = responseErrorShape.validate(respErr).error;
	return validationError === undefined;
}

export function request(req: IRequestData): Promise<Response> {
	return fetch(uri, {
		method: "POST",
		body: JSON.stringify({
			page: 1,
			...req,
		}),
	});
}
