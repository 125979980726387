import { useState, useEffect } from "react";
import { getAccessIdAsOption, useSnackStore } from "../store";
import { amICameraOwner, amIMeasurementOwner } from "./api/api-store";
import { Option } from "./result";
import { useTranslation } from "react-i18next";
import { ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { BrandingType } from "./api/request-definitions/get-settings";

export const cn = (...inputs: ClassValue[]) => {
	return twMerge(clsx(inputs));
};

export const bytesToSize = (bytes: number) => {
	const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

	if (typeof bytes !== "number") {
		return "Not a number";
	}

	let l = 0,
		n = bytes;

	while (n >= 1024 && ++l) {
		n = n / 1024;
	}

	return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l];
};

export const capitalize = (str: string) => {
	return str.charAt(0).toUpperCase() + str.slice(1);
};

export const useMediaQuery = (screen: string) => {
	const [matches, setMatches] = useState(false);

	interface Sizes {
		[key: string]: string;
	}

	const sizes: Sizes = {
		sm: "640px",
		md: "768px",
		lg: "1024px",
		xl: "1280px",
		"2xl": "1536px",
	};

	useEffect(() => {
		const query = `(min-width: ${sizes[screen]})`;
		const media = window.matchMedia(query);
		if (media.matches !== matches) {
			setMatches(media.matches);
		}
		const listener = () => setMatches(media.matches);
		window.addEventListener("resize", listener);
		return () => window.removeEventListener("resize", listener);
	}, [matches, screen]);

	return matches;
};

interface CountryCode {
	label: string;
	value: string;
}

export const CountryCodes: CountryCode[] = [
	{
		label: "Germany",
		value: "de",
	},
	{
		label: "Austria",
		value: "at",
	},
	{
		label: "Switzerland",
		value: "ch",
	},
	{
		label: "Belgium",
		value: "be",
	},
	{
		label: "Brazil",
		value: "br",
	},
	{
		label: "Bulgaria",
		value: "bg",
	},
	{
		label: "Croatia",
		value: "hr",
	},
	{
		label: "Macedonia",
		value: "mk",
	},
	{
		label: "Czech",
		value: "cz",
	},
	{
		label: "Danmark",
		value: "dk",
	},
	{
		label: "Estonia",
		value: "ee",
	},
	{
		label: "Egypt",
		value: "eg",
	},
	{
		label: "Finland",
		value: "fi",
	},
	{
		label: "France",
		value: "fr",
	},
	{
		label: "Greece",
		value: "gr",
	},
	{
		label: "Hungary",
		value: "hu",
	},
	{
		label: "Israel",
		value: "il",
	},
	{
		label: "Italy",
		value: "it",
	},
	{
		label: "Kuwait",
		value: "kw",
	},
	{
		label: "Lebanon",
		value: "lb",
	},
	{
		label: "Lithuania",
		value: "lt",
	},
	{
		label: "Netherlands",
		value: "nl",
	},
	{
		label: "Norway",
		value: "no",
	},
	{
		label: "Poland",
		value: "pl",
	},
	{
		label: "Portugal",
		value: "pt",
	},
	{
		label: "Romania",
		value: "ro",
	},
	{
		label: "Russia",
		value: "ru",
	},
	{
		label: "Saudi Arabia",
		value: "sa",
	},
	{
		label: "Serbia",
		value: "cs",
	},
	{
		label: "Slovakia",
		value: "sk",
	},
	{
		label: "Slovenia",
		value: "si",
	},
	{
		label: "South Africa",
		value: "za",
	},
	{
		label: "Spain",
		value: "es",
	},
	{
		label: "Sweden",
		value: "se",
	},
	{
		label: "Turkey",
		value: "tr",
	},
	{
		label: "Ukraine",
		value: "ua",
	},
	{
		label: "United Arab Emirates",
		value: "ae",
	},
	{
		label: "United Kingdom",
		value: "gb",
	},
];

export const cameraOwnerHelper = (close: () => void) => {
	const { t } = useTranslation();
	useEffect(() => {
		const interval = setInterval(() => {
			const accessResult = getAccessIdAsOption();
			if (accessResult.happy) {
				amICameraOwner.setRequest({
					access_id: accessResult.data,
				});
				amICameraOwner.invalidate();
				amICameraOwner
					.fetchData()
					.then((resp) => {
						if (!resp.owner) {
							useSnackStore
								.getState()
								.open(
									t(
										"application.timeExpiredMeasurementSaved",
									),
									"ok",
								);
							close();
						}
					})
					.catch((err) => {
						if (!err.errorData.owner) {
							useSnackStore
								.getState()
								.open(
									t(
										"application.timeExpiredMeasurementSaved",
									),
									"ok",
								);
							close();
						}
						// if (
						// 	err.kind === "api-response-error" &&
						// 	err.responseCode === 471
						// ) {
						// 	useSnackStore
						// 		.getState()
						// 		.open(
						// 			t(
						// 				"application.timeExpiredMeasurementSaved"
						// 			),
						// 			"ok"
						// 		);
						// 	close();
						// }
					});
			}
		}, 10000);
		return () => {
			clearInterval(interval);
		};
	}, []);
};

export const measurementOwnerHelper = (
	save: () => void,
	close: () => void,
	measurementId: Option<string>,
) => {
	const { t } = useTranslation();
	useEffect(() => {
		const interval = setInterval(() => {
			const accessResult = getAccessIdAsOption();
			if (accessResult.happy && measurementId.happy) {
				amIMeasurementOwner.setRequest({
					access_id: accessResult.data,
					measurement: measurementId.data,
				});
				amIMeasurementOwner.invalidate();
				amIMeasurementOwner
					.fetchData()
					.then((resp) => {
						if (resp.remaining_time === -1) {
							return false;
						} else if (resp.remaining_time <= 5) {
							save();
							// Master resets timer on save, so we need to close after that
							useSnackStore
								.getState()
								.open(
									t(
										"application.timeExpiredMeasurementSaved",
									),
									"ok",
								);
							close();
						} else if (resp.remaining_time <= 10) {
							setTimeout(
								() => {
									save();
									useSnackStore
										.getState()
										.open(
											t(
												"application.timeExpiredMeasurementSaved",
											),
											"ok",
										);
									close();
								},
								(resp.remaining_time - 5) * 1000,
							);
						}
					})
					.catch((err) => {
						if (
							err.kind === "api-response-error" &&
							err.responseCode === 471
						) {
							useSnackStore
								.getState()
								.open(
									t(
										"application.timeExpiredMeasurementClosed",
									),
									"ok",
								);
							close();
						}
					});
			}
		}, 10000);
		return () => {
			clearInterval(interval);
		};
	}, [measurementId]);
};

export const downloadXml = (xml: string, name: string, date: string) => {
	const file = name ? `${name}-${date}.xml` : `${date}.xml`;
	fetch(xml)
		.then((response) => response.text())
		.then((text) => {
			const parser = new DOMParser();
			const doc = parser.parseFromString(text, "text/xml");
			const serializer = new XMLSerializer();
			const xmlStr = serializer.serializeToString(doc);
			const url = URL.createObjectURL(
				new File([xmlStr], file, { type: "text/xml" }),
			);
			const anchor = document.createElement("a");
			anchor.href = url;
			anchor.download = file;

			document.body.appendChild(anchor);
			anchor.click();
			document.body.removeChild(anchor);

			URL.revokeObjectURL(url);
		});
};

type ApplicationType = {
	[key in BrandingType]: {
		title: string;
		title_visureal: string;
	};
};

export const application: ApplicationType = {
	hoya: {
		title: "visuReal MASTER",
		title_visureal: "",
	},
	vs: {
		title: "visuReal MASTER",
		title_visureal: "powered by visusolution",
	},
	ollendorf: {
		title: "",
		title_visureal: "",
	},
	visiono: {
		title: "visiono β",
		title_visureal: "",
	},
	optiswiss: {
		title: "OS Centering Pro",
		title_visureal: "powered by visiono®",
	},
};
